import Export from "../components/FormatePlotesuara/Export";
import StartingPageContent from "../components/StartingPage/StartingPageContent";
const ExportPage = () => {
  return (
    <StartingPageContent pageName="Eksport">
      <Export></Export>
    </StartingPageContent>
  );
};

export default ExportPage;
