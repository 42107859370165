import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box, MenuItem, Select, Typography } from "@mui/material";
import gridLabels from "../Labels/GridLabels";
import PerdoruesModalEditor from "./PerdoruesModalEditor";
import { useDispatch, useSelector } from "react-redux";
import { setMessage } from "../../store/message";
import { setLoading } from "../../store/loading";
import { getAll, updateToGlobalEditor } from "../../store/perdorues";
import ButtonAdd from "../UI/ButtonAdd";
import BoxRowButtonsIcon from "../UI/BoxRowButtonsIcon";
import RowButtonIcon from "../UI/RowButtonIcon";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import EditIcon from "@mui/icons-material/Edit";
import SearchInput from "../UI/SearchInput";
import ButtonExport from "../UI/ButtonExport";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { STATUS_USERS } from "../../data/dummy-data";
const PerdoruesCustomGrid = () => {
  const dispatch = useDispatch();
  const { perdorues, totalElements, totalPages, size } = useSelector(
    (state) => state.perdorues
  );
  const user = useSelector((state) => state.auth.user);
  const isAnalist = user?.role === "ROLE_ANALYST";

  const [njesi, setNjesi] = React.useState({});
  const [isStatusNew, setIsStatusNew] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [table, setTable] = React.useState({});
  const handleClose = () => {
    setOpen(false);
  };

  const [page, setPage] = React.useState(0);
  const [statusFilter, setStatusFilter] = React.useState("all");
  const searchState = React.useRef("");
  const [searchValueServer, setSearchValueServer] = React.useState("");

  const handleChangePage = (event, newPage) => {
    setPage(event);
  };
  const searchItems = (e) => {
    const searchValue = e.target.value;
    if (searchValue !== "") {
      searchState.current.value = searchValue;
      let filterTimeout;
      filterTimeout = setTimeout(() => {
        setSearchValueServer(searchState.current.value);
      }, 1000);
    } else {
      setSearchValueServer("");
      dispatch(getAll({ page: page, searchValue: "", status: statusFilter }));
    }
  };

  React.useEffect(() => {
    getAllData();
  }, [dispatch, page, searchValueServer, statusFilter]);

  const getAllData = () => {
    dispatch(setLoading(true));
    dispatch(
      getAll({
        page: page,
        searchValue: searchValueServer,
        status: statusFilter,
      })
    )
      .unwrap()
      .then(() => {
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };

  const initialNjesi = {
    id: "",
    username: "",
    reportingUnit: {},
    role: { value: "", label: "" },
    userType: "",
  };

  const [filterModel, setFilterModel] = React.useState({
    items: [
      {
        columnField: "rating",
        operatorValue: ">",
        value: "2.5",
      },
    ],
  });

  const currentlySelected = (e) => {
    setIsStatusNew(false);
    setNjesi(e.row);
    setOpen(true);
  };

  const selectedGlobalEditor = (e) => {
    var userUpdate = e.row;
    var userUpdateTemp;
    if (
      userUpdate.isGlobalEditor == null ||
      userUpdate.isGlobalEditor == false
    ) {
      userUpdateTemp = { ...userUpdate, isGlobalEditor: true };
    } else {
      userUpdateTemp = { ...userUpdate, isGlobalEditor: false };
    }
    dispatch(
      updateToGlobalEditor({
        user: userUpdateTemp,
        page: page,
        searchValue: searchValueServer,
      })
    );
  };

  const addNew = () => {
    setIsStatusNew(true);
    setNjesi(initialNjesi);
    setOpen(true);
  };

  const changeStatusFilter = (event) => {
    const { value } = event.target;
    setStatusFilter(value);
  };

  const CheckIcon = () => {
    return (
      <CheckCircleIcon
        sx={{
          "&.MuiSvgIcon-root": {
            width: "20px",
            height: "20px",
          },
          color: "#45C8D0",
        }}
      />
    );
  };

  const UnCheckIcon = () => {
    return (
      <RadioButtonUncheckedIcon
        sx={{
          "&.MuiSvgIcon-root": {
            width: "20px",
            height: "20px",
          },
          color: "#808EB1",
        }}
      />
    );
  };

  function CustomToolbar() {
    return (
      <Box
        sx={{
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            py: "18px",
            px: "9px",
            alignItems: "center",
            bgcolor: "transparent",
          }}
        >
          <Box
            sx={{
              flexGrow: 1,
              textAlign: "center",
              direction: "column",
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <SearchInput
              onChange={searchItems}
              inputRef={searchState}
              defaultValue={searchState.current.value}
            ></SearchInput>
          </Box>
          <Select
            value={statusFilter}
            onChange={changeStatusFilter}
            sx={{
              width: "130px",
              height: "40px",
              borderRadius: "5px",
              backgroundColor: "#F6F8FC",
              marginRight: "20px",
            }}
          >
            {[...STATUS_USERS, { label: "Te gjitha", value: "all" }].map(
              (item) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              )
            )}
          </Select>

          {!isAnalist && <ButtonAdd onClick={addNew} />}
        </Box>
      </Box>
    );
  }
  return (
    <div style={{ height: 700, width: "100%" }}>
      <DataGrid
        labelRowsPerPage="Artikuj per faqe"
        disableSelectionOnClick
        {...table}
        columns={[
          {
            field: "username",
            headerName: "Përdoruesi",
            width: 300,
            minWidth: 250,
            flex: 1,
            "&.MuiDataGrid-root.MuiDataGrid-root .MuiDataGrid-cell": {
              display: "flex",
              justifyContent: "left !important",
              "-webkit-justify-content": "left",
            },
            editable: false,
            renderCell: (params) =>
              !params.row.username ? "" : params.row.username,
          },
          {
            field: "role",
            headerName: "Operator",
            width: 100,
            flex: 1,
            marginLeft: 10,
            display: "flex",
            justifyContent: "center",
            editable: false,
            renderCell: (params) =>
              params.row?.role === "ROLE_OPERATOR" ? (
                <CheckIcon />
              ) : (
                <UnCheckIcon />
              ),
          },
          {
            field: "role1",
            headerName: "Editor",
            width: 100,
            flex: 1,
            editable: false,
            renderCell: (params) =>
              params.row?.role === "ROLE_EDITOR" ? (
                <CheckIcon />
              ) : (
                <UnCheckIcon />
              ),
          },
          {
            field: "role2",
            headerName: "Analist",
            width: 100,
            flex: 1,
            editable: false,
            renderCell: (params) =>
              params.row?.role === "ROLE_ANALYST" ? (
                <CheckIcon />
              ) : (
                <UnCheckIcon />
              ),
          },
          {
            field: "role3",
            headerName: "Global Editor",
            width: 100,
            flex: 1,
            editable: false,
            renderCell: (params) => (
              <BoxRowButtonsIcon>
                {params.row?.role === "ROLE_EDITOR" ? (
                  <RowButtonIcon
                    disabled={!isAnalist}
                    sx={{
                      width: "45px !important",
                      "&.MuiButtonBase-root.MuiIconButton-root": {
                        backgroundColor: isAnalist ? "white" : null,

                        ":hover": {
                          backgroundColor: "rgba(34, 87, 187, 0.04)",
                        },
                      },
                    }}
                    onClick={() => selectedGlobalEditor(params)}
                  >
                    {params.row.isGlobalEditor ? (
                      <CheckIcon />
                    ) : (
                      <UnCheckIcon />
                    )}
                  </RowButtonIcon>
                ) : (
                  <RowButtonIcon
                    sx={{ width: "45px !important" }}
                    disabled
                    onClick={() => selectedGlobalEditor(params)}
                  >
                    {params.row.isGlobalEditor ? (
                      <CheckIcon />
                    ) : (
                      <UnCheckIcon />
                    )}
                  </RowButtonIcon>
                )}
              </BoxRowButtonsIcon>
            ),
          },
          {
            field: "role4",
            headerName: "Ekzekutiv",
            width: 100,
            flex: 1,
            editable: false,
            renderCell: (params) =>
              params.row?.role === "ROLE_SOFT_EXECUTIVE" ? (
                <CheckIcon />
              ) : (
                <UnCheckIcon />
              ),
          },
          {
            field: "role5",
            headerName: "Administrator",
            width: 100,
            flex: 1,
            editable: false,
            renderCell: (params) =>
              params.row?.role === "ROLE_EXECUTIVE" ? (
                <CheckIcon />
              ) : (
                <UnCheckIcon />
              ),
          },
          // {
          //   field: "role6",
          //   headerName: "Minor",
          //   width: 70,
          //   flex: 1,
          //   editable: false,
          //   renderCell: (params) =>
          //     params.row?.role === "ROLE_MINOR_EXECUTIVE" ? (
          //       <CheckIcon />
          //     ) : (
          //       <UnCheckIcon />
          //     ),
          // },
          {
            headerName: "",
            hide: isAnalist,
            overflow: "hidden",
            field: "editim",
            editable: false,
            flex: 1,
            justifyContent: "flex-end",
            disableClickEventBubbling: true,
            align: "right",
            background: "red",
            headers: false,
            minWidth: 50,
            renderHeader: () => {},
            renderCell: (params) => (
              <BoxRowButtonsIcon>
                {/* <RowButtonIcon onClick={() => currentlySelected(params)}>
                  <RemoveRedEyeOutlinedIcon />
                </RowButtonIcon> */}
                <RowButtonIcon onClick={() => currentlySelected(params)}>
                  <EditIcon />
                </RowButtonIcon>
              </BoxRowButtonsIcon>
            ),
          },
        ]}
        rows={perdorues ? perdorues : []}
        density="comfortable"
        editMode="row"
        disableColumnMenu
        localeText={gridLabels}
        componentsProps={{
          pagination: {
            labelRowsPerPage: "Rreshta per Faqe",
            showLastButton: true,
            showFirstButton: true,
          },
        }}
        components={{
          Toolbar: CustomToolbar,
        }}
        filterModel={filterModel}
        onFilterModelChange={(newFilterModel) => setFilterModel(newFilterModel)}
        page={page}
        onPageChange={handleChangePage}
        count={totalPages}
        rowCount={totalElements}
        pageSize={size}
        pagination
        paginationMode="server"
      ></DataGrid>
      <PerdoruesModalEditor
        open={open}
        onClose={handleClose}
        formname="Përdorues"
        data={njesi}
        isstatusnew={isStatusNew ? 1 : 0}
      ></PerdoruesModalEditor>
    </div>
  );
};

export default PerdoruesCustomGrid;
