import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";
import { setLoading } from "./loading";
import ProductService from "../services/product.service";

const initialState = {
  products: [],
  prices: [],
  totalElements: 0,
  totalPages: 0,
  size: 0,
  page: 0,
  currentPage: 0,
  searchValueCurrent: "",
};
export const setSearch = createAsyncThunk(
  "product/setSearchValue",
  ({ searchValue }, thunkAPI) => {
    return {
      searchValue: searchValue,
    };
  }
);

export const getProduct = createAsyncThunk(
  "product/get",
  async ({ page, searchValue }, thunkAPI) => {
    // thunkAPI.dispatch(setMessage(""));
    //thunkAPI.rejectWithValue();
    try {
      const data = await ProductService.getProduct(page, searchValue);
      //thunkAPI.dispatch(setLoading(false));
      //  thunkAPI.rejectWithValue();
      return {
        products: data.content,
        totalElements: data.totalElements,
        totalPages: data.totalPages,
        size: data.size,
        page: data.number,
      };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // thunkAPI.dispatch(setLoading(false));
      //thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const createProduct = createAsyncThunk(
  "product/create",
  async ({ product }, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    try {
      const data = await ProductService.createProduct(product);
      //thunkAPI.dispatch(getAll());
      thunkAPI.dispatch(getProduct({ page: 0, searchValue: "" }));
      thunkAPI.dispatch(setLoading(false));
      return thunkAPI.rejectWithValue();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setLoading(false));
      thunkAPI.dispatch(setMessage({ message: message }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getPrice = createAsyncThunk(
  "productPrice/getPrice",
  async ({ page, searchValue, status = "all" }, thunkAPI) => {
    // thunkAPI.dispatch(setMessage(""));
    //thunkAPI.rejectWithValue();
    try {
      const data = await ProductService.getAllPrices(page, searchValue, status);
      //thunkAPI.dispatch(setLoading(false));
      //  thunkAPI.rejectWithValue();
      return {
        prices: data.content,
        totalElements: data.totalElements,
        totalPages: data.totalPages,
        size: data.size,
        page: data.number,
      };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // thunkAPI.dispatch(setLoading(false));
      //thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const createPrice = createAsyncThunk(
  "productPrice/create",
  async ({ price }, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    try {
      const data = await ProductService.createPriceProduct(price);
      thunkAPI.dispatch(getPrice({ page: 0, searchValue: "" }));
      thunkAPI.dispatch(setLoading(false));
      return thunkAPI.rejectWithValue();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setLoading(false));
      thunkAPI.dispatch(setMessage({ message: message }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const approvePrice = createAsyncThunk(
  "productPrice/approvePrice",
  async ({ product }, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    try {
      const data = await ProductService.approvePrice(product);
      thunkAPI.dispatch(getPrice({ page: 0, searchValue: "" }));
      thunkAPI.dispatch(setLoading(false));
      return thunkAPI.rejectWithValue();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      thunkAPI.dispatch(setLoading(false));
      thunkAPI.dispatch(setMessage({ message: message }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateProduct = createAsyncThunk(
  "product/update",
  async ({ productItem, currentPage, searchValueCurrent }, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    try {
      const data = await ProductService.updateProduct(productItem);
      thunkAPI.dispatch(
        getProduct({
          page: currentPage ? currentPage : 0,
          searchValue: searchValueCurrent ? searchValueCurrent : "",
        })
      );
      thunkAPI.dispatch(setLoading(false));
      return thunkAPI.rejectWithValue();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setLoading(false));
      thunkAPI.dispatch(setMessage({ message: message }));
      thunkAPI.dispatch(getProduct({ page: 0, searchValue: "" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const deleteProduct = createAsyncThunk(
  "product/delete",
  async ({ id, currentPage = 0, searchValueCurrent = "" }, thunkAPI) => {
    try {
      const data = await ProductService.deleteProduct(id);
      thunkAPI.dispatch(setMessage({ message: "Rekordi u fshi me sukses!" }));
      thunkAPI.dispatch(
        getProduct({
          page: currentPage ? currentPage : 0,
          searchValue: searchValueCurrent ? searchValueCurrent : "",
        })
      );
      return thunkAPI.rejectWithValue();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message }));
      thunkAPI.dispatch(
        getProduct({
          page: currentPage ? currentPage : 0,
          searchValue: searchValueCurrent ? searchValueCurrent : "",
        })
      );
      return thunkAPI.rejectWithValue();
    }
  }
);

const productSlice = createSlice({
  name: "product",
  initialState,
  extraReducers: {
    [getProduct.fulfilled]: (state, action) => {
      state.products = action.payload.products;
      state.totalElements = action.payload.totalElements;
      state.totalPages = action.payload.totalPages;
      state.size = action.payload.size;
      state.searchValue = action.payload.searchValue;
    },
    [getProduct.rejected]: (state, action) => {
      state.products = null;
      state.totalElements = 0;
      state.totalPages = 0;
      state.size = 0;
      state.searchValue = "";
    },
    [getPrice.fulfilled]: (state, action) => {
      state.prices = action.payload.prices;
      state.totalElements = action.payload.totalElements;
      state.totalPages = action.payload.totalPages;
      state.size = action.payload.size;
      state.searchValue = action.payload.searchValue;
    },
    [getPrice.rejected]: (state, action) => {
      state.prices = null;
      state.totalElements = 0;
      state.totalPages = 0;
      state.size = 0;
      state.searchValue = "";
    },
    [setSearch.fulfilled]: (state, action) => {
      state.searchValue = action.payload.searchValue;
    },
    [setSearch.rejected]: (state, action) => {
      state.searchValue = "";
    },
  },
});

const { reducer } = productSlice;
export default reducer;
