import * as React from "react";
import { InputAdornment, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { Search } from "@mui/icons-material";

const SearchInput = (props) => {
  return (
    <Box>
      <TextField
        placeholder="Kërko..."
        type="search"
        autoComplete="off"
        onChange={props.onChange}
        // autoFocus="autoFocus"
        inputRef={props.inputRef}
        defaultValue={props.defaultValue}
        InputProps={{
          startAdornment: (
            <InputAdornment
              sx={{
                "&.MuiInputAdornment-root": {
                  minWidth: "0px !important",
                },
                "& .MuiSvgIcon-root": {
                  height: "22px",
                  width: "22px",
                },
              }}
              position="start"
            >
              <Search />
            </InputAdornment>
          ),
        }}
        sx={{
          width: "400px",
          background: "#F6F8FC",
          height: "40px !important",
          borderRadius: "5px",
          "& .MuiInputBase-root.MuiOutlinedInput-root": {
            backgroundColor: "#F6F8FC",
            borderRadius: "5px",
            textSize: "16px",
          },
          "& .MuiOutlinedInput-root": {
            height: "40px !important",
            borderRadius: "5px",
            "& fieldset ": {
              color: "red",
              borderRadius: "5px",
              border: "transparent",
              "&:error": {
                border: "1px solid red",
              },
            },

            "&:hover fieldset ": {
              border: "1px solid #7bb1ff",
              "&:error": {
                border: "1px solid red",
              },
            },
            "&.Mui-focused fieldset": {
              border: "1px solid #7bb1ff",
            },
          },
        }}
        {...props}
      ></TextField>
    </Box>
  );
};

export default SearchInput;
