import * as React from "react";
import {
  Autocomplete,
  autocompleteClasses,
  ClickAwayListener,
  InputAdornment,
  MenuItem,
  Popper,
  styled,
  TextField,
  Tooltip,
} from "@mui/material";
import { Box } from "@mui/system";
import PropTypes from "prop-types";
import CorporateFareOutlinedIcon from "@mui/icons-material/CorporateFareOutlined";

const SelectAutocomplate = (props) => {
  const idSelect = props.open ? "github-label" : undefined;
  return (
    <Box sx={{ width: "100%" }}>
      <Tooltip
        title={
          props.disableTooltip
            ? null
            : props?.value
            ? props?.source?.map((item) => {
                if (item?.id === props?.value) return item?.name;
              })
            : ""
        }
        arrow
      >
        <TextField
          select
          disabled
          name={props.name}
          autoComplete="on"
          id={props.id}
          variant="outlined"
          sx={{
            width: "100%",

            "& .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input.Mui-disabled":
              {
                WebkitTextFillColor: "#091B3D !important",
              },
            "& .MuiInputBase-root.MuiOutlinedInput-root": {
              border: props.error
                ? "1px solid red !important"
                : "1px solid transparent",
              "&:hover": {
                border: "1px solid #3B376C",
              },
              "&:focus": {
                border: "1px solid #3B376C",
              },
            },
          }}
          InputProps={{
            startAdornment: !props.textfield ? (
              <InputAdornment position="start">
                {props.label ? props.label : ""}
              </InputAdornment>
            ) : null,
          }}
          value={props.value}
          onClick={props.onClick}
          error={props.error}
          helperText={props.helperText}
        >
          {props.source
            ? props.source.map((item) => (
                <MenuItem key={item.id} value={item.id} name={item.name}>
                  {item.name}
                </MenuItem>
              ))
            : null}
        </TextField>
      </Tooltip>
      <StyledPopper
        id={idSelect}
        open={props.open}
        anchorEl={props.anchorEl}
        placement="bottom-start"
      >
        <ClickAwayListener onClickAway={props.onClickAway}>
          <div>
            <Autocomplete
              open
              onClose={props.onClose}
              value={props.value}
              onChange={props.onChange}
              disableCloseOnSelect
              PopperComponent={PopperComponent}
              renderTags={() => null}
              noOptionsText={props.noOptionsText}
              renderOption={(props, option) => (
                <li {...props}>
                  <Box sx={{ width: 17, height: 17, mr: "5px", ml: "-2px" }} />
                  <CorporateFareOutlinedIcon></CorporateFareOutlinedIcon>
                  <Box
                    component="span"
                    sx={{
                      width: 14,
                      height: 14,
                      flexShrink: 0,
                      borderRadius: "3px",
                      mr: 1,
                      mt: "2px",
                    }}
                    style={{ backgroundColor: option.color }}
                  />
                  <Box
                    sx={{
                      flexGrow: 1,
                      "& span": {
                        color: "#8b949e",
                      },
                    }}
                  >
                    {option.name}
                  </Box>
                </li>
              )}
              options={props.source ? props.source : []}
              getOptionLabel={(option) => option.name || ""}
              renderInput={(params) => (
                <TextField
                  sx={{ width: "100%" }}
                  ref={params.InputProps.ref}
                  inputProps={params.inputProps}
                  onChange={props.onchangesearch}
                  inputRef={props.inputRef}
                  autoFocus
                  placeholder={props.placeholder}
                />
              )}
            />
          </div>
        </ClickAwayListener>
      </StyledPopper>
    </Box>
  );
};

const StyledAutocompletePopper = styled("div")(({ theme }) => ({
  [`& .${autocompleteClasses.paper}`]: {
    boxShadow: "none",
    margin: 0,
    color: "inherit",
    fontSize: 13,
  },
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    height: "200px",
    backgroundColor: theme.palette.mode === "light" ? "#fff" : "#1c2128",
    padding: 0,
    [`& .${autocompleteClasses.option}`]: {
      alignItems: "flex-start",
      padding: 8,
      minHeight: "60px",
      height: "auto",
      fontSize: "16px",
      alignItems: "center",
      borderBottom: `1px solid  ${
        theme.palette.mode === "light" ? " #eaecef" : "#30363d"
      }`,
      '&[aria-selected="true"]': {
        backgroundColor: "transparent",
      },
      '&[data-focus="true"], &[data-focus="true"][aria-selected="true"]': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  },
  [`&.${autocompleteClasses.popperDisablePortal}`]: {
    position: "relative",
  },
}));

function PopperComponent(props) {
  const { disablePortal, anchorEl, open, ...other } = props;
  return <StyledAutocompletePopper {...other} />;
}

PopperComponent.propTypes = {
  anchorEl: PropTypes.any,
  disablePortal: PropTypes.bool,
  open: PropTypes.bool.isRequired,
};

const StyledPopper = styled(Popper)(({ theme }) => ({
  zIndex: 10,
  paddingBottom: "20px",
  border: `1px solid ${theme.palette.mode === "light" ? "#e1e4e8" : "#30363d"}`,
  boxShadow: `0 12px 24px ${
    theme.palette.mode === "light" ? "rgba(149, 157, 165, 0.5)" : "rgb(1, 4, 9)"
  }`,
  borderRadius: "15px",
  width: 480,
  fontSize: 13,
  color: theme.palette.mode === "light" ? "#24292e" : "#c9d1d9",
  backgroundColor: theme.palette.mode === "light" ? "#fff" : "#1c2128",
}));

export default SelectAutocomplate;
