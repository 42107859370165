import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";
import { setLoading } from "./loading";
import FormSubmissionService from "../services/formSubmission.service";

const initialState = {
  formInputesSubmission: [],
  logsFormSubmission: [],
  analystCheck: false,
  previousSubmissions: [],
};

export const getFormSubmissionById = createAsyncThunk(
  "form/getFormSubmissionById",
  async ({ id }, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    try {
      const data = await FormSubmissionService.getFormSubmissionId(id);

      const orderValue = data.form.formInputs.sort((a, b) => {
        return a.order - b.order;
      });
      thunkAPI.dispatch(setLoading(false));
      return {
        previousSubmissions: data?.form?.previousSubmissions,
        formInputesSubmission: orderValue,
      };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      thunkAPI.dispatch(setLoading(false));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const setFormSubmissionChangeAnswer = createAsyncThunk(
  "form/changeAnswer",
  async ({ state, id, answerValue }, thunkAPI) => {
    const newArr = state.map((obj) => {
      if (obj.id === id) {
        return { ...obj, answer: { id: obj.answer.id, answer: answerValue } };
      }
      return obj;
    });

    return { formInputesSubmission: newArr };
  }
);

export const getLogsFormSubmissionById = createAsyncThunk(
  "form/getLogsFormSubmissionById",
  async ({ id }, thunkAPI) => {
    // thunkAPI.dispatch(setLoading(true));
    try {
      const data = await FormSubmissionService.getLogsFormSubmission(id);
      // thunkAPI.dispatch(setLoading(false));
      return { logsFormSubmission: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // thunkAPI.dispatch(setMessage(message));
      // thunkAPI.dispatch(setLoading(false));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const setCheckedFormById = createAsyncThunk(
  "form/setCheckedFormById",
  async ({ id }, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    try {
      const data = await FormSubmissionService.setCheckedForm(id);
      thunkAPI.dispatch(setLoading(false));
      return { analystCheck: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      thunkAPI.dispatch(setLoading(false));
      return thunkAPI.rejectWithValue();
    }
  }
);

const formInputesSubmissionSlice = createSlice({
  name: "formInputesSubmission",
  initialState,
  extraReducers: {
    [getFormSubmissionById.fulfilled]: (state, action) => {
      state.formInputesSubmission = action.payload.formInputesSubmission;
      state.previousSubmissions = action.payload.previousSubmissions;
    },
    [getFormSubmissionById.rejected]: (state, action) => {
      state.formInputesSubmission = [];
      state.previousSubmissions = [];
    },
    [setFormSubmissionChangeAnswer.fulfilled]: (state, action) => {
      state.formInputesSubmission = action.payload.formInputesSubmission;
    },
    [setFormSubmissionChangeAnswer.rejected]: (state, action) => {
      state.formInputesSubmission = [];
    },
    [getLogsFormSubmissionById.fulfilled]: (state, action) => {
      state.logsFormSubmission = action.payload.logsFormSubmission;
    },
    [getLogsFormSubmissionById.rejected]: (state, action) => {
      state.logsFormSubmission = [];
    },
  },
});

const { reducer } = formInputesSubmissionSlice;
export default reducer;
