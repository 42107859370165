const FORM_CATEGORY_COLUMNS = [
  {
    field: "name",
    headerName: "Emër",
    width: 500,
    minWidth: 500,
    flex: 1,
    editable: false,
  },
];

export default FORM_CATEGORY_COLUMNS;
