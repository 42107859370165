import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Box } from "@mui/system";
import { Chip, Divider, TextField, Typography } from "@mui/material";

const DialogComment = (props) => {
  const { inputref } = props;
  return (
    <div>
      <Dialog
        {...props}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ borderRadius: "15px" }}
        PaperProps={{
          style: {
            borderRadius: "15px",
            padding: "40px",
            width: "580px",
            background: "#F9F0ED",
            height: "500px",
          },
        }}
      >
        <Box sx={{ height: "250px", bgcolor: "#F9F0ED", textAlign: "center" }}>
          <DialogTitle
            id="alert-dialog-title"
            sx={{
              "&.MuiTypography-root.MuiDialogTitle-root": {
                padding: 0,
              },
              fontSize: "30px",
              color: "#CD3728",
              fontFamily: "Basier Circle Medium",
            }}
          >
            {props.title ? props.title : "Kujdes!"}
          </DialogTitle>
          <DialogContent
            sx={{
              padding: 2,
              fontSize: "25px",
              color: "#091B3D",
              fontFamily: "Basier Circle Medium",
            }}
          >
            <DialogContentText
              sx={{
                padding: 2,
                fontSize: "25px",
                color: "#091B3D",
                fontFamily: "Basier Circle",
                padding: "55px",
                //lineHeight: "30px",
                height: "300px",
                "&.MuiTypography-root.MuiDialogContentText-root": {
                  padding: 0,
                },
              }}
              id="alert-dialog-description"
            >
              <DialogContentText
                sx={{
                  fontSize: "15px",
                  color: "#091B3D",
                  fontFamily: "Basier Circle",
                  padding: "5px",
                  lineHeight: "30px",
                }}
                id="alert-dialog-description"
              >
                {props.description
                  ? props.description
                  : " Jeni të sigurt që doni ta fshini këtë rekord?"}
              </DialogContentText>

              <TextField
                multiline
                maxRows={4}
                //inputRef={props.inputref}
                ref={(ref) => {
                  if (inputref) {
                    inputref(ref);
                  }

                  ref = ref;
                }}
                sx={{
                  width: "100%",

                  "& .MuiInputBase-root.MuiOutlinedInput-root.MuiOutlinedInput-root":
                    {
                      height: "115px",
                    },
                }}
                onChange={props.onchangecomment}
                value={props.value}
                helperText={props.error ? "Komenti është i detyruar" : null}
                {...props}
              ></TextField>
              <Box
                style={{ height: "90px", marginTop: "10px", textAlign: "left" }}
              >
                {/* <TextField
                  // value={props.files}
                  onChange={props.onchangefile}
                  type="file"
                  hidden
                ></TextField> */}
                {!props.filename && (
                  <Button
                    variant="outlined"
                    component="label"
                    color="primary"
                    sx={{
                      height: "50px",
                      color: "#CD3728",
                      fontSize: "16px",
                      backgroundColor: "#FFF",
                    }}
                  >
                    {" "}
                    {/* <AddIcon/>  */}
                    Ngarko dokument
                    <input
                      onChange={props.onchangefile}
                      value={props.filevalue}
                      type="file"
                      hidden
                    />
                  </Button>
                )}
                {props.filename && (
                  <Chip
                    sx={{
                      height: "30px",
                      color: "#CD3728",
                      fontSize: "12px",
                    }}
                    label={props.filename}
                    variant="outlined"
                    onDelete={props.onDelete}
                  ></Chip>
                )}
              </Box>
            </DialogContentText>
          </DialogContent>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexGrow: 1,
            alignItems: "center",
            columnGap: 1.5,
            bgcolor: "#F9F0ED",
            justifyContent: "center",
            height: "56px !important",
            px: "24px",
          }}
        >
          <Button
            variant="text"
            sx={{
              color: "#CD3728",
              width: "160px",
              height: "75px",
              fontSize: "20px",
              backgroundColor: "#FFF",
            }}
            onClick={props.disagreeComment}
          >
            Kthehu
          </Button>

          <Button
            variant="contained"
            sx={{
              // backgroundColor: "#EF5828",
              width: "160px",
              height: "75px",
              fontSize: "20px",
            }}
            color="error"
            onClick={props.submitComment}
            autoFocus
          >
            Dërgo
          </Button>
        </Box>
      </Dialog>
    </div>
  );
};

export default DialogComment;
