import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";
import { setLoading } from "./loading";
import FormCategoryService from "../services/formCategory.service";

const initialState = {
  formCategory: [],
  totalElements: 0,
  totalPages: 0,
  size: 10,
  page: 0,
  searchValue: "",
  currentPage: 0,
  searchValueCurrent: "",
};

export const setSearch = createAsyncThunk(
  "form-category/setSearchValue",
  ({ searchValue }, thunkAPI) => {
    return {
      searchValue: searchValue,
    };
  }
);
export const getAllFormCategory = createAsyncThunk(
  "formCategory/getAllFormCategory",
  async ({ page, searchValue, size, filterSelect = false }, thunkAPI) => {
    try {
      const data = await FormCategoryService.getAllFormCategory(
        page,
        searchValue,
        size,
        filterSelect
      );
      return {
        formCategory: data.content,
        totalElements: data.totalElements,
        totalPages: data.totalPages,
        size: data.size,
        currentPage: data.number,
        searchValueCurrent: searchValue,
      };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const createFormCategory = createAsyncThunk(
  "formCategory/create",
  async ({ formCategoryItem }, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    try {
      const data = await FormCategoryService.createFormCategory(
        formCategoryItem
      );
      thunkAPI.dispatch(getAllFormCategory({ page: 0, searchValue: "" }));
      thunkAPI.dispatch(setLoading(false));
      return thunkAPI.rejectWithValue();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setLoading(false));
      thunkAPI.dispatch(setMessage({ message: message }));
      thunkAPI.dispatch(getAllFormCategory({ page: 0, searchValue: "" }));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const updateFormCategory = createAsyncThunk(
  "formCategory/update",
  async ({ formCategoryItem, currentPage, searchValueCurrent }, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    try {
      const data = await FormCategoryService.updateFormCategory(
        formCategoryItem
      );
      thunkAPI.dispatch(
        getAllFormCategory({
          page: currentPage ? currentPage : 0,
          searchValue: searchValueCurrent ? searchValueCurrent : "",
        })
      );
      // thunkAPI.dispatch(getAllUnit({ page: 0, searchValue: "" }));
      thunkAPI.dispatch(setLoading(false));
      return thunkAPI.rejectWithValue();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setLoading(false));
      thunkAPI.dispatch(setMessage({ message: message }));
      thunkAPI.dispatch(getAllFormCategory({ page: 0, searchValue: "" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const deleteFormCategory = createAsyncThunk(
  "formCategory/delete",
  async ({ id, currentPage = 0, searchValueCurrent = "" }, thunkAPI) => {
    try {
      const data = await FormCategoryService.deleteFormCategory(id);
      thunkAPI.dispatch(setMessage({ message: "Rekordi u fshi me sukses!" }));
      thunkAPI.dispatch(
        getAllFormCategory({
          page: currentPage ? currentPage : 0,
          searchValue: searchValueCurrent ? searchValueCurrent : "",
        })
      );
      return thunkAPI.rejectWithValue();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message }));
      thunkAPI.dispatch(
        getAllFormCategory({
          page: currentPage ? currentPage : 0,
          searchValue: searchValueCurrent ? searchValueCurrent : "",
        })
      );
      return thunkAPI.rejectWithValue();
    }
  }
);

const formCategorySlice = createSlice({
  name: "formCategory",
  initialState,
  extraReducers: {
    [getAllFormCategory.fulfilled]: (state, action) => {
      state.formCategory = action.payload.formCategory;
      state.totalElements = action.payload.totalElements;
      state.totalPages = action.payload.totalPages;
      state.size = action.payload.size;
      state.searchValue = action.payload.searchValue;
      state.currentPage = action.payload.currentPage;
      state.searchValueCurrent = action.payload.searchValueCurrent;
    },
    [getAllFormCategory.rejected]: (state, action) => {
      state.formCategory = null;
      state.totalElements = 0;
      state.totalPages = 0;
      state.size = 10;
      state.searchValue = "";
    },
    [setSearch.fulfilled]: (state, action) => {
      state.searchValue = action.payload.searchValue;
    },
    [setSearch.rejected]: (state, action) => {
      state.searchValue = "";
    },
  },
});

const { reducer } = formCategorySlice;
export default reducer;
