import * as React from "react";
import {
  TextField,
  Button,
  Stack,
  MenuItem,
  InputAdornment,
  IconButton,
  Checkbox,
  InputLabel,
} from "@mui/material";
import { Save } from "@mui/icons-material";
import ModalUI from "../UI/ModalUI";
import { ROLE, STATUS_USERS } from "../../data/dummy-data";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import authHeader from "../../services/auth-header";
import { createUser, getAll, updateUser } from "../../store/perdorues";
import { getAllUnit } from "../../store/njesiRaportuese";
import SelectAutocomplate from "../UI/SelectAutocomplate";
import { APP_URL } from "../../services/http-common";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Autocomplete from "@mui/material/Autocomplete";
import { getCustomTextLength } from "../helper/CustomLabel";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import { useNavigate } from "react-router-dom";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const PerdoruesModalEditor = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { njesiRaportuese } = useSelector((state) => state.njesiRaportuese);
  const { data, isstatusnew, open } = props;

  const searchStateUnit = React.useRef("");
  const [searchValueServerUnit, setSearchValueServerUnit] = React.useState("");
  const [reportingUnitId, setReportingUnitId] = React.useState("");
  const [state, setState] = React.useState({
    username: "",
    email: "",
    firstName: "",
    lastName: "",
    role: "",
    reportingUnit: {},
    reportingUnitIds: [],
    reportingUnitId: "",
  });
  const [errorValues, setErrorValues] = React.useState([]);
  const [usernameExist, setUsernameExist] = React.useState(false);
  const [anchorElUnit, setAnchorElUnit] = React.useState(null);
  const [strongPassword, setStrongPassword] = React.useState(true);
  const [validEmail, setValidEmail] = React.useState(true);
  const [passwordControl, setPasswordControl] = React.useState({
    strongPassword: true,
    showPassword: false,
  });

  const handleClickUnit = (event) => {
    setAnchorElUnit(event.currentTarget);
  };

  const handleCloseUnit = () => {
    setAnchorElUnit(null);
  };
  const openUnit = Boolean(anchorElUnit);

  const searchItemsUnit = (e) => {
    // const searchValue = e.target.value;
    // if (searchValue !== "") {
    //   searchStateUnit.current.value = searchValue;
    //   let filterTimeout;
    //   filterTimeout = setTimeout(() => {
    //     setSearchValueServerUnit(searchStateUnit.current.value);
    //   }, 1000);
    // } else {
    //   setSearchValueServerUnit("");
    //   dispatch(getAllUnit({ page: 0, searchValue: "" }));
    // }
  };

  React.useEffect(() => {
    dispatch(
      getAllUnit({ page: 0, searchValue: "", size: 1000, filterSelect: true })
    );
    setErrorValues([]);
    setStrongPassword(true);
    setAnchorElUnit(null);
    if (data) {
      setState((state) => ({
        ...state,
        id: data.id ? data.id : "",
        username: data.username ? data.username : "",
        email: data.email ? data.email : "",
        firstName: data.firstName ? data.firstName : "",
        lastName: data.lastName ? data.lastName : "",
        password: data.password ? data.password : "",
        role: data.role ? data.role : "",
        reportingUnit: data.reportingUnit ? data.reportingUnit : null,
        reportingUnitId: data.reportingUnit ? data.reportingUnit.id : "",
        reportingUnitIds: data.reportingUnits
          ? njesiRaportuese?.filter((item) =>
              data?.reportingUnits.map((item) => item.id).includes(item.id)
            )
          : null,
        reportingUnits: data.reportingUnits
          ? njesiRaportuese?.filter((item) =>
              data?.reportingUnits?.map((item) => item.id).includes(item.id)
            )
          : null,
        status: data.status ? data.status : "",
        company: data.company ? data.company : [],
      }));
      setReportingUnitId(data.reportingUnit ? data.reportingUnit?.id : "");
    }
  }, [data, open]);
  React.useEffect(() => {
    setAnchorElUnit(null);
  }, []);

  const changeUsername = (event) => {
    setUsernameExist(false);
    const { value } = event.target;
    setState((state) => ({
      ...state,
      username: value ? value : "",
    }));
  };
  const changeEmail = (event) => {
    const { value } = event.target;
    setState((state) => ({
      ...state,
      email: value ? value : "",
    }));
  };

  const changeFirstName = (event) => {
    const { value } = event.target;
    setState((state) => ({
      ...state,
      firstName: value ? value : "",
    }));
  };

  const changeLastName = (event) => {
    const { value } = event.target;
    setState((state) => ({
      ...state,
      lastName: value ? value : "",
    }));
  };

  const changePassword = (event) => {
    const { value } = event.target;
    setState((state) => ({
      ...state,
      password: value ? value : "",
    }));
  };
  const changeRole = (event) => {
    const { value } = event.target;
    setState((state) => ({
      ...state,
      role: value ? value : "",
    }));
  };

  const changeReportingUnit = (newValue) => {
    setState((state) => ({
      ...state,
      reportingUnit: { id: newValue.id, name: newValue.name },
      reportingUnitId: newValue.id,
    }));
    setReportingUnitId(newValue.id);
  };

  const changeStatus = (event) => {
    const { value } = event.target;
    setState((state) => ({
      ...state,
      status: value ? value : "",
    }));
  };

  const onBlurUsername = (event) => {
    const { value } = event.target;
    usernameExists(value);
  };
  const onBlurPassword = (event) => {
    const { value } = event.target;
    var strongRegex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
    );
    if (value.match(strongRegex)) {
      setStrongPassword(true);
    } else {
      setStrongPassword(false);
    }
  };

  const onBlurEmail = (event) => {
    const { value } = event.target;
    if (
      String(value).match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      setValidEmail(true);
    } else {
      setValidEmail(false);
    }
  };
  const handleClickShowPassword = () => {
    setPasswordControl({
      ...passwordControl,
      showPassword: !passwordControl.showPassword,
    });
  };

  const saveUser = () => {
    var initialError = [];

    Object.keys(state).map(function (key, index) {
      if (String(key === "role")) {
        if (state[key]?.value === "") {
          initialError.push(key);
        }
      }

      if (state[key] === "" || state[key] === undefined) {
        initialError.push(key);
      } else {
      }
    });
    var initialErrorTemp = [];
    const { reportingUnit, id, reportingUnitIds, ...newUser } = state;
    if (!isstatusnew) {
      initialErrorTemp = initialError.filter(
        (item) =>
          item !== "password" &&
          item !== "reportingUnit" &&
          item !== "reportingUnitIds" &&
          item !== "reportingUnitId" &&
          item !== "firstName" &&
          item !== "lastName"
      ); // remove key item and reportingUnit doesn not exist

      setErrorValues(initialErrorTemp);
    } else {
      initialErrorTemp = initialError.filter(
        (item) =>
          item !== "id" && item !== "status" && item !== "reportingUnitIds"
        // item !== "reportingUnit"
      ); // remove id, status new user
      //initialErrorTemp = initialError;
      setErrorValues(initialError);
    }

    if (isstatusnew) {
      initialErrorTemp = initialError.filter(
        (item) =>
          item !== "id" &&
          item !== "password" &&
          item !== "status" &&
          item !== "reportingUnitIds" &&
          item !== "reportingUnitId" &&
          item !== "firstName" &&
          item !== "lastName"
      ); // remove id new user
      //initialErrorTemp = initialError;
      setErrorValues(initialErrorTemp);
    }

    if (
      state.role === "ROLE_EDITOR" ||
      state.role === "ROLE_ANALYST" ||
      state.role === "ROLE_SOFT_EXECUTIVE" ||
      state.role === "ROLE_MINOR_EXECUTIVE"
    ) {
      if (isstatusnew) {
        initialErrorTemp = initialError.filter(
          (item) =>
            item !== "id" &&
            item !== "password" &&
            item !== "reportingUnitId" &&
            item !== "status" &&
            item !== "reportingUnitIds" &&
            item !== "company" &&
            item !== "firstName" &&
            item !== "lastName"
        );
        setErrorValues(initialErrorTemp);
      }

      if (!isstatusnew) {
        initialErrorTemp = initialError.filter(
          (item) =>
            item !== "reportingUnitId" &&
            item !== "password" &&
            item !== "status" &&
            item !== "reportingUnitIds" &&
            item !== "firstName" &&
            item !== "lastName"
        );
        setErrorValues(initialErrorTemp);
      }

      if (!isstatusnew) {
        initialErrorTemp = initialError.filter(
          (item) =>
            item !== "reportingUnitId" &&
            item !== "password" &&
            item !== "status" &&
            item !== "reportingUnitIds" &&
            item !== "firstName" &&
            item !== "lastName"
        );
        setErrorValues(initialErrorTemp);
      }

      if (isstatusnew) {
        initialErrorTemp = initialError.filter(
          (item) =>
            item !== "id" &&
            item !== "reportingUnitId" &&
            item !== "status" &&
            item !== "reportingUnitIds" &&
            item !== "company" &&
            item !== "firstName" &&
            item !== "lastName"
        );

        setErrorValues(initialErrorTemp);
      }
    }
    if (state?.role === "ROLE_MINOR_EXECUTIVE") {
      if (state?.reportingUnitIds?.length === 0) {
        initialErrorTemp.push("reportingUnitIds");
        setErrorValues(initialErrorTemp);
      }
    }
    if (initialErrorTemp.length === 0 && strongPassword) {
      if (isstatusnew) {
        const {
          id,
          status,
          company,
          reportingUnitIds,
          reportingUnits,
          reportingUnit,
          ...newUser
        } = state;

        if (state.role === "ROLE_MINOR_EXECUTIVE") {
          const reportingUnitIdsTemp1 = [];
          const reportingUnitIdsTemp = state.reportingUnitIds.filter((item) =>
            reportingUnitIdsTemp1.push(item.id)
          );

          const newUserMinor = {
            ...newUser,
            reportingUnitIds: reportingUnitIdsTemp1,
          };
          dispatch(createUser({ user: newUserMinor }));
        } else {
          // const { reportingUnit, ...updateUserEnd } = state;

          const updateUserAnalist = {
            ...newUser,
            reportingUnit: state.reportingUnitId,
          };

          if (state.role === "ROLE_EDITOR") {
            dispatch(createUser({ user: updateUserAnalist }));
          } else {
            dispatch(createUser({ user: newUser }));
          }

          // dispatch(createUser({ user: newUser }));
        }

        // dispatch(createUser({ user: newUser }));
      } else {
        const {
          username,
          reportingUnits,
          reportingUnitIds,
          company,
          ...updateUserState
        } = state;
        if (state.role === "ROLE_MINOR_EXECUTIVE") {
          const reportingUnitIdsTemp1 = [];
          const reportingUnitIdsTemp = state.reportingUnitIds.filter((item) =>
            reportingUnitIdsTemp1.push(item.id)
          );

          const updateUserMinor = {
            ...updateUserState,
            reportingUnitIds: reportingUnitIdsTemp1,
          };

          dispatch(updateUser({ user: updateUserMinor }));
        } else {
          const {
            username,
            reportingUnits,
            reportingUnitIds,
            company,
            reportingUnitId,
            ...updateUserState2
          } = state;

          const { reportingUnit, ...updateUserEnd } = state;
          const updateUserAnalist = {
            ...updateUserState2,
            reportingUnit: state.reportingUnitId,
          };

          if (state.role === "ROLE_EDITOR") {
            dispatch(updateUser({ user: updateUserAnalist }));
          } else {
            dispatch(updateUser({ user: updateUserEnd }));
          }
        }
      }
      dispatch(getAll());
      props.onClose();
    }
  };

  const checkError = (item) => {
    if (errorValues.find((element) => element === item)) {
      return true;
    } else {
      return false;
    }
  };

  async function usernameExists(username) {
    await axios
      .get(APP_URL + "users/username/" + username, {
        headers: authHeader(),
      })
      .then((response) => {
        setUsernameExist(response.data.exists);
      });
  }

  const openCompanyProfil = () => {
    navigate("/profile", { state: { company: state.company } });
  };

  return (
    <ModalUI
      multifield
      buttons={
        <Stack sx={{ display: "flex", width: "100%" }}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "primary.dark",
              display: "flex",
              marginTop: "10px",
            }}
            startIcon={<Save />}
            onClick={saveUser}
          >
            {isstatusnew ? "Shto" : "Ndrysho"}
          </Button>

          {state.role === "ROLE_OPERATOR" && (
            <Button
              variant="outlined"
              sx={{
                // backgroundColor: "primary.dark",
                color: "primary.dark",
                display: "flex",
                marginTop: "20px",
                height: "55px",
                fontFamily: "Basier Circle",
                fontSize: "18px",
                width: "100%",
              }}
              startIcon={<PersonOutlineIcon />}
              onClick={openCompanyProfil}
            >
              Shiko Profilin e Kompanise
            </Button>
          )}
        </Stack>
      }
      onClickAway={handleCloseUnit}
      {...props}
    >
      <Stack spacing={2}>
        <TextField
          disabled={!isstatusnew}
          name="username"
          autoComplete="off"
          id="username"
          variant="outlined"
          value={state.username}
          onChange={changeUsername}
          onBlur={onBlurUsername}
          error={usernameExist || checkError("username")}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">Përdoruesi</InputAdornment>
            ),
          }}
          helperText={
            isstatusnew
              ? usernameExist
                ? "Ky perdorues ekziston"
                : null
              : null
          }
        />
      </Stack>
      <Stack spacing={2}>
        <TextField
          name="email"
          autoComplete="off"
          id="email"
          type="email"
          variant="outlined"
          value={state.email}
          onChange={changeEmail}
          error={checkError("email") || !validEmail}
          helperText={
            checkError("email")
              ? "Emaili është i detyruar"
              : !validEmail
              ? "Vendos email të saktë"
              : null
          }
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">Email</InputAdornment>
            ),
          }}
          onBlur={onBlurEmail}
        />
      </Stack>
      {/* <Stack spacing={2}>
        <TextField
          name="firstName"
          autoComplete="off"
          id="firstName"
          type="text"
          variant="outlined"
          error={checkError("firstName")}
          helperText={checkError("firstName") ? "Emri është i detyruar" : null}
          value={state.firstName}
          onChange={changeFirstName}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">Emri</InputAdornment>
            ),
          }}
        />
      </Stack>
      <Stack spacing={2}>
        <TextField
          name="lastName"
          autoComplete="off"
          id="lastName"
          type="text"
          variant="outlined"
          error={checkError("lastName")}
          helperText={
            checkError("lastName") ? "Mbiemri është i detyruar" : null
          }
          value={state.lastName}
          onChange={changeLastName}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">Mbiemri</InputAdornment>
            ),
          }}
        />
      </Stack> */}
      <Stack spacing={2}>
        <TextField
          name="password"
          autoComplete="new-password"
          id="password"
          type={passwordControl.showPassword ? "text" : "password"}
          variant="outlined"
          value={state.password}
          error={checkError("password") || !strongPassword}
          onBlur={onBlurPassword}
          helperText={
            checkError("password")
              ? "Fjalëkalimi është i detyruar"
              : !strongPassword
              ? "Min 8 karaktere, karakter special, shkronjë të madhe, numër"
              : null
          }
          onChange={changePassword}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">Fjalëkalimi</InputAdornment>
            ),
            endAdornment: (
              <InputAdornment
                sx={{
                  "&.MuiInputAdornment-root.MuiInputAdornment-root": {
                    minWidth: "60px",
                  },
                }}
                position="end"
              >
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  // onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {passwordControl.showPassword ? (
                    <Visibility />
                  ) : (
                    <VisibilityOff />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>
      <Stack spacing={2}>
        <TextField
          labelId="demo-simple-select-label"
          select
          name="role"
          id="role"
          error={checkError("role")}
          helperText={checkError("role") ? "Roli është i detyruar" : null}
          value={state.role ? state.role : null}
          onChange={changeRole}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">Roli</InputAdornment>
            ),
          }}
        >
          {ROLE.map((item) => (
            <MenuItem key={item.value} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </TextField>
      </Stack>
      {state.role === "ROLE_EDITOR" && (
        <Stack spacing={2}>
          <SelectAutocomplate
            name="reportingUnitId"
            id="reportingUnitId"
            label="Drejtoria"
            placeholder="Kërko Njësi"
            noOptionsText="Nuk ka njësi"
            value={reportingUnitId ? reportingUnitId : ""}
            error={checkError("reportingUnitId")}
            onClick={handleClickUnit}
            source={njesiRaportuese}
            open={openUnit}
            anchorEl={anchorElUnit}
            onClickAway={handleCloseUnit}
            onClose={(event, reason) => {
              if (reason === "escape") {
                handleCloseUnit();
              }
            }}
            onChange={(event, newValue, reason) => {
              if (
                event.type === "keydown" &&
                event.key === "Backspace" &&
                reason === "removeOption"
              ) {
                return;
              }
              changeReportingUnit(newValue);
              handleCloseUnit();
            }}
            onchangesearch={searchItemsUnit}
            inputRef={searchStateUnit}
          ></SelectAutocomplate>
        </Stack>
      )}
      {state.role === "ROLE_MINOR_EXECUTIVE" && (
        <Stack spacing={2}>
          <InputLabel sx={{ fontSize: "16px", color: "#091B3D" }}>
            Njësitë:
          </InputLabel>
          <Autocomplete
            multiple
            onOpen={openUnit}
            limitTags={1}
            // onClick={handleClickIndikator}
            sx={{
              "& .MuiAutocomplete-root .MuiOutlinedInput-root": {
                padding: "0px !important",
                width: "100%",
              },
              width: "100%",
              maxWidth: "100%",
            }}
            // renderTags={
            //   (values) =>
            //     state.reportingUnits !== []
            //       ? getCustomTextLength(state.reportingUnits[0]?.name, 20) +
            //         String(state.reportingUnits?.length > 1 ? "  +" : "") +
            //         String(
            //           state.reportingUnits?.length > 1
            //             ? state.reportingUnits?.length - 1
            //             : ""
            //         )
            //       : ""

            //   // (values) => {
            //   //   return values ? values[0].join("...") : "";
            //   // }

            //   // (values) =>
            //   // values.map((value) => value.label).join(", ")
            // }
            name="reportingUnitIds"
            id="reportingUnitIds"
            label="Njësite"
            placeholder="Kërko Njësi"
            noOptionsText="Nuk ka njësi"
            error={checkError("reportingUnitIds")}
            options={njesiRaportuese}
            disableCloseOnSelect
            value={state?.reportingUnitIds ? state?.reportingUnitIds : []}
            getOptionLabel={(option) => option.name}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={
                    state?.reportingUnitIds?.find((item) => {
                      return item?.id === option?.id;
                    }) || selected
                  }
                  key={option.id}
                />
                {option.name}
              </li>
            )}
            onChange={(event, newValue, reason) => {
              if (
                event.type === "keydown" &&
                event.key === "Backspace" &&
                reason === "removeOption"
              ) {
                return;
              }

              setState((state) => ({
                ...state,
                reportingUnitIds: newValue ? newValue : [],
              }));
            }}
            renderInput={(params) => (
              <TextField
                error={checkError("reportingUnitIds")}
                helperText={
                  checkError("reportingUnitIds")
                    ? "Njësitë është i detyruar"
                    : null
                }
                sx={{
                  minWidth: "250px",
                  maxWidth: "100%",
                  width: "100%",
                  // minHeight: "75px",

                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  // height: "auto",
                  marginRight: "20px",
                  //  width: "min-content",

                  "& .Mui-disabled": {
                    "fieldset ": {
                      // borderColor: "#72aeff !important",
                      minWidth: "250px",
                      maxWidth: "100%",
                      width: "100%",
                    },
                  },
                  "& .MuiInputBase-root.MuiOutlinedInput-root": {
                    backgroundColor: "#f6f8fc",
                    borderRadius: "12px",
                    height: "auto",
                    minHeight: "75px",
                    width: "100%",
                  },
                  "& .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input.Mui-disabled":
                    {
                      WebkitTextFillColor: "#091B3D !important",
                    },
                }}
                {...params}
              />
            )}
          />
        </Stack>
      )}
      {!isstatusnew && (
        <Stack spacing={2}>
          <TextField
            labelId="demo-simple-select-label"
            select
            name="status"
            id="status"
            error={checkError("status")}
            helperText={
              checkError("status") ? "Statusi është i detyruar" : null
            }
            value={state.status ? state.status : null}
            onChange={changeStatus}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">Statusi</InputAdornment>
              ),
            }}
          >
            {STATUS_USERS.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </TextField>
        </Stack>
      )}
    </ModalUI>
  );
};
export default PerdoruesModalEditor;
