import axios from "axios";
import authHeader from "./auth-header";
import { APP_URL } from "./http-common";

const URLEntityName = APP_URL + "form-submission/graph";

const getGraphData = (indikators) => {
  return axios
    .post(URLEntityName, indikators, {
      headers: authHeader(),
    })
    .then((response) => {
      return response.data;
    });
};

const graphData = {
  getGraphData,
};
export default graphData;
