import * as React from "react";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { useDemoData } from "@mui/x-data-grid-generator";
import ModalEditor from "./ModalEditor";
import { Button, IconButton } from "@mui/material";
import { Add, Edit } from "@mui/icons-material";
import gridLabels from "../Labels/GridLabels";

const VISIBLE_FIELDS = ["name", "rating", "tipi", "dateCreated", "isAdmin"];

const CustomGrid = () => {
  const { data } = useDemoData({
    dataSet: "Employee",
    visibleFields: VISIBLE_FIELDS,
    rowLength: 100,
  });

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [njesi, setNjesi] = React.useState({});

  const initialNjesi = {
    id: "",
    name: "",
    roli: "",
    tipi: "",
  };

  const [filterModel, setFilterModel] = React.useState({
    items: [
      {
        columnField: "rating",
        operatorValue: ">",
        value: "2.5",
      },
    ],
  });

  const currentlySelected = (e) => {
    setNjesi(e.row);
    setOpen(true);
  };
  const addNew = () => {
    setNjesi(initialNjesi);
    setOpen(true);
  };
  // const handleChangeName = (e) => {
  //   //setName(e.target.value);
  //   setNjesi({ ...njesi, name: e.target.value });
  // };

  // const saveUser = () => {
  //   setDataLocal({ ...dataLocal, rows: { user } });
  // };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarFilterButton></GridToolbarFilterButton>
        <GridToolbarExport></GridToolbarExport>
        <Button
          variant="text"
          size="large"
          startIcon={<Add />}
          onClick={addNew}
        >
          Shto
        </Button>
      </GridToolbarContainer>
    );
  }
  return (
    <div style={{ height: 700, width: "100%" }}>
      <DataGrid
        labelRowsPerPage="Artikuj per faqe"
        // {...data}
        disableSelectionOnClick
        columns={[
          //...data.columns,
          { field: "name", headerName: "Emri", width: 200, editable: false },
          {
            field: "tipi",
            headerName: "Te drejtat",
            width: 250,
            editable: false,
          },
          {
            field: "roli",
            headerName: "Roli",
            width: 250,
            editable: false,
          },
          {
            headerName: "Editim",
            field: "editim",
            editable: false,
            width: 150,
            renderCell: (params) => (
              <strong>
                <IconButton
                  variant="outlined"
                  color="primary"
                  size="small"
                  style={{ marginLeft: 16 }}
                  onClick={currentlySelected}
                >
                  <Edit />
                </IconButton>
              </strong>
            ),
          },
        ]}
        rows={[
          {
            id: 1,
            name: "Enea Aliko",
            roli: "ADMIN",
            tipi: "Aruba",
          },
          {
            id: 2,
            name: "Endrit Abdiaj",
            roli: "ROLE_OPERATOR",
            tipi: "Test",
          },
          {
            id: 3,
            name: "Selma Test",
            roli: "ROLE_EDITOR",
            tipi: "Albani",
          },
        ]}
        density="comfortable"
        editMode="row"
        disableColumnMenu
        localeText={gridLabels}
        componentsProps={{
          pagination: {
            labelRowsPerPage: "Rreshta per Faqe",
            showLastButton: true,
            showFirstButton: true,
          },
        }}
        components={{
          Toolbar: CustomToolbar,
        }}
        onCellClick={currentlySelected}
        filterModel={filterModel}
        onFilterModelChange={(newFilterModel) => setFilterModel(newFilterModel)}
      />
      <ModalEditor
        open={open}
        onClose={handleClose}
        formname="Njësi Raportuese"
        data={njesi}
      ></ModalEditor>
    </div>
  );
};

export default CustomGrid;
