import React, { useEffect } from "react";
import classes from "./RegisterForm.module.css";
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  Stack,
  TextField,
  InputLabel,
  Typography,
  MenuItem,
  Grid,
} from "@mui/material";
import { Box } from "@mui/system";
import { useDispatch } from "react-redux";
import { clearMessage, setMessage } from "../../store/message";
import ERELogo from "../../image/ere_logo.jpeg";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { CATEGORY } from "../../data/dummy-data";
import { registerCompany } from "../../store/auth";
import { getDateFormat } from "../helper/CustomLabel";
import { APP_URL } from "../../services/http-common";
import axios from "axios";
import authHeader from "../../services/auth-header";
import { ConnectingAirportsOutlined } from "@mui/icons-material";

const RegisterForm = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearMessage());
  }, [dispatch]);

  var d = new Date();
  const currentDate = getDateFormat(d);

  const [usernameExist, setUsernameExist] = React.useState(false);
  const [dataValuesStructure, setDataValuesStructure] = React.useState([
    {
      label: "Përdoruesi",
      name: "username",
      value: "",
      required: true,
      type: "text",
      helperText: "Përdoruesi është i detyruar",
      errorValue: false,
    },
    {
      label: "Fjalëkalimi",
      name: "password",
      value: "",
      required: true,
      type: "password",
      helperText: "Fjalëkalimi është i detyruar",
      errorValue: false,
    },
    {
      label: "E-mail",
      name: "email",
      value: "",
      required: true,
      type: "email",
      helperText: "E-mail është i detyruar",
      errorValue: false,
    },
    {
      label: "Kategori",
      name: "category",
      value: "",
      required: true,
      type: "list",
      helperText: "Kategori është i detyruar",
      options: CATEGORY,
      errorValue: false,
    },
    {
      label: "Shteti",
      name: "country",
      value: "",
      required: true,
      type: "list",
      helperText: "Shteti është i detyruar",
      options: [
        {
          label: "Albania",
          value: "Albania",
        },
        { label: "Italy", value: "italy" },
      ],
      errorValue: false,
    },
    {
      label: "Emri ligjor kompanise",
      name: "name",
      value: "",
      required: true,
      type: "text",
      helperText: "Emri ligjor kompanise është i detyruar",
      errorValue: false,
    },
    {
      label: "Forma ligjore",
      name: "legalForm",
      value: "",
      required: true,
      type: "text",
      helperText: "Forma ligjore është e detyruar",
      errorValue: false,
    },
    {
      label: "Adresa kompanise",
      name: "address",
      value: "",
      required: true,
      type: "text",
      helperText: "Adresa kompanise është e detyruar",
      errorValue: false,
    },
    {
      label: "Qyteti kompanise",
      name: "city",
      value: "",
      required: true,
      type: "text",
      helperText: "Qyteti kompanise është i detyruar",
      errorValue: false,
    },
    {
      label: "Kodi eic",
      name: "eicCode",
      value: "",
      required: true,
      type: "text",
      helperText: "Kodi eic është i detyruar",
      errorValue: false,
    },
    {
      label: "Kodi bic",
      name: "bicCode",
      value: "",
      required: true,
      type: "text",
      helperText: "Kodi bic është i detyruar",
      errorValue: false,
    },
    {
      label: "Kodi lei",
      name: "leiCode",
      value: "",
      required: true,
      type: "text",
      helperText: "Kodi lei është i detyruar",
      errorValue: false,
    },
    {
      label: "NIPT",
      name: "vatNumber",
      value: "",
      required: true,
      type: "text",
      helperText: "Nipt është i detyruar",
      errorValue: false,
    },
    {
      label: "Website",
      name: "website",
      value: "",
      required: false,
      type: "text",
      helperText: "",
      errorValue: false,
    },
    {
      label: "Kodi Zip",
      name: "zipCode",
      value: "",
      required: false,
      type: "text",
      helperText: "",
      errorValue: false,
    },
    {
      label: "Publikim informacioni",
      name: "info1",
      value: "",
      required: false,
      type: "text",
      helperText: "",
      errorValue: false,
    },
    {
      label: "Wepage ku jane publikuar info",
      name: "info2",
      value: "",
      required: false,
      type: "text",
      helperText: "",
      errorValue: false,
    },
    {
      label: "Info",
      name: "info3",
      value: "",
      required: false,
      type: "text",
      helperText: "",
      errorValue: false,
    },
  ]);

  const [dataValues, setDataValues] = React.useState({
    username: "",
    password: "",
    email: "",
    category: "",
    country: "",
    name: "",
    legalForm: "",
    address: "",
    city: "",
    zipCode: "",
    eicCode: "",
    bicCode: "",
    leiCode: "",
    vatNumber: "",
    submissionDate: currentDate,
    website: "",
    info1: "",
    info2: "",
    info3: "",
  });
  const [state, setState] = React.useState({
    showPassword: false,
  });

  const changeUsername = (event) => {
    setUsernameExist(false);
    const { value } = event.target;
    setDataValues((state) => ({
      ...state,
      username: value ? value : "",
    }));

    const ourNextValuesList = [...dataValuesStructure];
    const currentValueList = ourNextValuesList.filter((item) => {
      if (item.name === "username") {
        item.value = value;
        if (item.value === "" && item.required === true) {
          item.errorValue = true;
        } else {
          item.errorValue = false;
        }
      }
      return item;
    });

    setDataValuesStructure(currentValueList);
  };

  const onBlurUsername = (event) => {
    const { value } = event.target;
    checkUsernameExists(value);
  };

  const handleRegisterChange = (e) => {
    const { name, value } = e.target;

    const ourNextValuesList = [...dataValuesStructure];
    const currentValueList = ourNextValuesList.filter((item) => {
      if (item.name === name) {
        item.value = value;
        if (item.value === "" && item.required === true) {
          item.errorValue = true;
        } else {
          item.errorValue = false;
        }
      }
      return item;
    });

    setDataValuesStructure(currentValueList);

    setDataValues((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleClickShowPassword = () => {
    setState({
      ...state,
      showPassword: !state.showPassword,
    });
  };

  const handlerRegister = (e) => {
    var isError = false;

    const ourNextValuesList = [...dataValuesStructure];
    const currentValueList = ourNextValuesList.filter((item) => {
      if (item.value === "" && item.required === true) {
        item.errorValue = true;
        isError = true;
      } else {
        if (item.name === "username" && usernameExist) {
          item.errorValue = true;
        } else {
          item.errorValue = false;
        }
      }
      return item;
    });

    setDataValuesStructure(currentValueList); // here update errorValue of

    const { username, email, password, ...companyData } = dataValues;

    const dataSendTemp = {
      company: {
        ...companyData,
      },
      user: {
        email: dataValues.email,
        password: dataValues.password,
        username: dataValues.username,
      },
    };

    if (isError) {
      console.log("Error Message");
    } else {
      e.preventDefault();
      dispatch(
        registerCompany({
          data: dataSendTemp,
        })
      )
        .unwrap()
        .then(() => {
          props.onBackLogin();
        })
        .catch(() => {});
    }
  };

  async function checkUsernameExists(username) {
    await axios.get(APP_URL + "users/username/" + username).then((response) => {
      setUsernameExist(response.data.exists);
    });
  }

  const handleCloseMessage = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(setMessage(""));
  };

  return (
    <section className={classes.auth}>
      <Box
        sx={{
          justifyContent: "center",
          display: "flex",
          marginTop: "20px",
        }}
      >
        <img
          style={{ textAlign: "center", height: "120px", width: "120x" }}
          src={ERELogo}
          alt="ERE Logo"
        />
      </Box>
      <Box sx={{ textAlign: "center", marginTop: "20px", color: "#00446c" }}>
        <Typography variant="h4" sx={{ fontSize: "26px" }}>
          Enti Rregullator i Energjisë <br />
          Forme Regjistrimi
        </Typography>
      </Box>
      <form style={{ marginTop: "20px" }}>
        <div className={classes.control}>
          <Stack spacing={1}>
            <Grid container spacing={1} columns={{ xs: 4, sm: 8, md: 8 }}>
              {dataValuesStructure.map((item) => {
                return item.name === "username" ? (
                  <Grid
                    item
                    xs={8}
                    sm={8}
                    md={4}
                    key={item?.label}
                    component="div"
                  >
                    <div key={item.label}>
                      <InputField
                        label={item.label}
                        required={item.required}
                        name={item.name}
                        type={item.type}
                        options={item.options}
                        errorValue={usernameExist || item.errorValue}
                        helperText={
                          usernameExist
                            ? "Ky perdorues ekziston"
                            : item.helperText
                        }
                        onChange={changeUsername}
                        onBlur={onBlurUsername}
                        autoComplete="off"
                      ></InputField>
                    </div>
                  </Grid>
                ) : (
                  <Grid
                    item
                    xs={8}
                    sm={8}
                    md={4}
                    key={item?.label}
                    component="div"
                  >
                    <div key={item.label}>
                      <InputField
                        label={item.label}
                        required={item.required}
                        name={item.name}
                        type={item.type}
                        options={item.options}
                        errorValue={item.errorValue}
                        helperText={item.helperText}
                        onChange={handleRegisterChange}
                      ></InputField>
                    </div>
                  </Grid>
                );
              })}
            </Grid>
          </Stack>
        </div>
        <Stack
          sx={{ justifyContent: "center", mt: "35px", mb: "10px" }}
          spacing={2}
          direction="row"
        >
          <Button
            variant="contained"
            // type="submit"
            sx={{
              height: "55px",
              fontFamily: "Basier Circle",
              fontSize: "18px",
              width: "100%",
            }}
            onClick={handlerRegister}
          >
            Regjistrohu
          </Button>
        </Stack>
        <Stack>
          <Button
            startIcon={<ArrowBackIcon />}
            sx={{
              marginTop: "40px",
              height: "50px",
              border: "1px solid #008fc6",
              fontFamily: "Basier Circle",
              fontSize: "18px",
              width: "170px",
              padding: "6px 10px",
              color: "#091B3D",
            }}
            onClick={() => props.onBackLogin()}
          >
            Identifikohu
          </Button>
        </Stack>
      </form>
    </section>
  );
};

const InputField = (props) => {
  return (
    <Stack spacing={1}>
      <InputLabel
        sx={{
          fontFamily: "Basier Circle",
          fontSize: "18px",
          color: "#091B3D",
          lineHeight: "22px",
        }}
        required={props?.required}
      >
        {props?.label}
      </InputLabel>
      <TextField
        select={props?.type === "list"}
        name={props?.name}
        onBlur={props?.onBlur}
        autoComplete="off"
        id={props?.name}
        variant="outlined"
        required={props?.required}
        error={props?.errorValue}
        value={props?.value}
        onChange={props?.onChange}
        type={props?.type}
        helperText={props?.errorValue ? props?.helperText : null}
        sx={{
          "& .MuiInputBase-root.MuiOutlinedInput-root": {
            height: "50px",
          },
          "& .MuiInputBase-input.MuiOutlinedInput-input": {
            padding: "12px 14px",
          },
        }}
      >
        {props?.type === "list" &&
          props?.options.map((option) => (
            <MenuItem key={option?.value} value={option?.value}>
              {option?.label}
            </MenuItem>
          ))}
      </TextField>
    </Stack>
  );
};

export default RegisterForm;
