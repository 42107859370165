import { createTheme } from "@mui/material/styles";
import { textAlign } from "@mui/system";
import RalewayWoff2 from "./fonts/basier/raleway-regular.woff2";
import BasierCircleWoff2 from "./fonts/basier/BasierCircle-Regular.woff2";
import BasierCircleMediumWoff2 from "./fonts/basier/BasierCircle-Medium.woff2";

const light = createTheme({
  palette: {
    primary: {
      main: "#008fc6",
      white: "#ffffff",
      success: "#4EB65A",
      dark: "#008fc6",
      error: "#ff4e4e",
    },
    success: { main: "#4EB65A" },
    secondary: {
      main: "#111",
    },
    background: {
      default: "#f6f8fc",
    },
  },
  zIndex: 1,
  shape: {
    borderRadius: 0,
  },
  typography: {
    fontFamily: ["Basier Circle", "Basier Circle Medium"].join(","),
    h3: {
      fontSize: "40px",
      color: "#091B3D",
      fontFamily: "Basier Circle",
      fontWeight: 600,
      lineHeight: "50px",
    },
    h4: {
      fontSize: "30px",
      color: "#091B3D",
      fontFamily: "Basier Circle Medium",
      fontWeight: 400,
      lineHeight: "37px",
    },
    h5: {
      fontSize: "20px",
      color: "#091B3D",
      fontFamily: "Basier Circle Medium",
    },
    body: {
      fontSize: "16px",
      fontWeight: 400,
      fontFamily: "Basier Circle",
    },
  },
  spacing: 8,
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          lineHeight: "16px",
          fontWeight: 400,
          fontFamily: "Basier Circle",
          fontSize: "16px",
          padding: "12px 18px",
          borderRadius: "10px",
          height: "65px",
          color: "#fff",
          boxShadow: "none",
        },
      },
      variants: [
        {
          props: { variant: "contained" },
          style: {
            "&.MuiButton-containedError": {
              background: "#ff4e4e",
              "&:hover": {
                backgroundColor: "#ff3824",
              },
              "&:active,&:hover,&:focus": {
                background: "#ff3824",
              },
            },
          },
        },
      ],
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          "&.MuiFormHelperText-root": {
            marginLeft: 0,
            marginRight: 0,
            paddingTop: "4px",
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: "#00446c",
          fontSize: "18px",
          fontWeight: "500",
          "&.MuiFormLabel-root.MuiInputLabel-root": {
            whiteSpace: "pre-wrap",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          background: "#fff",
          fontSize: "18px",
          height: "65px",
          border: "none",
          borderRadius: "15px",
          "textarea ": {
            height: "100% !important",
          },

          "&-webkit-autofill::first-line": {
            fontSize: "24px",
          },
          "input:-webkit-autofill,input:-webkit-autofill:hover,input:-webkit-autofill:focus,input:-webkit-autofill:active":
            {
              " -webkit-box-shadow": "0 0 0 30px white inset !important",
              fontSize: "18px",
            },
          "fieldset ": {
            borderColor: "transparent",
          },

          "&.Mui-disabled": {
            fieldset: {
              borderColor: "transparent !important",
            },
          },
          "&.MuiInputAdornment-root.MuiTypography-root": {
            color: "red !important",
          },

          "&.MuiOutlinedInput-root": {
            "&:hover fieldset ": {
              border: "1px solid #3B376C",
              "&:error": {
                border: "1px solid red",
              },
            },
            "&.Mui-focused fieldset": {
              border: "1px solid #3B376C",
            },
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          ":hover": {
            borderColor: "red !important",
          },
          "& .MuiInputBase-root.MuiOutlinedInput-root.MuiOutlinedInput-root:hover fieldset":
            {
              borderColor: "transparent !important",
            },
          "&.MuiAutocomplete-root .MuiOutlinedInput-root": {
            padding: "0px 0px 0px 10px !important",
            ":hover": {
              borderColor: "red !important",
            },
          },
        },
      },
    },

    MuiAppBar: {
      styleOverrides: {
        root: {
          zIndex: 4,
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        root: {
          zIndex: 2,
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          "& .MuiSvgIcon-root": {
            width: "25px",
            height: "25px",
          },

          "&.MuiButtonBase-root.MuiListItem-root": {
            margin: "15px 20px 15px 20px",
            height: "50px",
            borderRadius: 5,
            width: "auto",
            ":hover": {
              backgroundColor: "#00628870",
            },
          },

          "&.Mui-selected": {
            color: "white",
            backgroundColor: "#006288",
          },
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          backgroundColor: "#fff",
          borderRadius: "20px",
          border: "none",
          paddingTop: "20px",
          paddingLeft: "60px",
          paddingRight: "60px",
          paddingBottom: "10px",
          color: "#B5B5BB",
          maxHeight: "none",
          " &.MuiDataGrid-virtualScroller": {
            backgroundColor: "#fff",
          },
          "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
            outline: "none !important",
            cursor: "pointer !important",
          },
          "&.MuiDataGrid-root .MuiDataGrid-cell": {
            cursor: "pointer !important",
            borderBottom: "none",
            lineHeight: "unset !important",

            maxHeight: "none !important",
            padding: "0 0 0 30px",
            whiteSpace: "normal",
          },
          "&.MuiDataGrid-root .MuiDataGrid-row": {
            marginBottom: "10px",
            marginTop: "10px",
            borderRadius: "15px",
            backgroundColor: "#F6F8FC",
            color: "#091B3D",
            fontSize: "16px",
            fontWeight: "400",
            fontFamily: "Basier Circle",

            minHeight: "75px !important",
            alignItems: "center",
            maxHeight: "none !important",
          },

          "&.MuiDataGrid-renderingZone": {
            minHeight: "75px !important",
          },
          "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, .MuiDataGrid-root .MuiDataGrid-cell:focus":
            {
              outline: "none !important",
            },
          "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-root .MuiDataGrid-cell:focus-within":
            {
              outline: "none !important",
            },
          "&.MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-root .MuiDataGrid-cell":
            {
              padding: "0 0 0 30px",
            },

          "& .MuiDataGrid-columnHeaders": {
            color: "#808EB1",
            borderBottom: "none",
            fontSize: "16px",
            fontWeight: "400",
            fontFamily: "Basier Circle",
          },
          "& .MuiButtonBase-root.MuiButton-root": {
            color: "#fff",
            fontSize: "12px",
            fontWeight: "400",
            fontFamily: "Basier Circle",
            backgroundColor: "#7CB0FF",
            height: "40px",
            minWidth: "40px !important",
            width: "40px",
            marginRight: "14px",
            borderRadius: "8px",
          },
          "&.MuiDataGrid-root .MuiDataGrid-iconSeparator": {
            display: "none !important",
          },
          "& .MuiButton-startIcon": {
            marginRight: 0,
            marginLeft: 0,
          },
          "& .MuiSvgIcon-root": {
            height: "22px",
            width: "22px",
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
          },
          "& .MuiTablePagination-root": {
            color: "#B5B5BB",
          },
          "& .MuiDataGrid-overlay": {
            zIndex: 1,
          },
        },
      },
    },

    MuiModal: {
      styleOverrides: {
        root: {
          zIndex: 1,
          "&.MuiModal-root.MuiPopover-root.MuiMenu-root": {
            zIndex: 200,
          },
        },
      },
    },
    MuiPopper: {
      styleOverrides: {
        root: {
          zIndex: 2,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        root: {
          zIndex: 3,
        },
        tooltip: {
          borderRadius: "5px",
          backgroundColor: "#7CB0FF",
          fontFamily: "Basier Circle",
          fontSize: 16,
          fontWeight: 400,
          color: "#ffffff",
          zIndex: 2005,
        },
        arrow: {
          "&::before": {
            backgroundColor: "#7CB0FF",
            zIndex: 2005,
          },
        },
      },
    },

    MuiInputAdornment: {
      styleOverrides: {
        root: {
          "&.MuiInputAdornment-root": {
            minWidth: "180px",
            // borderRight: "1px solid #95979c",
            // height: "100%",
            "& .MuiTypography-root": {
              fontSize: "16px",
              minWidth: "100px",
              fontFamily: "Basier Circle",
              color: "#091B3D",
            },
          },
        },
      },
    },

    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Basier Circle';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('Basier Circle'), local('Basier Circle-Regular'), url(${BasierCircleWoff2}) format('woff2');
          
        }
        @font-face {
          font-family: 'Basier Circle Medium';
          font-style: normal;
          font-display: swap;
          font-weight: 500;
          src: local('Basier Circle Medium'), local('Basier Circle-Medium'), url(${BasierCircleMediumWoff2}) format('woff2');        
        }
      `,
    },
  },
});
export default light;
