const NJESI_RAPORTUESE_COLUMNS = [
  {
    field: "name",
    headerName: "Emër",
    width: 500,
    minWidth: 500,
    flex: 1,
    editable: false,
  },
  // {
  //   field: "description",
  //   headerName: "Përshkrimi",
  //   width: 200,
  //   flex: 1,
  //   // flexGrow: 1,
  //   editable: false,
  // },
];

export default NJESI_RAPORTUESE_COLUMNS;
