import { useSelector } from "react-redux";
import Grid from "../components/Grid/Grid";
import PerdoruesCustomGrid from "../components/Perdorues/PerdoruesCustomGrid";
import PerdoruesTable from "../components/Perdorues/PerdoruesTable";
import StartingPageContent from "../components/StartingPage/StartingPageContent";
const Perdorues = () => {
  const user = useSelector((state) => state.auth.user);
  return (
    <StartingPageContent pageName="Përdorues">
      {/* {user?.role === "ROLE_ANALYST" ? (
        <PerdoruesCustomGrid></PerdoruesCustomGrid>
      ) : (
        <PerdoruesTable></PerdoruesTable>
      )} */}
      <PerdoruesCustomGrid></PerdoruesCustomGrid>
    </StartingPageContent>
  );
};

export default Perdorues;
