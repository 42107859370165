import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";
import { setLoading } from "./loading";
import GraphDashboardDataService from "../services/graphDashboard.services";

const initialState = {
  graphDashboard: [],
  graphById: "",
};
export const getGraphDashboardData = createAsyncThunk(
  "graphDashboard/getDashboardData",
  async (thunkAPI) => {
    // thunkAPI.dispatch(setLoading(true));
    try {
      const data = await GraphDashboardDataService.getGraphData();
      // thunkAPI.dispatch(setLoading(false));
      const orderValue = data.sort((a, b) => {
        return a.order - b.order;
      });

      return {
        graphDashboard: orderValue,
      };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const createGraphDashboard = createAsyncThunk(
  "graphDashboard/createGraphDashboard",
  async ({ graph }, thunkAPI) => {
    try {
      const data = await GraphDashboardDataService.createGraph(graph);
      //  thunkAPI.dispatch(getAllUnit({ page: 0, searchValue: "" }));
      thunkAPI.dispatch(getGraphDashboardData());
      return thunkAPI.rejectWithValue();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateGraphDashboard = createAsyncThunk(
  "graphDashboard/updateGraphDashboard",
  async ({ graph, id }, thunkAPI) => {
    try {
      const data = await GraphDashboardDataService.updateGraph(graph, id);
      //  thunkAPI.dispatch(getAllUnit({ page: 0, searchValue: "" }));
      thunkAPI.dispatch(getGraphDashboardData());
      return thunkAPI.rejectWithValue();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const deleteGraphDashboard = createAsyncThunk(
  "graphDashboard/deleteGraphDashboard",
  async ({ id }, thunkAPI) => {
    try {
      const data = await GraphDashboardDataService.deleteGraph(id);
      thunkAPI.dispatch(setMessage({ message: "Rekordi u fshi me sukses!" }));
      // thunkAPI.dispatch(getAllFormTemplate({ page: 0, searchValue: "" }));
      // thunkAPI.dispatch(getAllUnit({ page: 0, searchValue: "" }));
      thunkAPI.dispatch(getGraphDashboardData());
      return thunkAPI.rejectWithValue();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getGraphByIdData = createAsyncThunk(
  "graphDashboard/getGraphByIdData",
  async ({ id }, thunkAPI) => {
    // thunkAPI.dispatch(setLoading(true));
    try {
      const data = await GraphDashboardDataService.getGraphById(id);
      // thunkAPI.dispatch(setLoading(false));
      return {
        graphById: data.data,
      };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

const graphDashboardDataSlice = createSlice({
  name: "graphDashboard",
  initialState,
  extraReducers: {
    [getGraphDashboardData.fulfilled]: (state, action) => {
      state.graphDashboard = action.payload.graphDashboard;
    },
    [getGraphDashboardData.rejected]: (state, action) => {
      state.graphDashboard = [];
    },

    [getGraphByIdData.fulfilled]: (state, action) => {
      state.graphById = action.payload.graphById;
    },
    [getGraphByIdData.rejected]: (state, action) => {
      state.graphById = "";
    },
  },
});

const { reducer } = graphDashboardDataSlice;
export default reducer;
