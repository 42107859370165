import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";
import { setLoading } from "./loading";
import FormInputesService from "../services/formInputes.service";
import FormTemplateService from "../services/formTemplate.service";

const initialState = { formInputesById: [] };
export const getFormInputesById = createAsyncThunk(
  "form/getFormInputesById",
  async ({ id }, thunkAPI) => {
    try {
      const data = await FormTemplateService.getFormInputesById(id);
      const datatemp = [];
      data.map((item) => datatemp.push({ ...item, answer: "" }));
      return { formInputesById: datatemp };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const createFormInputById = createAsyncThunk(
  "formInput/create",
  async ({ formInput }, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    try {
      const data = await FormInputesService.createFormInput(formInput);
      thunkAPI.dispatch(setLoading(false));
      thunkAPI.dispatch(getFormInputesById({ id: formInput.formId }));
      return thunkAPI.rejectWithValue();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setLoading(false));
      thunkAPI.dispatch(setMessage({ message: message }));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const updateFormInputById = createAsyncThunk(
  "formInput/update",
  async ({ formInput }, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    try {
      const data = await FormInputesService.updateFormInput(formInput);
      thunkAPI.dispatch(getFormInputesById({ id: formInput.formId }));
      thunkAPI.dispatch(setLoading(false));
      thunkAPI.dispatch(setMessage({ message: "U perditesua me sukses!" }));
      return thunkAPI.rejectWithValue();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setLoading(false));
      thunkAPI.dispatch(setMessage({ message: message }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const deleteFormInput = createAsyncThunk(
  "formInput/delete",
  async ({ formInput }, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    try {
      const data = await FormInputesService.deleteFormInput(formInput.id);
      thunkAPI.dispatch(setLoading(false));
      thunkAPI.dispatch(setMessage({ message: "Rekordi u fshi me sukses!" }));
      thunkAPI.dispatch(getFormInputesById({ id: formInput.formId }));
      return thunkAPI.rejectWithValue();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setLoading(false));
      thunkAPI.dispatch(setMessage({ message: message }));
      return thunkAPI.rejectWithValue();
    }
  }
);

const formInputesByIdSlice = createSlice({
  name: "formInputesById",
  initialState,
  extraReducers: {
    [getFormInputesById.fulfilled]: (state, action) => {
      state.formInputesById = action.payload.formInputesById;
    },
    [getFormInputesById.rejected]: (state, action) => {
      state.formInputesById = [];
    },
  },
});

const { reducer } = formInputesByIdSlice;
export default reducer;
