import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import Layout from "./components/Layout/Layout";
import UserProfile from "./components/Profile/UserProfile";
import AuthPage from "./pages/AuthPage";
import HomePage from "./pages/HomePage";
import light from "./theme/light";
import Entitet1 from "./pages/Entitet1";
import NjesiRaportuese from "./pages/NjesiRaportuese";
import Forma from "./pages/Forma";
import Perdorues from "./pages/Perdorues";
import Inpute from "./pages/Inpute";
import FormGrid from "./pages/FormGrid";
import FormGridInputet from "./pages/FormGridInputet";
import { useSelector, useDispatch } from "react-redux";
import Dashboard from "./pages/Dashboard";
import { setMessage } from "./store/message";
import Progressbar from "./components/UI/Progressbar";
import MessageAlert from "./components/UI/MessageAlert";
import FormTemplate from "./pages/FormTemplate";
import FormTemplateEditor from "./components/FormTemplate/FormTemplateEditor";
import FormatePlotesuara from "./pages/FormatePlotesuara";
import DrawerLeft from "./components/Navigation/DrawerLeft";
import { CssBaseline } from "@mui/material";
import Raports from "./pages/RaportPage";
import ExportPage from "./pages/ExportPage";
import ProfilePage from "./pages/ProfilePage";
import ProduktPage from "./pages/ProduktPage";
import FormCategory from "./pages/FormCategory";
import ProduktListPage from "./pages/ProduktListPage";

function App() {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const user = useSelector((state) => state.auth.user);
  const location = useLocation();

  const { message } = useSelector((state) => state.message);
  const { loading } = useSelector((state) => state.loading);
  const handleCloseMessage = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(setMessage(""));
  };

  return (
    <ThemeProvider theme={light}>
      <div
        style={{
          width: "100%",
          minHeight: "100vh",
          background: location.pathname === "/auth" ? "#008fc6" : "#F6F8FC",
          top: 0,
          bottom: 0,
        }}
      >
        <CssBaseline />
        <DrawerLeft>
          <Routes>
            <Route path="/dashboard" element={<Dashboard />} exact />
            <Route path="/home" element={<HomePage />} exact />
            <Route path="/profile" element={<ProfilePage />} />
            <Route path="/entitet1" element={<Entitet1 />} />
            <Route
              path={"/auth"}
              element={
                isLoggedIn && user?.role ? (
                  user?.role === "ROLE_EXECUTIVE" ? (
                    <Navigate to="/perdorues" />
                  ) : (
                    <Navigate to="/dashboard" />
                  )
                ) : (
                  <AuthPage />
                )
              }
            />
            <Route path="/forma" element={<Forma />} />
            <Route path="/formTemplate" element={<FormTemplate />} />
            <Route path="/formCategory" element={<FormCategory />} />
            <Route
              path="/formTemplateEditor"
              element={<FormTemplateEditor />}
            />
            <Route path="/formgrid" element={<FormGrid />} />
            <Route path="/formgrid/inputet" element={<FormGridInputet />} />
            <Route path="/njesiRaportuese" element={<NjesiRaportuese />} />
            <Route path="/perdorues" element={<Perdorues />} />
            <Route path="/inpute" element={<Inpute />} />
            <Route path="/formatePlotesuara" element={<FormatePlotesuara />} />
            <Route path="/export" element={<ExportPage />} />
            <Route path="/raporte" element={<Raports />} />
            <Route path="/produkt" element={<ProduktPage />} />
            <Route path="/produktList" element={<ProduktListPage />} />
          </Routes>
          <Progressbar open={loading} />
          <MessageAlert
            handleClose={handleCloseMessage}
            message={message}
            open={message !== "" || message !== undefined ? 1 : 0}
          />
        </DrawerLeft>
      </div>
    </ThemeProvider>
  );
}

export default App;
