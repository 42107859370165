import * as React from "react";
import {
  TextField,
  Button,
  Stack,
  InputAdornment,
  Typography,
  Box,
} from "@mui/material";
import { Save } from "@mui/icons-material";
import ModalUI from "../UI/ModalUI";
import { useDispatch, useSelector } from "react-redux";
import {
  createFormCategory,
  getAllFormCategory,
  updateFormCategory,
} from "../../store/formCategory";
import { useLocation } from "react-router-dom";
import { approvePrice, getPrice } from "../../store/product";
import { date } from "faker/lib/locales/az";

const ModalConfrim = (props) => {
  const dispatch = useDispatch();

  const { currentPage, searchValueCurrent } = useSelector(
    (state) => state.njesiRaportuese
  );
  const { data, isstatusnew, open } = props;
  const [state, setState] = React.useState({});
  const [errorValues, setErrorValues] = React.useState([]);
  React.useEffect(() => {
    setErrorValues([]);
    setState({
      id: data.id ? data.id : "",
      status: data.status ? data.status : "ACTIVE",
      name: data.product ? data.product.name : "",
      price: data.price ? data.price : "",
      company: data.company ? data.company?.name : "",
    });
  }, [data, open]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setState({ ...state, [name]: value ? value : "" });
  };

  const saveUser = () => {
    var initialError = [];
    Object.keys(state).map(function (key, index) {
      if (state[key] !== "" || Object.keys(state[key]).length !== 0) {
      } else {
        initialError.push(key);
      }
    });

    setErrorValues(initialError);

    var initialErrorTemp = [];
    initialErrorTemp = initialError.filter((item) => item !== "id"); // remove id item

    if (initialErrorTemp.length === 0) {
      if (isstatusnew) {
        const { id, ...newUnit } = state;
        dispatch(createFormCategory({ formCategoryItem: newUnit }));
      } else {
        const { ...updateUnitTemp } = state;
        dispatch(
          updateFormCategory({
            formCategoryItem: updateUnitTemp,
            currentPage: currentPage,
            searchValueCurrent: searchValueCurrent,
          })
        );
      }
      dispatch(
        getAllFormCategory({
          page: currentPage,
          searchValue: searchValueCurrent,
        })
      );
      props.onClose();
    }
  };

  const approve = (itemProductStatus) => {
    dispatch(
      approvePrice({
        product: { id: state.id, status: itemProductStatus },
      })
    );
    dispatch(getPrice({ page: 0, searchValue: "" }));
    props.onClose();
  };

  const checkError = (item) => {
    if (errorValues.find((element) => element === item)) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <ModalUI {...props}>
      <Stack sx={{ display: "flex", width: "100%" }}>
        <Box
          sx={{
            borderRadius: "16px",
            border: "1px solid #dadada",
            padding: "16px 4px",
          }}
        >
          <Typography variant="h5" sx={{ textAlign: "center" }}>
            {state.name} - {state.price}
          </Typography>
          <Typography component="div" variant="h5" sx={{ textAlign: "center" }}>
            {state?.company}
          </Typography>
        </Box>
        <Button
          variant="contained"
          sx={{
            backgroundColor: "primary.error",
            display: "flex",
            marginTop: "40px",
          }}
          color="error"
          onClick={() => approve("REJECTED")}
        >
          Refuzo
        </Button>
        <Button
          variant="contained"
          sx={{
            backgroundColor: "primary.dark",
            display: "flex",
            marginTop: "40px",
          }}
          onClick={() => approve("APPROVED")}
        >
          Konfirmo
        </Button>
      </Stack>
    </ModalUI>
  );
};
export default ModalConfrim;
