import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";
import { setLoading } from "./loading";
import UploadService from "../services/uploadFile.service";
import { setFormSubmissionChangeAnswer } from "./formInputesSubmission";

const initialState = {
  uplaodFileId: "",
  uplaodFileIdTemp: "",
  loadFile: "",
};

export const uploadFile = createAsyncThunk(
  "uploadFile/uploadFile",
  async ({ fileData, temp = false }, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    try {
      const data = await UploadService.uploadFile(fileData);
      thunkAPI.dispatch(setLoading(false));
      if (temp) {
        return {
          uplaodFileIdTemp: data?.id,
        };
      } else {
        return {
          uplaodFileId: data?.id,
        };
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setLoading(false));
      thunkAPI.dispatch(setMessage({ message: message }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const uploadFileTemp = createAsyncThunk(
  "uploadFile/uploadFileTemp",
  async ({ fileData, stateFormInputesSubmission, itemId }, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    try {
      const data = await UploadService.uploadFile(fileData);
      thunkAPI.dispatch(setLoading(false));
      thunkAPI.dispatch(
        setFormSubmissionChangeAnswer({
          state: stateFormInputesSubmission,
          id: itemId,
          answerValue: data?.id,
        })
      );
      // return {
      //   uplaodFileIdTemp: data?.id,
      // };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setLoading(false));
      thunkAPI.dispatch(setMessage({ message: message }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const loadFileById = createAsyncThunk(
  "uploadFile/loadFile",
  async ({ id }, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    try {
      const data = await UploadService.loadFile(id);
      thunkAPI.dispatch(setLoading(false));
      return {
        loadFile: data,
      };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setLoading(false));
      thunkAPI.dispatch(setMessage({ message: message }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const clearUpdateFileId = createAsyncThunk(
  "uploadFile/clearUploadFileId",
  async (thunkAPI) => {
    return { uplaodFileId: "" };
  }
);

const uplaodFileSlice = createSlice({
  name: "uplaodFile",
  initialState,
  extraReducers: {
    [uploadFile.fulfilled]: (state, action) => {
      state.uplaodFileId = action.payload.uplaodFileId;
    },
    [uploadFile.rejected]: (state, action) => {
      state.uplaodFileId = "";
    },
    // [uploadFileTemp.fulfilled]: (state, action) => {
    //   state.uplaodFileIdTemp = action.payload.uplaodFileIdTemp;
    // },
    // [uploadFileTemp.rejected]: (state, action) => {
    //   state.uplaodFileIdTemp = "";
    // },
    [loadFileById.fulfilled]: (state, action) => {
      state.loadFile = action.payload.loadFile;
    },
    [loadFileById.rejected]: (state, action) => {
      state.loadFile = "";
    },

    [clearUpdateFileId.fulfilled]: (state, action) => {
      state.uplaodFileId = "";
    },
  },
});

const { reducer } = uplaodFileSlice;
export default reducer;
