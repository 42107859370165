import React, { useEffect, useState } from "react";
import classes from "./AuthForm.module.css";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Stack,
  TextField,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  ButtonBase,
} from "@mui/material";
import { Box } from "@mui/system";
import { useDispatch } from "react-redux";
import { login } from "../../store/auth";
import { me } from "../../store/auth";
import { clearMessage, setMessage } from "../../store/message";
import ERELogo from "../../image/ere_logo.jpeg";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import RegisterForm from "../Register/RegisterForm";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";

const AuthForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearMessage());
  }, [dispatch]);

  const [dataValues, setDataValues] = React.useState({
    perdorues: "",
    fjalekalim: "",
  });
  const [state, setState] = React.useState({
    showPassword: false,
  });
  const [errorValues, setErrorValues] = React.useState({
    perdorues: null,
    fjalekalim: null,
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setDataValues((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
    if (dataValues[name].trim().length !== 0) {
      setErrorValues((prevState) => {
        return {
          ...prevState,
          [name]: false,
        };
      });
    }
  };

  const handleClickShowPassword = () => {
    setState({
      ...state,
      showPassword: !state.showPassword,
    });
  };

  const handlerLogin = (e) => {
    var isError = false;
    Object.entries(dataValues).map(([key, value]) => {
      if (value.trim().length === 0) {
        isError = true;
        setErrorValues((prevState) => {
          return {
            ...prevState,
            [key]: true,
          };
        });
      }
    });

    if (isError) {
      console.log("Error Message");
    } else {
      e.preventDefault();
      dispatch(
        login({
          username: dataValues.perdorues,
          password: dataValues.fjalekalim,
        })
      )
        .unwrap()
        .then(() => {
          dispatch(me());
        })
        .catch(() => {});
    }
  };

  const handleCloseMessage = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    dispatch(setMessage(""));
  };

  const [loginState, setLoginState] = useState(true);

  return loginState ? (
    <section className={classes.auth}>
      <Box
        sx={{ justifyContent: "center", display: "flex", marginTop: "20px" }}
      >
        <img
          style={{ textAlign: "center", height: "120px", width: "120x" }}
          src={ERELogo}
          alt="ERE Logo"
        />
      </Box>
      <Box sx={{ textAlign: "center", marginTop: "20px", color: "#00446c" }}>
        <Typography variant="h4" sx={{ fontSize: "26px" }}>
          Enti Rregullator i Energjisë
        </Typography>
        {/* <Typography variant="h4">Data Store</Typography> */}
      </Box>
      <form style={{ marginTop: "20px" }}>
        <div className={classes.control}>
          <Stack spacing={1}>
            <InputLabel
              htmlFor="perdorues"
              sx={{
                fontFamily: "Basier Circle",
                fontSize: "18px",
                color: "#091B3D",
                lineHeight: "22px",
              }}
              required
            >
              Përdoruesi
            </InputLabel>
            <TextField
              name="perdorues"
              autoComplete="off"
              id="perdorues"
              variant="outlined"
              required
              error={errorValues.perdorues}
              value={dataValues.perdorues}
              onChange={handleChange}
              helperText={
                errorValues.perdorues ? "Përdoruesi është i detyruar" : null
              }
              sx={{
                "& .MuiInputBase-root.MuiOutlinedInput-root": {
                  height: "50px",
                },
                "& .MuiInputBase-input.MuiOutlinedInput-input": {
                  padding: "12px 14px",
                },
              }}
            />
          </Stack>
        </div>
        <div className={classes.control}>
          <Stack spacing={1}>
            <InputLabel
              htmlFor="fjalekalim"
              sx={{
                fontFamily: "Basier Circle",
                fontSize: "18px",
                color: "#091B3D",
                lineHeight: "22px",
              }}
              required
            >
              Fjalëkalimi
            </InputLabel>
            <TextField
              name="fjalekalim"
              autoComplete="off"
              id="fjalekalim"
              variant="outlined"
              required
              type={state.showPassword ? "text" : "password"}
              error={errorValues.fjalekalim}
              value={dataValues.fjalekalim}
              onChange={handleChange}
              helperText={
                errorValues.fjalekalim ? "Fjalëkalimi është i detyruar" : null
              }
              sx={{
                "& .MuiInputBase-root.MuiOutlinedInput-root": {
                  height: "50px",
                },
                "& .MuiInputBase-input.MuiOutlinedInput-input": {
                  padding: "12px 14px",
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    sx={{
                      "&.MuiInputAdornment-root.MuiInputAdornment-root": {
                        minWidth: "60px",
                      },
                    }}
                    position="end"
                  >
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      // onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {state.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
        </div>
        <Stack
          sx={{ justifyContent: "center", mt: "15px", mb: "10px" }}
          spacing={2}
          direction="row"
        >
          <Button
            variant="contained"
            type="submit"
            sx={{
              height: "55px",
              fontFamily: "Basier Circle",
              fontSize: "18px",
              width: "100%",
            }}
            onClick={handlerLogin}
          >
            Identifikohu
          </Button>
        </Stack>
        <Stack>
          <Button
            startIcon={<AppRegistrationIcon />}
            sx={{
              height: "50px",
              // backgroundColor: "#d3ebf4",
              borderRadius: "10px",
              border: "1px solid #008fc6",
              fontFamily: "Basier Circle",
              fontSize: "18px",
              width: "100%",
              marginTop: "20px",
              color: "#091B3D",
            }}
            onClick={() => setLoginState(false)}
          >
            Regjistrohu
          </Button>
        </Stack>
      </form>
    </section>
  ) : (
    <div>
      {" "}
      <RegisterForm onBackLogin={() => setLoginState(true)}></RegisterForm>
      {/* <Stack>
        <ButtonBase onClick={() => setLoginState(true)}>
          Identifikohu
        </ButtonBase>
      </Stack> */}
    </div>
  );
};

export default AuthForm;
