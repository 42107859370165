import * as React from "react";
import { Modal, Box, Typography, Stack, IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";

const ModalUI = (props) => {
  return (
    <Modal
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ zIndex: props?.multifield ? 0 : 4 }}
      {...props}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: props?.width ? props?.width : "580px",
          bgcolor: props.alert ? "#F9F0ED" : "#EBF0FA",
          border: "2px solid #000",
          boxShadow: "none",
          border: "transparent",
          borderRadius: "15px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            marginRight: "30px",
            marginTop: "30px",
            justifyContent: "flex-end",
            align: "right",
          }}
        >
          <IconButton
            onClick={props.onClose}
            sx={{ color: props.alert ? "#CD3728" : "#008fc6" }}
          >
            <Close></Close>
          </IconButton>
        </Box>
        <Typography
          id="modal-modal-title"
          sx={{
            pb: 4,
            textAlign: "center",
            color: props.alert ? "#CD3728" : "#091B3D",
          }}
          variant="h4"
          component="h4"
        >
          {props.formname}
        </Typography>
        <Stack
          sx={{
            paddingTop: "20px",
            paddingLeft: "50px",
            paddingRight: "50px",
            // paddingBottom: "80px",
            overflowY: "scroll",
            maxHeight: "50vh",
          }}
          spacing={"20px"}
        >
          {props.children}
        </Stack>
        <Stack
          sx={{
            paddingTop: "30px",
            paddingLeft: "50px",
            paddingRight: "50px",
            paddingBottom: "50px",
          }}
        >
          {props.buttons}
        </Stack>
      </Box>
    </Modal>
  );
};

export default ModalUI;
