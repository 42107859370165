import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Button, IconButton, Switch } from "@mui/material";
import { Add, Delete, Edit } from "@mui/icons-material";
import gridLabels from "../Labels/GridLabels";
import FORM_TEMPLATE_COLUMNS from "./FormTemplateTableColumn";
import FormTemplateModalEditor from "./FormTemplateModalEditor";
import { useDispatch, useSelector } from "react-redux";
import { clearMessage, setMessage } from "../../store/message";
import { setLoading } from "../../store/loading";
import {
  deleteFormTemplate,
  getAllFormTemplate,
  getByReportingUnit,
  updateFormTemplate,
} from "../../store/formTemplate";
import FormTemplateEditor from "./FormTemplateEditor";
import { Link, useNavigate } from "react-router-dom";
import DialogDelete from "../UI/DialogDelete";
import ButtonAdd from "../UI/ButtonAdd";
import BoxRowButtonsIcon from "../UI/BoxRowButtonsIcon";
import RowButtonIcon from "../UI/RowButtonIcon";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import EditIcon from "@mui/icons-material/Edit";
import ButtonExport from "../UI/ButtonExport";
import SearchInput from "../UI/SearchInput";
import DownloadCsvFile from "../helper/DownloadCsvFile";
import { setBackState } from "../../store/backState";

const FormTemplateTable = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    formTemplate,
    totalElements,
    totalPages,
    size,
    currentPage,
    searchValueCurrent,
  } = useSelector((state) => state.formTemplate);
  const { backState } = useSelector((state) => state.backState);

  const [open, setOpen] = React.useState(false);
  const [openEditor, setOpenEditor] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [isStatusNew, setIsStatusNew] = React.useState(false);
  const [stateDialogDelete, setStateDialogDelete] = React.useState(false);
  const [selectRowId, setSelectRowId] = React.useState("");
  const [selectRowItem, setSelectRowItem] = React.useState({});
  const [forma, setForma] = React.useState({});
  const [updateForm, setUpdateForm] = React.useState({});
  const [dataColumns, setDataColumns] = React.useState(FORM_TEMPLATE_COLUMNS);
  const [page, setPage] = React.useState(
    backState.formTemplate ? currentPage : 0
  );
  const searchState = React.useRef("");
  const [searchValueServer, setSearchValueServer] = React.useState(
    backState.formTemplate ? searchValueCurrent : ""
  );

  const initialForm = {
    id: "",
    form: "",
    description: "",
  };

  const handleChangePage = (event, newPage) => {
    setPage(event);
  };

  const searchItems = (e) => {
    setBackState({ formSubmission: false, formTemplate: false });
    const searchValue = e.target.value;
    if (searchValue !== "") {
      searchState.current.value = searchValue;
      let filterTimeout;
      filterTimeout = setTimeout(() => {
        setSearchValueServer(searchState.current.value);
      }, 1000);
    } else {
      setSearchValueServer("");
      dispatch(getAllFormTemplate({ page: page, searchValue: "" }));
    }
  };

  React.useEffect(() => {
    getAllData();
    // getByReportingUnitData();
  }, [dispatch, page, searchValueServer]);

  const getAllData = () => {
    dispatch(setLoading(true));
    dispatch(
      getAllFormTemplate({
        page: page,
        searchValue: searchValueServer,
        limit: 10,
      })
    )
      .unwrap()
      .then(() => {
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };

  const getByReportingUnitData = () => {
    dispatch(setLoading(true));
    dispatch(
      getByReportingUnit({
        page: page,
        searchValue: searchValueServer,
        unit: "90e2824c-74b4-4898-8e9d-568a025ce9b4",
      })
    )
      .unwrap()
      .then(() => {
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };

  const [filterModel, setFilterModel] = React.useState({
    items: [
      {
        columnField: "",
        operatorValue: ">",
        value: "",
      },
    ],
  });

  const currentlySelected = (e) => {
    setIsStatusNew(false);
    //setOpenEditor(true);
    const dataRow = {
      // category: e.row.companyCategory,
      description: e.row.description,
      reportingUnit: e.row.reportingUnit.id,
      formCategory: e.row.formCategory.id,
      id: e.row.id,
      name: e.row.name,
      schedule: e.row.schedule,
      status: e.row.status,
      variableSubmission: e.row.variableSubmission,
    };
    navigate("/formTemplateEditor", { state: { formTemplate: dataRow } });
    dispatch(setBackState({ formSubmission: false, formTemplate: true }));
    //setForma(e.row);
    // setOpen(true);
  };

  const deleteSelectRow = (e) => {
    setForma(e.row);
  };
  const addNew = () => {
    setIsStatusNew(true);
    setForma(initialForm);
    setOpen(true);
  };
  const handleClickDelete = (e) => {
    setSelectRowId(e.row.id);
    setSelectRowItem(e.row);
    let copyOfObject = { ...e.row };
    delete copyOfObject["reportingUnit"];
    let tempUpdateForm = {
      ...copyOfObject,
      reportingUnit: e.row?.reportingUnit?.id,
      formCategory: e.row?.formCategory?.id,
      status: e.row?.status === "ACTIVE" ? "INACTIVE" : "ACTIVE",
    };
    setUpdateForm(tempUpdateForm);
    setStateDialogDelete(true);
  };

  const handleCloseDialogDelete = (e) => {
    setStateDialogDelete(false);
  };
  const handleDeleteFormTemplate = (e) => {
    dispatch(
      updateFormTemplate({
        formTemplate: updateForm,
        currentPage: currentPage,
        searchValueCurrent: searchValueCurrent,
      })
    ); // update form switch

    // dispatch(deleteFormTemplate({ id: selectRowId })); // delete form switch old
    setStateDialogDelete(false);
  };

  function CustomToolbar() {
    return (
      <Box
        sx={{
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            py: "18px",
            px: "9px",
            alignItems: "center",
            bgcolor: "transparent",
          }}
        >
          <Box
            sx={{
              flexGrow: 1,
              textAlign: "center",
              direction: "column",
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <SearchInput
              onChange={searchItems}
              inputRef={searchState}
              defaultValue={
                backState.formTemplate
                  ? searchValueCurrent
                  : searchState.current.value
              }
            ></SearchInput>
          </Box>

          <ButtonAdd onClick={addNew} />
        </Box>
      </Box>
    );
  }
  return (
    <div style={{ height: 700, width: "100%" }}>
      {openEditor ? (
        <FormTemplateEditor />
      ) : (
        <DataGrid
          labelRowsPerPage="Artikuj per faqe"
          disableSelectionOnClick
          columns={[
            ...dataColumns,
            {
              headerName: "",
              overflow: "hidden",
              field: "status",
              editable: false,
              flex: 1,
              justifyContent: "flex-end",
              disableClickEventBubbling: true,
              align: "right",
              background: "red",
              headers: false,
              renderHeader: () => {},
              renderCell: (params) => (
                <BoxRowButtonsIcon>
                  {/* <RowButtonIcon
                    onClick={(event) => {
                      currentlySelected(params);
                    }}
                  >
                    <RemoveRedEyeOutlinedIcon />
                  </RowButtonIcon> */}
                  <RowButtonIcon
                    onClick={(event) => {
                      currentlySelected(params);
                    }}
                  >
                    <EditIcon />
                  </RowButtonIcon>

                  <RowButtonIcon>
                    <Switch
                      checked={params?.value === "ACTIVE"}
                      onClick={(event) => {
                        handleClickDelete(params);
                      }}
                    ></Switch>
                  </RowButtonIcon>
                  {/* <RowButtonIcon
                    delete
                    onClick={(event) => {
                      handleClickDelete(params);
                    }}
                  >
                    <Delete />
                  </RowButtonIcon> */}
                </BoxRowButtonsIcon>
              ),
            },
          ]}
          rows={formTemplate ? formTemplate : []}
          density="comfortable"
          editMode="row"
          disableColumnMenu
          localeText={gridLabels}
          componentsProps={{
            pagination: {
              labelRowsPerPage: "Rreshta per Faqe",
              showLastButton: true,
              showFirstButton: true,
            },
          }}
          components={{
            Toolbar: CustomToolbar,
          }}
          onRowDoubleClick={currentlySelected}
          filterModel={filterModel}
          onFilterModelChange={(newFilterModel) =>
            setFilterModel(newFilterModel)
          }
          page={page}
          onPageChange={handleChangePage}
          count={totalPages}
          rowCount={totalElements}
          pageSize={size}
          pagination
          paginationMode="server"
        />
      )}
      <DialogDelete
        open={stateDialogDelete}
        disagreedelete={handleCloseDialogDelete}
        agreedelete={handleDeleteFormTemplate}
        valueswitch={
          selectRowItem?.status === "ACTIVE"
            ? "INAKTIVE"
            : selectRowItem?.status === "INACTIVE"
            ? "AKTIVE"
            : ""
        }
      />
      <FormTemplateModalEditor
        open={open}
        onClose={handleClose}
        formname="Modeli Formës"
        isstatusnew={isStatusNew ? 1 : 0}
        data={forma}
      ></FormTemplateModalEditor>
    </div>
  );
};

export default FormTemplateTable;
