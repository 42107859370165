import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Box } from "@mui/system";
import { Divider } from "@mui/material";

const DialogDelete = (props) => {
  return (
    <div>
      <Dialog
        {...props}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ borderRadius: "15px" }}
        PaperProps={{
          style: {
            borderRadius: "15px",
            padding: "40px",
            width: "580px",
            background: "#F9F0ED",
            height: "500px",
          },
        }}
      >
        <Box sx={{ height: "250px", bgcolor: "#F9F0ED", textAlign: "center" }}>
          <DialogTitle
            id="alert-dialog-title"
            sx={{
              fontSize: "30px",
              color: "#CD3728",
              fontFamily: "Basier Circle Medium",
            }}
          >
            {props.title ? props.title : "Kujdes!"}
          </DialogTitle>
          <DialogContent
            sx={{
              padding: 4,
              fontSize: "25px",
              color: "#091B3D",
              fontFamily: "Basier Circle Medium",
            }}
          >
            <DialogContentText
              sx={{
                padding: 4,
                fontSize: "25px",
                color: "#091B3D",
                fontFamily: "Basier Circle",
                padding: "55px",
                lineHeight: "30px",
              }}
              id="alert-dialog-description"
            >
              {props.description
                ? props.description
                : ` Jeni të sigurt që doni ta bëni ${
                    props?.valueswitch ? props?.valueswitch : "INAKTIVE"
                  } këtë rekord?`}
            </DialogContentText>
          </DialogContent>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexGrow: 1,
            alignItems: "center",
            columnGap: 1.5,
            bgcolor: "#F9F0ED",
            justifyContent: "center",
            height: "56px !important",
            px: "24px",
          }}
        >
          {!props.warning && (
            <Button
              variant="text"
              sx={{
                color: "#CD3728",
                width: "160px",
                height: "75px",
                fontSize: "20px",
                backgroundColor: "#FFF",
              }}
              onClick={props.disagreedelete}
            >
              Jo
            </Button>
          )}
          <Button
            variant="contained"
            sx={{
              // backgroundColor: "#EF5828",
              width: "160px",
              height: "75px",
              fontSize: "20px",
            }}
            color="error"
            onClick={props.agreedelete}
            autoFocus
          >
            {props.warning ? "Kthehu!" : "Po"}
          </Button>
        </Box>
      </Dialog>
    </div>
  );
};

export default DialogDelete;
