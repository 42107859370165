import * as React from "react";
import {
  TextField,
  InputLabel,
  Button,
  Stack,
  InputAdornment,
} from "@mui/material";
import { Save } from "@mui/icons-material";
import ModalUI from "../UI/ModalUI";
import { useDispatch, useSelector } from "react-redux";
import {
  createFormInputById,
  updateFormInputById,
} from "../../store/formInputes";
import SelectAutocomplate from "../UI/SelectAutocomplate";
import { INDICATOR_TYPE } from "../../data/dummy-data";

const FormInputEditor = (props) => {
  const dispatch = useDispatch();

  const { data, isstatusnew, formid, open } = props;
  const [state, setState] = React.useState({
    id: "",
    formId: formid ? formid : "",
    label: "",
    order: 0,
    defaultAnswer: 0,
    unit: "cope",
    type: "NUMBER",
  });
  const [errorValues, setErrorValues] = React.useState([]);
  const searchTypeState = React.useRef("");
  const [anchorElType, setAnchorElType] = React.useState(null);

  const handleClickType = (event) => {
    setAnchorElType(event.currentTarget);
  };

  const handleCloseType = () => {
    setAnchorElType(null);
  };

  const openType = Boolean(anchorElType);
  const searchItems = (e) => {};
  React.useEffect(() => {
    setState({
      key: data.key ? data.key : "",
      id: data.id ? data.id : "",
      formId: formid ? formid : "",
      label: data.label ? data.label : "",
      order: data.order ? data.order : 0,
      defaultAnswer: data.defaultAnswer ? data.defaultAnswer : 0,
      unit: data.unit ? data.unit : "",
      type: data.type ? data?.type : "",
    });
    setErrorValues([]);
  }, [data, open]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    const onlyNums = event.target.value.replace(/[^0-9]/g, "");
    if (name === "order") {
      if (onlyNums) {
        setState({ ...state, [name]: value ? value : "" });
      }
    } else {
      setState({ ...state, [name]: value ? value : "" });
    }
  };

  const saveFormTemplate = () => {
    var initialError = [];
    Object.keys(state).map(function (key, index) {
      if (state[key] !== "" || Object.keys(state[key]).length !== 0) {
      } else {
        initialError.push(key);
      }
    });

    const initialErrorTemp = initialError.filter(
      (item) => item !== "key" && item !== "id"
    ); // remove key item

    setErrorValues(initialErrorTemp);

    if (initialErrorTemp.length === 0) {
      if (isstatusnew) {
        const { id, ...newFormInput } = state;
        dispatch(createFormInputById({ formInput: newFormInput }));
      } else {
        const { ...updateFormInputTemp } = state;
        dispatch(updateFormInputById({ formInput: updateFormInputTemp }));
      }
      props.onClose();
    }
  };

  const checkError = (item) => {
    if (errorValues.find((element) => element === item)) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <ModalUI
      buttons={
        <Stack sx={{ display: "flex", width: "100%" }}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "primary.dark",
              display: "flex",
              marginTop: "40px",
            }}
            startIcon={<Save />}
            onClick={saveFormTemplate}
          >
            {isstatusnew ? "Shto" : "Ndrysho"}
          </Button>
        </Stack>
      }
      {...props}
    >
      <Stack spacing={2}>
        <TextField
          name="label"
          autoComplete="off"
          id="label"
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">Indikator</InputAdornment>
            ),
          }}
          value={state.label}
          error={checkError("label")}
          helperText={checkError("label") ? "Indikator është i detyruar" : null}
          onChange={handleInputChange}
        />
      </Stack>
      <Stack spacing={2}>
        <TextField
          name="order"
          autoComplete="off"
          id="order"
          variant="outlined"
          type="number"
          InputProps={{
            inputProps: { min: 0 },
            startAdornment: (
              <InputAdornment position="start">Rendi</InputAdornment>
            ),
          }}
          value={state.order}
          error={checkError("order")}
          helperText={checkError("order") ? "Rendi është i detyruar" : null}
          onChange={handleInputChange}
        />
      </Stack>
      <Stack spacing={2}>
        <TextField
          name="defaultAnswer"
          autoComplete="off"
          id="defaultAnswer"
          variant="outlined"
          type="number"
          InputProps={{
            inputProps: { min: 0 },
            startAdornment: (
              <InputAdornment position="start">
                Përgjigje Default
              </InputAdornment>
            ),
          }}
          value={state.defaultAnswer}
          error={checkError("defaultAnswer")}
          helperText={
            checkError("defaultAnswer")
              ? " Përgjigje Default është e detyruar"
              : null
          }
          onChange={handleInputChange}
        />
      </Stack>
      <Stack spacing={2}>
        <TextField
          name="unit"
          autoComplete="off"
          id="unit"
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">Njësia matëse</InputAdornment>
            ),
          }}
          value={state.unit}
          error={checkError("unit")}
          helperText={
            checkError("unit") ? " Njësia matëse është e detyruar" : null
          }
          onChange={handleInputChange}
        />
      </Stack>
      <Stack spacing={2}>
        <SelectAutocomplate
          name="type"
          id="type"
          label="Tipi"
          placeholder="Kërko Tip"
          noOptionsText="Nuk ka Tip"
          value={state?.type}
          onClick={handleClickType}
          source={INDICATOR_TYPE}
          open={openType}
          anchorEl={anchorElType}
          onClickAway={handleCloseType}
          error={checkError("type")}
          helperText={checkError("type") ? "Tipi është i detyruar" : null}
          onClose={(event, reason) => {
            if (reason === "escape") {
              handleCloseType();
            }
          }}
          onChange={(event, newValue, reason) => {
            if (
              event.type === "keydown" &&
              event.key === "Backspace" &&
              reason === "removeOption"
            ) {
              return;
            }
            setState({
              ...state,
              type: newValue.id ? newValue.id : "",
            });
            handleCloseType();
          }}
          onchangesearch={searchItems}
          inputRef={searchTypeState}
        ></SelectAutocomplate>
      </Stack>
    </ModalUI>
  );
};
export default FormInputEditor;
