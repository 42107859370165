import axios from "axios";
import authHeader from "./auth-header";
import { APP_URL } from "./http-common";

const URLEntityName = APP_URL + "forms";

const getAllFormTemplate = (page, searchValue, limit) => {
  var pageValue = page;
  var valueUnit = "";
  if (searchValue !== "") {
    valueUnit = "&search=name=" + searchValue;
  }
  var valueLimit = "";
  if (limit !== "" && limit !== undefined) {
    // pageValue = 0;
    valueLimit = "&size=" + limit;
  }

  return (
    axios
      .get(URLEntityName + "?page=" + pageValue + valueUnit + valueLimit, {
        headers: authHeader(),
      })
      // .get(URLEntityName +"?&search=reportingUnit=6d989a4f-a5f4-4dd7-ae5f-ddba8071a801",{ headers: authHeader() })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      })
  );
};

const getByReportingUnit = (unitId, searchValue) => {
  var valueUnit = "";
  if (searchValue != "") {
    valueUnit = "?&search=name=" + searchValue;
  }
  return axios
    .get(URLEntityName + "/reporting-unit/" + unitId + valueUnit, {
      headers: authHeader(),
    })
    .then((response) => {
      return response.data;
    });
};

async function deleteFormTemplate(id) {
  await axios
    .delete(URLEntityName + "/" + id, { headers: authHeader() })
    .then((response) => {
      if (
        response.status === 200 ||
        response.status === 201 ||
        response.status === 204
      ) {
        return response;
      }
      //return response;
    })
    .catch((err) => {
      return err;
    });
}
async function createFormTemplate(formTemplate) {
  await axios
    .post(URLEntityName, formTemplate, {
      headers: authHeader(),
    })
    .then((response) => {
      return response;
    });
}

async function updateFormTemplate(formTemplate) {
  await axios
    .put(URLEntityName + "/" + formTemplate.id, formTemplate, {
      headers: authHeader(),
    })
    .then((response) => {
      return response;
    });
}

const getFormInputesById = (id) => {
  return axios
    .get(URLEntityName + "/" + id + "/form-inputs", { headers: authHeader() })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};

const getAllFormTemplateSearch = (searchValue) => {
  if (searchValue != "" && searchValue.length > 2 && searchValue != undefined) {
    return axios
      .get(URLEntityName + "/name/" + searchValue, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  }
};

const formTemplateService = {
  getAllFormTemplate,
  createFormTemplate,
  deleteFormTemplate,
  updateFormTemplate,
  getFormInputesById,
  getByReportingUnit,
  getAllFormTemplateSearch,
};
export default formTemplateService;
