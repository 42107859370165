class Forma {
  constructor(id, emer, frekuence, status) {
    this.id = id;
    this.emer = emer;
    this.frekuence = frekuence;
    this.status = status;
  }
}

export default Forma;
