import Grid from "../components/Grid/Grid";
import FormTemplateTable from "../components/FormTemplate/FormTemplateTable";
import StartingPageContent from "../components/StartingPage/StartingPageContent";
const FormTemplate = () => {
  return (
    <StartingPageContent pageName="Modelet e Formave">
      <FormTemplateTable></FormTemplateTable>
    </StartingPageContent>
  );
};

export default FormTemplate;
