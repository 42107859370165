import * as React from "react";
import {
  TextField,
  InputLabel,
  Button,
  Stack,
  Select,
  MenuItem,
  Autocomplete,
  InputAdornment,
  autocompleteClasses,
  Popper,
  ClickAwayListener,
  Checkbox,
  Typography,
} from "@mui/material";
import { Save } from "@mui/icons-material";
import ModalUI from "../UI/ModalUI";
import { SHEDULES, CATEGORY } from "../../data/dummy-data";
import { useDispatch, useSelector } from "react-redux";
import {
  createFormTemplate,
  getAllFormTemplate,
  updateFormTemplate,
} from "../../store/formTemplate";
import { getAllUnit } from "../../store/njesiRaportuese";
import { setLoading } from "../../store/loading";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import { Box } from "@mui/system";
import CorporateFareOutlinedIcon from "@mui/icons-material/CorporateFareOutlined";
import SelectAutocomplate from "../UI/SelectAutocomplate";
import { getAllFormCategory } from "../../store/formCategory";

const FormTemplateModalEditor = (props) => {
  const { njesiRaportuese } = useSelector((state) => state.njesiRaportuese);
  const { formCategory, searchValueCurrent } = useSelector(
    (state) => state.formCategory
  );

  const dispatch = useDispatch();

  const { data, isstatusnew } = props;
  const [state, setState] = React.useState({
    name: "",
    description: "",
    // companyCategory: "",
    schedule: "",
    formCategory: "",
    reportingUnit: "",
    variableSubmission: false,
  });
  const searchState = React.useRef("");
  const searchShedulesState = React.useRef("");
  const searchFormCategoryState = React.useRef("");
  const searchReportingUnitState = React.useRef("");
  const [searchValueServer, setSearchValueServer] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElShedules, setAnchorElShedules] = React.useState(null);
  const [anchorElFormCategory, setAnchorElFormCategory] = React.useState(null);
  const [anchorElReportingUnit, setAnchorElReportingUnit] =
    React.useState(null);
  const [errorValues, setErrorValues] = React.useState([]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickShedules = (event) => {
    setAnchorElShedules(event.currentTarget);
  };

  const handleCloseShedules = () => {
    setAnchorElShedules(null);
  };

  const handleClickFormCategory = (event) => {
    dispatch(getAllFormCategory({ page: 0, searchValue: "" }));
    setAnchorElFormCategory(event.currentTarget);
  };

  const handleCloseFormCategory = () => {
    setAnchorElFormCategory(null);
  };

  const handleClickReportingUnit = (event) => {
    dispatch(getAllUnit({ page: 0, searchValue: "" }));
    setAnchorElReportingUnit(event.currentTarget);
  };

  const handleCloseReportingUnit = () => {
    setAnchorElReportingUnit(null);
  };

  const open = Boolean(anchorEl);
  const openShedules = Boolean(anchorElShedules);
  const openFormCategory = Boolean(anchorElFormCategory);
  const openReportingUnit = Boolean(anchorElReportingUnit);

  const searchItems = (e) => {};

  React.useEffect(() => {
    getAllData();
  }, [dispatch, searchValueServer]);
  React.useEffect(() => {
    setErrorValues([]);
    setState({
      name: "",
      description: "",
      // companyCategory: "",
      schedule: "",
      formCategory: "",
      reportingUnit: "",
      variableSubmission: false,
    });
  }, [data]);

  const getAllData = () => {
    dispatch(setLoading(true));
    dispatch(
      getAllUnit({
        page: 0,
        searchValue: searchValueServer,
        size: 1000,
        filterSelect: true,
      })
    )
      .unwrap()
      .then(() => {
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "variableSubmission") {
      setState({
        ...state,
        variableSubmission: event.target?.checked
          ? event.target.checked
          : false,
      });
    } else {
      setState({ ...state, [name]: value ? value : "" });
    }
  };

  const saveFormTemplate = () => {
    var initialError = [];
    Object.keys(state).map(function (key, index) {
      if (state[key] !== "" || Object.keys(state[key]).length !== 0) {
      } else {
        initialError.push(key);
      }
    });
    setErrorValues(initialError);

    if (initialError.length === 0) {
      if (isstatusnew) {
        const { id, ...newFormTemplate } = state;
        dispatch(createFormTemplate({ formTemplate: newFormTemplate }));
      } else {
        const { ...updateFormTemplateTemp } = state;
        dispatch(updateFormTemplate({ formTemplate: updateFormTemplateTemp }));
      }
      dispatch(getAllFormTemplate());
      props.onClose();
    }
  };
  const checkError = (item) => {
    if (errorValues.find((element) => element === item)) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <ModalUI {...props}>
      <Stack spacing={2}>
        <TextField
          name="name"
          autoComplete="off"
          id="name"
          variant="outlined"
          error={checkError("name")}
          helperText={
            checkError("name") ? "Titulli Formës është i detyruar" : null
          }
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">Titulli Formës</InputAdornment>
            ),
          }}
          value={state.name}
          onChange={handleInputChange}
        />
      </Stack>
      <Stack spacing={2}>
        <TextField
          name="description"
          autoComplete="off"
          id="description"
          variant="outlined"
          multiline
          maxRows={2}
          error={checkError("description")}
          helperText={
            checkError("description") ? "Përshkrimi është i detyruar" : null
          }
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">Përshkrimi</InputAdornment>
            ),
          }}
          value={state.description}
          onChange={handleInputChange}
        />
      </Stack>
      {/* <Stack spacing={2}>
        <SelectAutocomplate
          name="companyKategory"
          id="companyCategory"
          label="Kategoria"
          placeholder="Kërko Kategori"
          noOptionsText="Nuk ka kategori"
          value={state?.companyCategory}
          onClick={handleClick}
          source={CATEGORY}
          open={open}
          anchorEl={anchorEl}
          onClickAway={handleClose}
          error={checkError("companyCategory")}
          helperText={
            checkError("companyCategory") ? "Kategoria është e detyruar" : null
          }
          onClose={(event, reason) => {
            if (reason === "escape") {
              handleClose();
            }
          }}
          onChange={(event, newValue, reason) => {
            if (
              event.type === "keydown" &&
              event.key === "Backspace" &&
              reason === "removeOption"
            ) {
              return;
            }
            setState({
              ...state,
              companyCategory: newValue.id ? newValue.id : "",
            });
            handleClose();
          }}
          onchangesearch={searchItems}
          inputRef={searchState}
        ></SelectAutocomplate>
      </Stack> */}
      <Stack spacing={2}>
        <SelectAutocomplate
          name="schedule"
          id="schedule"
          label="Frekuenca"
          placeholder="Kërko Frekuence"
          noOptionsText="Nuk ka Frekuence"
          value={state?.schedule}
          onClick={handleClickShedules}
          source={SHEDULES}
          open={openShedules}
          anchorEl={anchorElShedules}
          onClickAway={handleCloseShedules}
          error={checkError("schedule")}
          helperText={
            checkError("schedule") ? "Frekuenc është e detyruar" : null
          }
          onClose={(event, reason) => {
            if (reason === "escape") {
              handleCloseShedules();
            }
          }}
          onChange={(event, newValue, reason) => {
            if (
              event.type === "keydown" &&
              event.key === "Backspace" &&
              reason === "removeOption"
            ) {
              return;
            }
            setState({
              ...state,
              schedule: newValue.id ? newValue.id : "",
            });
            handleCloseShedules();
          }}
          onchangesearch={searchItems}
          inputRef={searchShedulesState}
        ></SelectAutocomplate>
        {/* <TextField
          select
          name="schedule"
          autoComplete="off"
          id="schedule"
          variant="outlined"
          error={checkError("schedule")}
          helperText={
            checkError("schedule") ? "Frekuenca është e detyruar" : null
          }
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">Frekuenca</InputAdornment>
            ),
          }}
          value={state.schedule}
          onChange={handleInputChange}
        >
          {SHEDULES.map((item) => (
            <MenuItem key={item.id} value={item.id} name={item.id}>
              {item.label}
            </MenuItem>
          ))}
        </TextField> */}
      </Stack>
      <Stack>
        <SelectAutocomplate
          name="formCategory"
          id="formCategory"
          label="Form Kategori"
          placeholder="Kërko Kategoria"
          noOptionsText="Nuk ka Kategoria"
          value={state?.formCategory}
          onClick={handleClickFormCategory}
          source={formCategory}
          open={openFormCategory}
          anchorEl={anchorElFormCategory}
          onClickAway={handleCloseFormCategory}
          error={checkError("formCategory")}
          helperText={
            checkError("formCategory") ? "Kategoria është e detyruar" : null
          }
          onClose={(event, reason) => {
            if (reason === "escape") {
              handleCloseShedules();
            }
          }}
          onChange={(event, newValue, reason) => {
            if (
              event.type === "keydown" &&
              event.key === "Backspace" &&
              reason === "removeOption"
            ) {
              return;
            }
            setState({
              ...state,
              formCategory: newValue.id ? newValue.id : "",
            });
            handleCloseFormCategory();
          }}
          onchangesearch={searchItems}
          inputRef={searchFormCategoryState}
        ></SelectAutocomplate>
      </Stack>
      <Stack>
        <SelectAutocomplate
          name="reportingUnit"
          id="reportingUnit"
          label="Drejtoria"
          placeholder="Kërko Drejtori"
          noOptionsText="Nuk ka Drejtori"
          value={state?.reportingUnit}
          onClick={handleClickReportingUnit}
          source={njesiRaportuese}
          open={openReportingUnit}
          anchorEl={anchorElReportingUnit}
          onClickAway={handleCloseReportingUnit}
          error={checkError("reportingUnit")}
          helperText={
            checkError("reportingUnit") ? "Drejtoria është e detyruar" : null
          }
          onClose={(event, reason) => {
            if (reason === "escape") {
              handleCloseReportingUnit();
            }
          }}
          onChange={(event, newValue, reason) => {
            if (
              event.type === "keydown" &&
              event.key === "Backspace" &&
              reason === "removeOption"
            ) {
              return;
            }
            setState({
              ...state,
              reportingUnit: newValue.id ? newValue.id : "",
            });
            handleCloseReportingUnit();
          }}
          onchangesearch={searchItems}
          inputRef={searchReportingUnitState}
        ></SelectAutocomplate>
      </Stack>
      <Stack>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h5" color="primary">
            Lejo rikrijimin e formës
          </Typography>
          <Checkbox
            name="variableSubmission"
            id="variableSubmission"
            defaultChecked={false}
            checked={state.variableSubmission}
            onChange={handleInputChange}
            // {...props}
          ></Checkbox>
        </Box>
      </Stack>
      <Stack sx={{ display: "flex", width: "100%" }}>
        <Button
          variant="contained"
          sx={{
            backgroundColor: "primary.dark",
            display: "flex",
            marginTop: "40px",
          }}
          startIcon={<Save />}
          onClick={saveFormTemplate}
        >
          {isstatusnew ? "Shto" : "Ndrysho"}
        </Button>
      </Stack>
    </ModalUI>
  );
};

export default FormTemplateModalEditor;

const StyledAutocompletePopper = styled("div")(({ theme }) => ({
  [`& .${autocompleteClasses.paper}`]: {
    boxShadow: "none",
    margin: 0,
    color: "inherit",
    fontSize: 13,
  },
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    height: "200px",
    backgroundColor: theme.palette.mode === "light" ? "#fff" : "#1c2128",
    padding: 0,
    [`& .${autocompleteClasses.option}`]: {
      alignItems: "flex-start",
      padding: 8,
      minHeight: "60px",
      height: "auto",
      fontSize: "16px",
      alignItems: "center",
      borderBottom: `1px solid  ${
        theme.palette.mode === "light" ? " #eaecef" : "#30363d"
      }`,
      '&[aria-selected="true"]': {
        backgroundColor: "transparent",
      },
      '&[data-focus="true"], &[data-focus="true"][aria-selected="true"]': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  },
  [`&.${autocompleteClasses.popperDisablePortal}`]: {
    position: "relative",
  },
}));

function PopperComponent(props) {
  const { disablePortal, anchorEl, open, ...other } = props;
  return <StyledAutocompletePopper {...other} />;
}

PopperComponent.propTypes = {
  anchorEl: PropTypes.any,
  disablePortal: PropTypes.bool,
  open: PropTypes.bool.isRequired,
};

const StyledPopper = styled(Popper)(({ theme }) => ({
  zIndex: 1,
  paddingBottom: "20px",
  border: `1px solid ${theme.palette.mode === "light" ? "#e1e4e8" : "#30363d"}`,
  boxShadow: `0 8px 24px ${
    theme.palette.mode === "light" ? "rgba(149, 157, 165, 0.2)" : "rgb(1, 4, 9)"
  }`,
  borderRadius: 6,
  width: 480,
  fontSize: 13,
  color: theme.palette.mode === "light" ? "#24292e" : "#c9d1d9",
  backgroundColor: theme.palette.mode === "light" ? "#fff" : "#1c2128",
}));
