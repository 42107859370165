export const colorsBorderGraph = [
  "#45C8D0",
  "#2E58B5",
  "#FFBF00",
  "#8C54FF",
  "#DE3163",
  "#9FE2BF",
  "#40E0D0",
  "#6495ED",
  "#CCCCFF",
  "#DFFF00",
];
export const backgroundColorList = [
  "#45C8D0",
  "#2E58B5",
  "#FFBF00",
  "#8C54FF",
  "#DE3163",
  "#9FE2BF",
  "#40E0D0",
  "#6495ED",
  "#CCCCFF",
  "#DFFF00",
];
