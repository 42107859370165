import axios from "axios";
import authHeader from "./auth-header";
import { APP_URL } from "./http-common";

const URLEntityName = APP_URL + "form-submission";

const getFormSubmissionDashboard = () => {
  return axios
    .get(URLEntityName + "/dashboard", { headers: authHeader() })
    .then((response) => {
      return response.data;
    });
};

const getFormSubmissionSummary = () => {
  return axios
    .get(URLEntityName + "/summary", { headers: authHeader() })
    .then((response) => {
      return response.data;
    });
};

const getFormSubmission = (status) => {
  return axios
    .get(URLEntityName + "/status/" + status, { headers: authHeader() })
    .then((response) => {
      return response.data;
    });
};

const getFormSubmissionId = (id) => {
  return axios
    .get(URLEntityName + "/" + id, { headers: authHeader() })
    .then((response) => {
      return response.data;
    });
};

const getFormSubmissionAll = (page, searchValue, limit) => {
  var valueFormSubmission = "";
  var pageValue = page;

  if (searchValue.formId !== "") {
    valueFormSubmission = "&search=form=" + searchValue.formId;
  } else {
    if (searchValue.reportingUnitId !== "") {
      valueFormSubmission =
        "&search=reportingUnit=" + searchValue.reportingUnitId;
    }
  }

  if (valueFormSubmission !== "") {
    if (searchValue.dateStart !== "") {
      valueFormSubmission =
        valueFormSubmission + "%26date_from=" + searchValue.dateStart;
    }
    if (searchValue.dateEnd !== "") {
      valueFormSubmission =
        valueFormSubmission + "%26date_to=" + searchValue.dateEnd;
    }
  } else {
    if (searchValue.dateStart !== "") {
      valueFormSubmission = "&search=date_from=" + searchValue.dateStart;
      if (searchValue.dateEnd !== "") {
        valueFormSubmission =
          valueFormSubmission + "%26date_to=" + searchValue.dateEnd;
      }
    } else {
      if (searchValue.dateEnd !== "") {
        valueFormSubmission = "&search=date_to=" + searchValue.dateEnd;
      }
    }
  }

  var checked = "";

  if (searchValue.analystCheck === null || searchValue.analystCheck === "all") {
  } else {
    if (valueFormSubmission === "") {
      checked = "&search=analyst_check=" + searchValue.analystCheck;
    } else {
      checked = "%26analyst_check=" + searchValue.analystCheck;
    }
  }

  var valueLimit = "";
  if (limit !== "" && limit !== undefined) {
    valueLimit = "&size=" + limit;
  }
  return axios
    .get(
      URLEntityName +
        "?page=" +
        pageValue +
        valueFormSubmission +
        checked +
        valueLimit,
      { headers: authHeader() }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};

async function submitFormSubmission(formInput) {
  await axios
    .put(URLEntityName + "/" + formInput.id, formInput, {
      headers: authHeader(),
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

async function submitFormSubmissionOverride(formInput) {
  await axios
    .put(URLEntityName + "/" + formInput.id + "/override", formInput, {
      headers: authHeader(),
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

const getLogsFormSubmission = (id) => {
  return axios
    .get(URLEntityName + "/" + id + "/logs", { headers: authHeader() })
    .then((response) => {
      return response.data;
    });
};

const getFormSubmissionExport = (data) => {
  return axios
    .post(URLEntityName + "/export", data, { headers: authHeader() })
    .then((response) => {
      return response;
    });
};

const setCheckedForm = (id) => {
  return axios
    .put(URLEntityName + "/" + id + "/check", null, { headers: authHeader() })
    .then((response) => {
      return response;
    });
};

const getFormSubmissionLogsExport = (id) => {
  return axios
    .get(URLEntityName + "/" + id + "/logs:export", {
      headers: authHeader(),
    })
    .then((response) => {
      return response;
    });
};

const FormSubmissionService = {
  getFormSubmission,
  getFormSubmissionId,
  submitFormSubmission,
  getFormSubmissionAll,
  getFormSubmissionDashboard,
  getFormSubmissionSummary,
  getLogsFormSubmission,
  submitFormSubmissionOverride,
  getFormSubmissionExport,
  setCheckedForm,
  getFormSubmissionLogsExport,
};
export default FormSubmissionService;
