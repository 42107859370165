import * as React from "react";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import Box from "@mui/material/Box";
import { Toolbar, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

const Navigation = (props) => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const openEntity = (item) => {
    props.onClose();
    //props.onPageName(item.label ? item.label : "");
    if (isLoggedIn) {
      navigate("/" + item.value);
    }
  };

  const itemListExtra = [
    { label: "Form Template", value: "formTemplate" },
    { label: "Njësi Raportuese", value: "njesiRaportuese" },
    { label: "Perdorues", value: "perdorues" },
  ];

  const itemListOperatorEditor = [
    { label: "Dashboard", value: "dashboard" },
    { label: "Forma te plotesuara", value: "formatePlotesuara" },
    ...(user ? (user?.role === "ROLE_EXECUTIVE" ? itemListExtra : []) : []),
  ];

  const list = (anchor) => (
    <Box sx={{ width: anchor === "right" ? 400 : 250 }} role="presentation">
      <List>
        {itemListOperatorEditor.map((item) => (
          <ListItem button key={item.value} onClick={() => openEntity(item)}>
            <ListItemIcon>
              <InboxIcon />
            </ListItemIcon>
            <ListItemText primary={item.label} />
          </ListItem>
        ))}
      </List>
      <Divider />
    </Box>
  );
  return (
    <Drawer
      sx={{
        paddingLeft: "0px !important",
        zIndex: "1000 !important",
      }}
      anchor="left"
      {...props}
    >
      <Toolbar sx={{ justifyContent: "center", bgcolor: "primary.main" }}>
        <Typography variant="h6" color="white">
          ERE MENU
        </Typography>
      </Toolbar>
      {list("left")}
    </Drawer>
  );
};

export default Navigation;
