import AuthService from "./auth.service";
import jwt_decode from "jwt-decode";

export default function authHeader() {
  const user = JSON.parse(localStorage.getItem("userData"));
  if (!user.token) {
    AuthService.logout();
  } else {
    const { exp } = jwt_decode(user.token);
    const expirationTime = exp * 1000 - 60000;
    if (Date.now() >= expirationTime) {
      AuthService.logout();
    } else {
      if (user && user.token) {
        return {
          Authorization: "Bearer " + user.token,
          "Content-Type": "application/json",
        };
      } else {
        return {};
      }
    }
  }
}
