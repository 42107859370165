import * as React from "react";
import {
  TextField,
  Button,
  Stack,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { Save } from "@mui/icons-material";
import ModalUI from "../UI/ModalUI";
import { useDispatch, useSelector } from "react-redux";
import { createUser, getAll, updateUser } from "../../store/perdorues";
import { resetPassword } from "../../store/auth";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const ResetPassword = (props) => {
  const dispatch = useDispatch();
  const [state, setState] = React.useState({
    confirm: "",
    password: "",
    strongPassword: true,
    showPassword: false,
  });
  const [errorValues, setErrorValues] = React.useState([]);
  const [match, setMatch] = React.useState(true);
  const [anchorElUnit, setAnchorElUnit] = React.useState(null);

  React.useEffect(() => {
    setState((state) => ({
      ...state,
      confirm: "",
      password: "",
      strongPassword: true,
      showPassword: false,
    }));
    setMatch(true);
    setErrorValues([]);
  }, [props]);

  const handleClickUnit = (event) => {
    setAnchorElUnit(event.currentTarget);
  };

  const handleCloseUnit = () => {
    setAnchorElUnit(null);
  };

  const changePassword = (event) => {
    const { value } = event.target;
    setState((state) => ({
      ...state,
      password: value ? value : "",
    }));
  };
  const changePasswordConfirm = (event) => {
    const { value } = event.target;
    setState((state) => ({
      ...state,
      confirm: value ? value : "",
    }));
  };

  const onBlurPassword = (event) => {
    const { value } = event.target;
    var strongRegex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
    );
    if (value.match(strongRegex)) {
      setState((state) => ({
        ...state,
        strongPassword: true,
      }));
    } else {
      setState((state) => ({
        ...state,
        strongPassword: false,
      }));
    }
  };

  const handleClickShowPassword = () => {
    setState({
      ...state,
      showPassword: !state.showPassword,
    });
  };

  const saveUser = () => {
    var initialError = [];

    Object.keys(state).map(function (key, index) {
      if (state[key] === "" || state[key] === undefined) {
        initialError.push(key);
      } else {
      }
    });

    setErrorValues(initialError);

    if (initialError.length === 0 && match && state.strongPassword) {
      const { confirm, ...updateUserState } = state;

      dispatch(resetPassword({ passwordData: updateUserState }));
      props.onClose();
    }
  };

  const checkError = (item) => {
    if (errorValues.find((element) => element === item)) {
      return true;
    } else {
      return false;
    }
  };
  const checkErrorMatchPassword = () => {
    if (state.password !== state.confirm) {
      setMatch(false);
    } else {
      setMatch(true);
    }
  };

  return (
    <ModalUI
      buttons={
        <Stack sx={{ display: "flex", width: "100%" }}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "primary.dark",
              display: "flex",
              marginTop: "40px",
            }}
            startIcon={<Save />}
            onClick={saveUser}
          >
            Ndrysho
          </Button>
        </Stack>
      }
      {...props}
      onClickAway={handleCloseUnit}
    >
      <Stack spacing={2}>
        <TextField
          name="password"
          autoComplete="new-password"
          id="password"
          type={state.showPassword ? "text" : "password"}
          variant="outlined"
          value={state.password}
          error={checkError("password") || !state.strongPassword}
          onBlur={onBlurPassword}
          helperText={
            checkError("password")
              ? "Fjalëkalimi është i detyruar"
              : !state.strongPassword
              ? "Përfshi min 8 karaktere, karakter special, shkronjë të madhe, numër"
              : null
          }
          onChange={changePassword}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">Fjalëkalimi ri</InputAdornment>
            ),
            endAdornment: (
              <InputAdornment
                sx={{
                  "&.MuiInputAdornment-root.MuiInputAdornment-root": {
                    minWidth: "60px",
                  },
                }}
                position="end"
              >
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  // onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {state.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          name="confirm"
          autoComplete="new-password"
          id="confirm"
          type="password"
          variant="outlined"
          value={state.confirm}
          error={checkError("confirm") || !match}
          helperText={
            checkError("confirm")
              ? "Konfirmimi i Fjalëkalimit është i detyruar"
              : !match
              ? "Fjalëkalimi nuk përputhet"
              : null
          }
          onBlur={checkErrorMatchPassword}
          onChange={changePasswordConfirm}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                Konfirmo Fjalëkalimin
              </InputAdornment>
            ),
          }}
        />
      </Stack>
    </ModalUI>
  );
};
export default ResetPassword;
