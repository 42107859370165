import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  formSubmisionFilter: {
    reportingUnitId: "",
    formId: "",
    dateStart: "",
    dateEnd: "",
    analystCheck: null,
  },
};
const formSubmisionFilterSlice = createSlice({
  name: "formSubmisionFilter",
  initialState,
  reducers: {
    setFormSubmisionFilter: (state, action) => {
      return { formSubmisionFilter: action.payload };
    },
  },
});
const { reducer, actions } = formSubmisionFilterSlice;
export const { setFormSubmisionFilter } = actions;
export default reducer;
