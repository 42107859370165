import * as React from "react";
import StartingPageContent from "../components/StartingPage/StartingPageContent";
import FormCard from "../components/Grid/FormCard";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import { setLoading } from "../store/loading";
import { useDispatch, useSelector } from "react-redux";
import {
  getFormSubmissionDashboard,
  getFormSubmissionSummary,
} from "../store/formSubmission";
import ContainerDashboard from "../components/UI/ContainerDashboard";
import PanelRight from "../components/Navigation/PanelRight";
import { DashboardRaports } from "../components/Raports/DashboardRaports";

const Dashboard = () => {
  const dispatch = useDispatch();
  const { formSubmissionDashboard } = useSelector(
    (state) => state.formSubmission
  );

  const user = useSelector((state) => state.auth.user);
  const isAnalyst = user?.role === "ROLE_ANALYST";
  const isEditor = user?.role === "ROLE_EDITOR";
  const isSoftEkzekutive = user?.role === "ROLE_SOFT_EXECUTIVE";

  React.useEffect(() => {
    getAllData();
    //dispatch(getFormSubmissionDashboard());
    dispatch(getFormSubmissionSummary());
  }, [dispatch, getFormSubmissionDashboard, getFormSubmissionSummary]);

  const getAllData = () => {
    dispatch(setLoading(true));
    dispatch(getFormSubmissionDashboard())
      .unwrap()
      .then(() => {
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };
  return (
    <StartingPageContent pageName="">
      <Box sx={{ justifyContent: "space-between", display: "flex" }}>
        <Box sx={{ mr: isAnalyst ? "0px" : "60px", width: 1 }}>
          {isAnalyst || isSoftEkzekutive ? (
            <DashboardRaports></DashboardRaports>
          ) : (
            <ContainerDashboard
              header={
                <Typography variant="h5" sx={{ ml: 1 }}>
                  {isEditor
                    ? "Forma për tu aprovuar "
                    : "Forma për tu plotësuar "}
                  ({formSubmissionDashboard.length})
                </Typography>
              }
              length={formSubmissionDashboard.length}
            >
              {formSubmissionDashboard.length !== 0 ? (
                <Box
                  sx={{
                    display: "flex",
                    // flexWrap: "wrap",
                    flexWrap: { xs: "nowrap", md: "wrap" },
                    m: 1,
                    justifyContent: {
                      xs: "center",
                      md: "center",
                      lg: "center",
                      xl: "left",
                    },
                    // justifyContent: "center",
                  }}
                >
                  {formSubmissionDashboard.map((item, index) => (
                    <FormCard key={item.id} data={item}></FormCard>
                  ))}
                </Box>
              ) : (
                <Box
                  sx={{
                    flexDirection: "column",
                    textAlign: "center",
                    alignContent: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography sx={{ color: "#B5B5BB", fontSize: 20 }}>
                    Nuk ka asnjë formë në Kartelë.
                  </Typography>
                </Box>
              )}
            </ContainerDashboard>
          )}
        </Box>
        {!isAnalyst && !isSoftEkzekutive && <PanelRight></PanelRight>}
      </Box>
    </StartingPageContent>
  );
};

export default Dashboard;
