import * as React from "react";
import { IconButton } from "@mui/material";

const RowButtonIcon = (props) => {
  return (
    <IconButton
      variant="outlined"
      color={props.delete ? "error" : "primary"}
      size="small"
      style={{
        marginLeft: 8,
        color: props.delete ? "#CD3728" : "#008fc6",
        height: "54px",
        width: "54px",
        borderRadius: "8px",
      }}
      {...props}
    >
      {props.children}
    </IconButton>
  );
};

export default RowButtonIcon;
