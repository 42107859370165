import React, { useEffect, useState } from "react";
import classes from "./ProfileForm.module.css";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Button,
  Stack,
  TextField,
  InputLabel,
  MenuItem,
  Box,
  Grid,
  Typography,
  Chip,
} from "@mui/material";
import BottomToolbar from "../UI/BottomToolbar";
import { CATEGORY } from "../../data/dummy-data";
import { getCompany, updateCompany } from "../../store/company";
import SelectAutocomplate from "../UI/SelectAutocomplate";
import { getAllFormCategory } from "../../store/formCategory";

const ProfileForm = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { company } = useSelector((state) => state.company);

  const { formCategory } = useSelector((state) => state.formCategory);

  // dispatch(getCompany({ id: "f78a9f3e-0a6e-4e14-988b-288f8d63546c" }));

  const user = useSelector((state) => state.auth.user);
  const [userState, setUserState] = useState(user);

  const navigate = useNavigate();

  const stateDataCompany = location?.state?.company;

  const [dataValuesTest, setDataValuesTest] = React.useState([
    // {
    //   label: "Përdoruesi",
    //   name: "username",
    //   value: "",
    //   required: true,
    //   type: "text",
    //   helperText: "Përdoruesi është i detyruar",
    //   errorValue: false,
    // },
    // {
    //   label: "Fjalëkalimi",
    //   name: "password",
    //   value: "",
    //   required: true,
    //   type: "password",
    //   helperText: "Fjalëkalimi është i detyruar",
    //   errorValue: false,
    // },
    // {
    //   label: "E-mail",
    //   name: "email",
    //   value: "",
    //   required: true,
    //   type: "email",
    //   helperText: "E-mail është i detyruar",
    //   errorValue: false,
    // },
    {
      label: "Kategori",
      name: "category",
      value: "",
      required: true,
      type: "list",
      helperText: "Kategori është i detyruar",
      options: CATEGORY,
      errorValue: false,
    },
    {
      label: "Shteti",
      name: "country",
      value: "",
      required: true,
      type: "list",
      helperText: "Shteti është i detyruar",
      options: [
        {
          label: "Albania",
          value: "Albania",
        },
        { label: "Italy", value: "italy" },
      ],
      errorValue: false,
    },
    {
      label: "Emri ligjor kompanise",
      name: "name",
      value: "",
      required: true,
      type: "text",
      helperText: "Emri ligjor kompanise është i detyruar",
      errorValue: false,
    },
    {
      label: "Forma ligjore",
      name: "legalForm",
      value: "",
      required: true,
      type: "text",
      helperText: "Forma ligjore është e detyruar",
      errorValue: false,
    },
    {
      label: "Adresa kompanise",
      name: "address",
      value: "",
      required: true,
      type: "text",
      helperText: "Adresa kompanise është e detyruar",
      errorValue: false,
    },
    {
      label: "Qyteti kompanise",
      name: "city",
      value: "",
      required: true,
      type: "text",
      helperText: "Qyteti kompanise është i detyruar",
      errorValue: false,
    },
    {
      label: "Kodi eic",
      name: "eicCode",
      value: "",
      required: true,
      type: "text",
      helperText: "Kodi eic është i detyruar",
      errorValue: false,
    },
    {
      label: "Kodi bic",
      name: "bicCode",
      value: "",
      required: true,
      type: "text",
      helperText: "Kodi bic është i detyruar",
      errorValue: false,
    },
    {
      label: "Kodi lei",
      name: "leiCode",
      value: "",
      required: true,
      type: "text",
      helperText: "Kodi lei është i detyruar",
      errorValue: false,
    },
    {
      label: "NIPT",
      name: "vatNumber",
      value: "",
      required: true,
      type: "text",
      helperText: "Nipt është i detyruar",
      errorValue: false,
    },
    {
      label: "Website",
      name: "website",
      value: "",
      required: false,
      type: "text",
      helperText: "",
      errorValue: false,
    },
    {
      label: "Kodi Zip",
      name: "zipCode",
      value: "",
      required: false,
      type: "text",
      helperText: "",
      errorValue: false,
    },
    {
      label: "Publikim informacioni",
      name: "info1",
      value: "",
      required: false,
      type: "text",
      helperText: "",
      errorValue: false,
    },
    {
      label: "Wepage ku jane publikuar info",
      name: "info2",
      value: "",
      required: false,
      type: "text",
      helperText: "",
      errorValue: false,
    },
    {
      label: "Info",
      name: "info3",
      value: "",
      required: false,
      type: "text",
      helperText: "",
      errorValue: false,
    },
    {
      label: "Kategoritë e Formave që do ti shfaqen",
      name: "formCategories",
      value: "",
      required: false,
      type: "multiSelect",
      helperText: "Kategoria e Formave është e detyruar",
      errorValue: false,
    },
  ]);

  const [dataValues, setDataValues] = React.useState({
    username: "",
    password: "",
    email: "",
    id: "",
    category: "",
    formCategories: [],
    country: "",
    name: "",
    legalForm: "",
    address: "",
    city: "",
    zipCode: "",
    eicCode: "",
    bicCode: "",
    leiCode: "",
    vatNumber: "",
    submissionDate: "",
    website: "",
    info1: "",
    info2: "",
    info3: "",
  });
  const [categoriesSelectState, setCategorisSelectState] = React.useState([]);

  useEffect(() => {
    setUserState(user);
    dispatch(getAllFormCategory({ page: 0, searchValue: "" }));
  }, [user]); //or company

  useEffect(() => {
    // setUserState(user);
    dispatch(
      getCompany({
        id: stateDataCompany?.id ? stateDataCompany.id : userState?.company?.id,
      })
    );

    const ourNextValuesList = [...dataValuesTest];
    dataValuesTest.map((item) => {
      const currentValueList = ourNextValuesList.filter((item) => {
        item.value = company[item.name];

        return item;
      });
    });

    setDataValues(company);

    setCategorisSelectState(
      company?.formCategories
        ? [
            ...company?.formCategories?.map((item) => {
              return item.id;
            }),
          ]
        : []
    );
  }, [company.id]); //or company

  const [state, setState] = React.useState({
    showPassword: false,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    const ourNextValuesList = [...dataValuesTest];
    const currentValueList = ourNextValuesList.filter((item) => {
      if (item.name === name) {
        item.value = value;
        if (item.value === "" && item.required === true) {
          item.errorValue = true;
        } else {
          item.errorValue = false;
        }
      }
      return item;
    });

    setDataValuesTest(currentValueList);

    setDataValues((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleChangeList = (e) => {
    const { name, value } = e.target;

    setDataValues((prevState) => {
      return {
        ...prevState,
        [name]: [...state, value],
      };
    });
  };

  const handleClickShowPassword = () => {
    setState({
      ...state,
      showPassword: !state.showPassword,
    });
  };

  const saveForm = (e) => {
    var isError = false;

    const ourNextValuesList = [...dataValuesTest];
    const currentValueList = ourNextValuesList.filter((item) => {
      if (item.value === "" && item.required === true) {
        item.errorValue = true;
        isError = true;
      } else {
        item.errorValue = false;
      }
      return item;
    });

    setDataValuesTest(currentValueList); // here update errorValue of

    const { username, email, password, ...companyData } = dataValues;

    const dataVlauesEnd = {
      ...companyData,
      formCategories: categoriesSelectState,
    };

    if (isError) {
      console.log("Error Message");
    } else {
      e.preventDefault();
      dispatch(
        updateCompany({
          company: dataVlauesEnd,
        })
      )
        .unwrap()
        .then(() => {
          stateDataCompany && onBackNavigation();
        })
        .catch(() => {
          stateDataCompany && onBackNavigation();
        });
    }
  };

  const onBackNavigation = () => {
    navigate(-1);
  };
  const DeleteFormCategory = (item) => {
    setCategorisSelectState(
      categoriesSelectState.filter((it) => it !== item.id)
    );
  };

  return (
    <>
      <form className={classes.form}>
        <Typography
          sx={{ textAlign: "left", marginBottom: "30px" }}
          variant="h4"
        >
          {dataValues.name}
        </Typography>
        {/* <div className={classes.control}>
          <label>Username</label>
          <h2>{userState.username}</h2>
          <label>Roli</label>
          <h2>{userState.role}</h2>
          <label style={{ fontSize: 12 }}>Token</label>
          <label style={{ fontSize: 12 }}>{userState.token}</label>
        </div> */}
        <section>
          <div style={{ marginTop: "0px" }}>
            <div>
              <Box sx={{ flexGrow: 1 }}>
                <Grid
                  container
                  spacing={{ xs: 2, md: 3 }}
                  columns={{ xs: 4, sm: 8, md: 12 }}
                >
                  <Grid
                    item
                    xs={8}
                    sm={8}
                    md={4}
                    key="username"
                    component="div"
                  >
                    <InputField
                      label="Username"
                      name={userState?.username}
                      type="text"
                      value={userState?.username}
                      disabled
                    ></InputField>
                  </Grid>
                  {dataValuesTest?.map((item) => {
                    return (
                      <Grid
                        item
                        xs={8}
                        sm={8}
                        md={item.type === "multiSelect" ? 12 : 4}
                        key={item?.label}
                        component="div"
                      >
                        {item.type === "multiSelect" ? (
                          <Stack>
                            {userState?.role === "ROLE_EXECUTIVE" && (
                              <>
                                <InputLabel
                                  sx={{
                                    fontFamily: "Basier Circle",
                                    fontSize: "18px",
                                    color: "#091B3D",
                                    lineHeight: "22px",
                                  }}
                                  required={item?.required}
                                >
                                  {item?.label}
                                </InputLabel>
                                <div
                                  style={{
                                    margin: "auto 0 0 0",
                                    width: "100%",
                                  }}
                                >
                                  <TextField
                                    select
                                    name={item?.name}
                                    autoComplete="off"
                                    id={item?.name}
                                    variant="outlined"
                                    required={item?.required}
                                    error={item?.errorValue}
                                    value={item?.value}
                                    // onChange={handleChange}
                                    onChange={(event, newValue, reason) => {
                                      if (
                                        event.type === "keydown" &&
                                        event.key === "Backspace" &&
                                        reason === "removeOption"
                                      ) {
                                        return;
                                      }
                                      setCategorisSelectState([
                                        ...categoriesSelectState,
                                        newValue.props.value,
                                      ]);
                                      // handleCloseNjesia();
                                    }}
                                    type={item?.type}
                                    helperText={
                                      item?.errorValue ? item?.helperText : null
                                    }
                                    sx={{
                                      "& .MuiInputBase-root.MuiOutlinedInput-root":
                                        {
                                          height: "50px",
                                          textAlign: "left",
                                        },
                                      "& .MuiInputBase-input.MuiOutlinedInput-input":
                                        {
                                          padding: "12px 14px",
                                        },
                                      width: "100%",
                                    }}
                                  >
                                    {item?.type === "multiSelect" &&
                                      formCategory.map((option) => (
                                        <MenuItem
                                          key={option?.name}
                                          value={option?.id}
                                        >
                                          {option?.name}
                                        </MenuItem>
                                      ))}
                                  </TextField>
                                </div>
                                <div
                                  style={{
                                    paddingTop: "10px",
                                    textAlign: "left",
                                  }}
                                >
                                  {formCategory.map((item) => {
                                    return (
                                      categoriesSelectState?.includes(
                                        item.id
                                      ) && (
                                        <Chip
                                          key={item.id}
                                          value={item.name}
                                          label={item.name}
                                          variant="outlined"
                                          style={{ marginRight: "4px" }}
                                          onDelete={() =>
                                            DeleteFormCategory(item)
                                          }
                                        />
                                      )
                                    );
                                  })}
                                </div>
                              </>
                            )}
                          </Stack>
                        ) : (
                          <InputField
                            label={item?.label}
                            required={item?.required}
                            name={item?.name}
                            type={item?.type}
                            options={item?.options}
                            helperText={item?.helperText}
                            disabled={item?.disabled}
                            value={item?.value}
                            errorValue={item?.errorValue}
                            onChange={handleChange}
                          ></InputField>
                        )}
                      </Grid>
                    );
                  })}
                </Grid>
              </Box>
            </div>
            <BottomToolbar>
              <Button
                variant="contained"
                sx={{
                  width: "200px",
                  height: "52px",
                  backgroundColor: "primary.dark",
                }}
                onClick={saveForm}
              >
                Ruaj
              </Button>
            </BottomToolbar>
          </div>
        </section>
      </form>
    </>
  );
};

const InputField = (props) => {
  return (
    <Stack spacing={1} sx={{ height: "100%" }}>
      <InputLabel
        sx={{
          fontFamily: "Basier Circle",
          fontSize: "18px",
          color: "#091B3D",
          lineHeight: "22px",
        }}
        required={props?.required}
      >
        {props?.label}
      </InputLabel>

      <div style={{ margin: "auto 0 0 0", width: "100%" }}>
        <TextField
          select={props?.type === "list"}
          name={props?.name}
          autoComplete="off"
          id={props?.name}
          variant="outlined"
          required={props?.required}
          error={props?.errorValue}
          value={props?.value}
          onChange={props?.onChange}
          type={props?.type}
          helperText={props?.errorValue ? props?.helperText : null}
          sx={{
            "& .MuiInputBase-root.MuiOutlinedInput-root": {
              height: "50px",
              textAlign: "left",
            },
            "& .MuiInputBase-input.MuiOutlinedInput-input": {
              padding: "12px 14px",
            },
            width: "100%",
          }}
          disabled={props?.disabled}
        >
          {props?.type === "list" &&
            props?.options.map((option) => (
              <MenuItem key={option?.value} value={option?.value}>
                {option?.label}
              </MenuItem>
            ))}
        </TextField>
      </div>
    </Stack>
  );
};

export default ProfileForm;
