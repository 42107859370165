import * as React from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  InputLabel,
  Button,
  Stack,
} from "@mui/material";
import { Save } from "@mui/icons-material";

const ModalEditor = (props) => {
  const [dataValues, setDataValues] = React.useState(props.data);

  React.useEffect(() => {
    setDataValues(props.data);
  }, [props.data]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setDataValues((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const saveUser = () => {
    props.onClose();
    //setDataLocal({ ...dataLocal, rows: { user } });
  };

  return (
    <Modal
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      {...props}
    >
      <Box sx={styleModalForm}>
        <Typography
          id="modal-modal-title"
          sx={{ pb: 4, textAlign: "center" }}
          variant="h5"
          component="h2"
        >
          {props.formname}
        </Typography>
        <Stack spacing={2}>
          {Object.entries(dataValues).map(([key, value]) => (
            // <InputEdit label={k} value={v} />
            <Stack key={key} spacing={2}>
              <InputLabel>{key}</InputLabel>
              <TextField
                name={key}
                autoComplete="off"
                id="outlined-basic"
                variant="outlined"
                value={value}
                onChange={handleChange}
              />
            </Stack>
          ))}
          <Button variant="contained" startIcon={<Save />} onClick={saveUser}>
            Ruaj
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

const styleModalForm = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default ModalEditor;
