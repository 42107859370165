import Grid from "../components/Grid/Grid";
import FormCategoryTable from "../components/FormCategory/FormCategoryTable";
import StartingPageContent from "../components/StartingPage/StartingPageContent";
const FormCategory = () => {
  return (
    <StartingPageContent pageName="Kategoritë e Formave">
      <FormCategoryTable></FormCategoryTable>
    </StartingPageContent>
  );
};

export default FormCategory;
