import * as React from "react";
import {
  TextField,
  Button,
  Stack,
  InputAdornment,
  Checkbox,
  Typography,
  Box,
} from "@mui/material";
import { Check, Save } from "@mui/icons-material";
import ModalUI from "../UI/ModalUI";
import { useDispatch, useSelector } from "react-redux";
import {
  createUnit,
  getAllUnit,
  updateUnit,
} from "../../store/njesiRaportuese";

const NjesiRaportueseModalEditor = (props) => {
  const dispatch = useDispatch();
  const { currentPage, searchValueCurrent } = useSelector(
    (state) => state.njesiRaportuese
  );
  const { data, isstatusnew, open } = props;
  const [state, setState] = React.useState({
    name: "",
    priceApproval: false,
    // description: "",
  });
  const [errorValues, setErrorValues] = React.useState([]);

  React.useEffect(() => {
    setErrorValues([]);
    setState({
      id: data.id ? data.id : "",
      name: data.name ? data.name : "",
      priceApproval: data.priceApproval ? data.priceApproval : false,
      // description: data.description ? data.description : "",
      status: data.status ? data.status : "ACTIVE",
    });
  }, [data, open]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "priceApproval") {
      setState({
        ...state,
        priceApproval: event.target?.checked ? event.target.checked : false,
      });
    } else {
      setState({ ...state, [name]: value ? value : "" });
    }
  };

  const saveUser = () => {
    var initialError = [];
    Object.keys(state).map(function (key, index) {
      if (state[key] !== "" || Object.keys(state[key]).length !== 0) {
      } else {
        initialError.push(key);
      }
    });

    setErrorValues(initialError);

    var initialErrorTemp = [];
    initialErrorTemp = initialError.filter((item) => item !== "id"); // remove id item

    if (initialErrorTemp.length === 0) {
      if (isstatusnew) {
        const { id, ...newUnit } = state;
        dispatch(createUnit({ unit: newUnit }));
      } else {
        // const { priceApproval, ...updateUnitTemp } = state;
        const { ...updateUnitTemp } = state;
        dispatch(
          updateUnit({
            unit: updateUnitTemp,
            currentPage: currentPage,
            searchValueCurrent: searchValueCurrent,
          })
        );
      }
      dispatch(
        getAllUnit({ page: currentPage, searchValue: searchValueCurrent })
      );
      props.onClose();
    }
  };

  const checkError = (item) => {
    if (errorValues.find((element) => element === item)) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <ModalUI {...props}>
      <Stack spacing={2}>
        <TextField
          name="name"
          autoComplete="off"
          id="name"
          variant="outlined"
          error={checkError("name")}
          helperText={checkError("name") ? "Emri është i detyruar" : null}
          InputProps={{
            startAdornment: (
              <InputAdornment
                sx={{
                  "&.MuiInputAdornment-root": {
                    minWidth: "100px",
                  },
                }}
                position="start"
              >
                Emër
              </InputAdornment>
            ),
          }}
          value={state.name}
          onChange={handleInputChange}
        />
      </Stack>
      <Stack>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h5" color="primary">
            Aprovon Çmimin e Produkteve
          </Typography>
          <Checkbox
            name="priceApproval"
            id="priceApproval"
            defaultChecked={false}
            checked={state.priceApproval}
            onChange={handleInputChange}
            // {...props}
          ></Checkbox>
        </Box>
      </Stack>
      {/* <Stack spacing={2}>
        <TextField
          name="description"
          autoComplete="off"
          id="description"
          variant="outlined"
          error={checkError("description")}
          helperText={
            checkError("description") ? "Përshkrimi është i detyruar" : null
          }
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">Përshkrimi</InputAdornment>
            ),
          }}
          value={state.description}
          onChange={handleInputChange}
        />
      </Stack> */}
      <Stack sx={{ display: "flex", width: "100%" }}>
        <Button
          variant="contained"
          sx={{
            backgroundColor: "primary.dark",
            display: "flex",
            marginTop: "40px",
          }}
          startIcon={<Save />}
          onClick={saveUser}
        >
          {isstatusnew ? "Shto" : "Ndrysho"}
        </Button>
      </Stack>
    </ModalUI>
  );
};
export default NjesiRaportueseModalEditor;
