import { useSelector } from "react-redux";
import UserProfile from "../components/Profile/UserProfile";

import StartingPageContent from "../components/StartingPage/StartingPageContent";
const ProfilePage = () => {
  const user = useSelector((state) => state.auth.user);
  const isOperator = user?.role === "ROLE_OPERATOR" ? true : false;
  return (
    <StartingPageContent back={!isOperator} pageName="Profil i Kompanise">
      <UserProfile />
    </StartingPageContent>
  );
};

export default ProfilePage;
