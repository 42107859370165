const FORM_INPUTE_COLUMNS = [
  {
    field: "label",
    headerName: "Indikator",
    width: 250,
    flex: 1,
    editable: false,
  },
  {
    field: "defaultAnswer",
    headerName: "Përgjigje Default",
    width: 250,
    flex: 1,
    editable: false,
  },
  {
    field: "unit",
    headerName: "Njësia matëse",
    width: 200,
    flex: 1,
    editable: false,
  },
];

export default FORM_INPUTE_COLUMNS;
