import * as React from "react";
import { Checkbox, Divider, Typography } from "@mui/material";
import { Box, textAlign } from "@mui/system";
import FolderIcon from "@mui/icons-material/Folder";
import { useSelector } from "react-redux";

const ContainerDashboard = (props) => {
  const user = useSelector((state) => state.auth.user);
  const isEditor = user?.role === "ROLE_EDITOR";
  const isOperator = user?.role === "ROLE_OPERATOR";
  const isAnalist = user?.role === "ROLE_ANALYST";
  return (
    <Box>
      <Box
        sx={{
          padding: 5,
          flexGrow: 1,
          height: 100,
          display: "flex",
          backgroundColor: "#fff",
          flexDirection: "column",
          borderTopLeftRadius: "21px",
          borderTopRightRadius: "21px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent:
              props?.status === "REVIEWED" && isAnalist && "space-between",
            alignItems: "center",
          }}
        >
          <FolderIcon sx={{ color: "#B2D4FF" }}></FolderIcon>
          {props.header}
          {props?.status === "REVIEWED" && isAnalist && (
            <Box
              sx={{
                marginLeft: "auto",
                display: "flex",
                alignItems: "center",
              }}
            >
              {/* <Typography variant="h8" color="primary">
                Kontrolluar
              </Typography>
              <Checkbox
                defaultChecked={props?.analystCheck}
                checked={props?.analystCheck}
                // value={props?.analystCheck ? true : false}
                disabled={props?.analystCheck}
                onChange={props.onChange}
                // {...props}
              ></Checkbox> */}
            </Box>
          )}
        </Box>
      </Box>
      <Box
        flex={1}
        overflow="auto"
        sx={{
          paddingBottom: 3,
          flexGrow: 1,
          height: props.height ? props.height : 700,
          display: "flex",
          backgroundColor: "#fff",
          flexDirection: "column",
          borderBottomLeftRadius: "21px",
          borderBottomRightRadius: "21px",
          padding: props.padding ? props.padding : "20px",
          textAlign: props.length ? "center" : "left",
          alignContent: props.length ? "center" : "left",
          justifyContent: props.length === 0 ? "center" : "top",
        }}
      >
        {props.children}
      </Box>
    </Box>
  );
};

export default ContainerDashboard;
