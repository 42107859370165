const FORM_TEMPLATE_COLUMNS = [
  {
    field: "name",
    headerName: "Titulli i Formës",
    width: 400,
    minWidth: 400,
    flex: 1,
    editable: false,
  },
  {
    field: "description",
    headerName: "Përshkrimi",
    width: 300,
    flex: 1,
    editable: false,
  },
  // {
  //   field: "status",
  //   headerName: "Status",
  //   editable: false,
  // },
];

export default FORM_TEMPLATE_COLUMNS;
