const month = [
  "Janar",
  "Shkurt",
  "Mars",
  "Prill",
  "Maj",
  "Qershor",
  "Korrik",
  "Gusht",
  "Shtator",
  "Tetor",
  "Nentor",
  "Dhjetor",
];

const periodText = (startDate, endDate, monthly) => {
  const start = new Date(startDate);
  const end = new Date(endDate);
  if (monthly) {
    return month[start.getMonth()] + " " + start.getFullYear();
  } else {
    return (
      month[start.getMonth()] +
      " - " +
      month[end.getMonth()] +
      " " +
      start.getFullYear()
    );
  }
};

export default periodText;
