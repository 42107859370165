import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";
import { setLoading } from "./loading";
import GraphDataService from "../services/graph.service";

const initialState = {
  graphData: [],
};
export const getGraphData = createAsyncThunk(
  "form/getGraphData",
  async ({ indikators }, thunkAPI) => {
    try {
      const data = await GraphDataService.getGraphData(indikators);
      return {
        graphData: data,
      };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

const graphDataSlice = createSlice({
  name: "graphData",
  initialState,
  extraReducers: {
    [getGraphData.fulfilled]: (state, action) => {
      state.graphData = action.payload.graphData;
    },
    [getGraphData.rejected]: (state, action) => {
      state.graphData = [];
    },
  },
});

const { reducer } = graphDataSlice;
export default reducer;
