import * as React from "react";
import { Divider } from "@mui/material";
import { Box, textAlign } from "@mui/system";
import FolderIcon from "@mui/icons-material/Folder";
const ContainerAnalyst = (props) => {
  return (
    <Box>
      <Box
        sx={{
          padding: "20px 40px 0px 40px",
          flexGrow: 1,

          display: "flex",
          backgroundColor: "#fff",
          flexDirection: "column",
          borderTopLeftRadius: "21px",
          borderTopRightRadius: "21px",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>{props.header}</Box>
      </Box>
      <Box
        flex={1}
        overflow="auto"
        sx={{
          paddingBottom: 1,
          flexGrow: 1,
          overflow: "auto",
          height: props.heightcustom ? props.heightcustom : 650,
          minHeight: 600,
          display: "flex",
          backgroundColor: "#fff",
          flexDirection: "column",
          borderBottomLeftRadius: "21px",
          borderBottomRightRadius: "21px",
          padding: props.padding ? props.padding : "20px",
          textAlign: props.length ? "center" : "left",
          alignContent: props.length ? "center" : "left",
          justifyContent: props.length === 0 ? "center" : "top",
        }}
      >
        {props.children}
      </Box>
    </Box>
  );
};

export default ContainerAnalyst;
