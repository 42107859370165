import axios from "axios";
import authHeader from "./auth-header";
import { APP_URL } from "./http-common";

const URLEntityName = APP_URL + "reporting-unit";

const getAllUnits = (page, searchValue, limit, filterSelect) => {
  var valueUnit = "";
  var pageValue = page;
  if (searchValue !== "") {
    if (filterSelect) {
      valueUnit = "&search=status=ACTIVE&name=" + searchValue;
    } else {
      valueUnit = "&search=name=" + searchValue;
    }
  } else {
    if (filterSelect) {
      valueUnit = "&search=status=ACTIVE";
    } else {
      valueUnit = "";
    }
  }
  var valueLimit = "";
  if (limit !== "" && limit !== undefined) {
    pageValue = 0;
    valueLimit = "&size=" + limit;
  }

  return axios
    .get(URLEntityName + "?page=" + pageValue + valueUnit + valueLimit, {
      headers: authHeader(),
    })
    .then((response) => {
      return response.data;
    });
};

async function deleteUnit(id) {
  await axios
    .delete(URLEntityName + "/" + id, { headers: authHeader() })
    .then((response) => {
      if (
        response.status === 200 ||
        response.status === 201 ||
        response.status === 204
      ) {
        return response;
      }
      //return response;
    })
    .catch((err) => {
      return err;
    });
}

async function createUnit(unit) {
  await axios
    .post(URLEntityName, unit, {
      headers: authHeader(),
    })
    .then((response) => {
      return response;
    });
}

async function updateUnit(unit) {
  await axios
    .put(URLEntityName + "/" + unit.id, unit, {
      headers: authHeader(),
    })
    .then((response) => {
      return response;
    });
}
const njesiService = {
  getAllUnits,
  createUnit,
  deleteUnit,
  updateUnit,
};
export default njesiService;
