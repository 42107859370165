import * as React from "react";
import { CircularProgress, Backdrop } from "@mui/material";

const Progressbar = (props) => {
  return (
    <Backdrop sx={{ color: "#fff", zIndex: 99999 }} open={props.open}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default Progressbar;
