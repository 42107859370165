import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { CardActionArea, Divider, IconButton, Tooltip } from "@mui/material";
import { Box, textAlign } from "@mui/system";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import periodText from "../helper/PeriodText";
import { Edit } from "@mui/icons-material";
import { getCustomTextLength } from "../helper/CustomLabel";
import { useSelector } from "react-redux";

const FormCard = (props) => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);
  const isEditor = user?.role === "ROLE_EDITOR";
  const [dataValues, setDataValues] = React.useState(props.data);
  const openInputet = () => {
    navigate("/formgrid/inputet", {
      state: {
        stateFormInputId: dataValues.id,
        nameForm: dataValues.form?.name,
        company: dataValues?.company,
        formSubmissionState: dataValues?.status,
        stateForm: "new",
        variableSubmission: dataValues?.form?.variableSubmission,
      },
    });
  };

  React.useEffect(() => {
    setDataValues(props.data);
  }, [props.data]);

  return (
    <Tooltip
      title={dataValues?.form?.name.length > 50 ? dataValues?.form?.name : ""}
      arrow
    >
      <Card
        sx={{
          width: 337,
          boxShadow: "none",
          borderRadius: "15px",
          //#f0e9ff
          backgroundColor:
            dataValues.status === "ACTIVE"
              ? "#F6F8FC"
              : dataValues.status === "SUBMITTED"
              ? "#f0e9ff"
              : "#F8ECE8",

          //dataValues.status rxv  = "ACTIVE" ? "#F6F8FC" : "#F8ECE8",
          padding: 0,
          height: 260,
          m: 1,
        }}
      >
        <CardActionArea
          onClick={openInputet}
          // sx={{ ":hover": { backgroundColor: "#fbdcd2", boxShadow: 5 } }}
        >
          <CardContent
            sx={{
              height: 260,
              padding: 0,
              padding: "25px 30px 30px 25px",
              textAlign: "left",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <Typography sx={{ color: "#808EB1", fontSize: "16px" }}>
                Statusi:{" "}
              </Typography>
              <Typography
                sx={{
                  color:
                    dataValues.status === "ACTIVE"
                      ? "#45C8D0"
                      : dataValues.status === "SUBMITTED"
                      ? "#8C54FF"
                      : "#CD3728s",
                }}
              >
                &nbsp;
                {dataValues.status === "ACTIVE"
                  ? "Aktive"
                  : dataValues.status === "SUBMITTED"
                  ? "Plotësuar"
                  : "Draft"}
              </Typography>
            </Box>
            <Box sx={{ height: "110px" }}>
              <Box
                sx={{
                  maxHeight: "90px",
                  minHeight: "30px",
                  textAlign: "left",
                }}
              >
                <Typography
                  gutterBottom
                  variant="h5"
                  sx={{
                    fontWeight: 500,
                    color: "#091B3D",
                    fontSize: "25px",
                    lineHeight: "27px",
                    fontFamily: "Basier Circle Medium",
                  }}
                  component="div"
                >
                  {getCustomTextLength(dataValues.form.name, 60)}
                </Typography>
              </Box>
              {isEditor && (
                <Box>
                  <Typography
                    sx={{
                      color: "#808EB1",
                      fontSize: "16px",
                      marginTop: "2px",
                    }}
                    component="div"
                  >
                    Kompania:
                  </Typography>
                  <Typography
                    sx={{
                      color: "#545454",
                      fontSize: "22px",
                      fontFamily: "Basier Circle Medium",
                      fontWeight: 500,
                      marginBottom: "8px",
                    }}
                  >
                    {getCustomTextLength(dataValues?.company?.name, 60)}
                  </Typography>
                </Box>
              )}
              <Box
                sx={{
                  textAlign: "left",
                  width: "200px",
                  bottom: "20px",
                  alignContent: "center",
                  color: "#808EB1",
                  justifyContent: "center",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                    textAlign: "left",
                    alignSelf: "left",
                  }}
                >
                  <AccessTimeOutlinedIcon />
                  <Typography
                    variant="body"
                    sx={{ pl: "2px", justifyContent: "center" }}
                  >
                    Afati{" "}
                    {dataValues?.remainingDays > 1
                      ? " pas " + dataValues?.remainingDays + " ditësh"
                      : " Sot"}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                height: "80px",
                alignSelf: "flex-end",
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  pl: "2px",
                  display: "flex",
                  alignItems: "flex-start",
                  flexDirection: "column",
                  alignSelf: "flex-end",
                }}
              >
                <Typography variant="body" sx={{ color: "#808EB1" }}>
                  Periudha Raportuese:
                </Typography>
                <Typography variant="body">
                  {periodText(
                    dataValues?.scheduledStartDate,
                    dataValues?.scheduledEndDate,
                    dataValues?.monthly
                  )}
                  {/* Prill 2022 */}
                </Typography>
              </Box>
              <Box
                sx={{
                  pl: "2px",
                  display: "flex",
                  alignItems: "flex-end",
                  flexDirection: "row",
                  alignSelf: "flex-end",
                }}
              >
                <IconButton
                  disabled
                  sx={{
                    backgroundColor: "#CD3728 !important",
                    color: "#fff !important",
                    height: "42px",
                    width: "42px",
                  }}
                >
                  <Edit></Edit>
                </IconButton>
              </Box>
            </Box>
          </CardContent>
          <Divider light />
        </CardActionArea>
      </Card>
    </Tooltip>
  );
};

export default FormCard;
