import * as React from "react";
import {
  TextField,
  InputLabel,
  Button,
  Stack,
  InputAdornment,
} from "@mui/material";
import { Save } from "@mui/icons-material";
import ModalUI from "../UI/ModalUI";
import { useDispatch } from "react-redux";
import {
  createGraphDashboard,
  updateGraphDashboard,
} from "../../store/graphDashboard";
import { useNavigate, useLocation } from "react-router-dom";

const ChartModalEditor = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { data, isstatusnew } = props;
  const [state, setState] = React.useState({
    name: "",
    order: "",
  });
  const [errorValues, setErrorValues] = React.useState([]);

  React.useEffect(() => {
    setErrorValues([]);

    if (!isstatusnew) {
      setState({
        id: data.id ? data.id : "",
        name: data.name ? data.name : "",
        order: data.order ? data.order : "",
      });
    }
  }, [data.id, isstatusnew]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setState({ ...state, [name]: value ? value : "" });
  };

  const saveGraph = () => {
    var initialError = [];
    Object.keys(state).map(function (key, index) {
      if (state[key] !== "" || Object.keys(state[key]).length !== 0) {
      } else {
        initialError.push(key);
      }
    });

    setErrorValues(initialError);

    var initialErrorTemp = [];
    initialErrorTemp = initialError.filter((item) => item !== "id"); // remove id item

    if (initialErrorTemp.length === 0) {
      if (isstatusnew) {
        const { id, ...newUnit } = state;
        const sendData = {
          type: data.type.toUpperCase(),
          name: newUnit.name,
          order: newUnit.order,
          formInputIds: data.listFilter?.formInputIds?.map((item) => {
            return item.id;
          }),
        };

        dispatch(createGraphDashboard({ graph: sendData }));
        props.onClose();
      } else {
        const { id, ...newUnit } = state;
        const sendData = {
          type: data.type.toUpperCase(),
          name: newUnit.name,
          order: newUnit.order,
          formInputIds: data.listFilter?.formInputIds?.map((item) => {
            return item.id;
          }),
        };

        dispatch(updateGraphDashboard({ graph: sendData, id: state.id }));
        props.onClose();
        navigate(-1);
      }
    }
  };

  const checkError = (item) => {
    if (errorValues.find((element) => element === item)) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <ModalUI {...props}>
      <Stack spacing={2}>
        <TextField
          name="name"
          autoComplete="off"
          id="name"
          variant="outlined"
          error={checkError("name")}
          helperText={checkError("name") ? "Emri është i detyruar" : null}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">Emër</InputAdornment>
            ),
          }}
          value={state.name}
          onChange={handleInputChange}
        />
      </Stack>
      <Stack spacing={2}>
        <TextField
          name="order"
          autoComplete="off"
          id="order"
          variant="outlined"
          error={checkError("order")}
          helperText={checkError("order") ? "Rendi është i detyruar" : null}
          type="number"
          InputProps={{
            inputProps: { min: 0 },
            startAdornment: (
              <InputAdornment position="start">Rendi</InputAdornment>
            ),
          }}
          value={state.order}
          onChange={handleInputChange}
        />
      </Stack>
      <Stack sx={{ display: "flex", width: "100%" }}>
        <Button
          variant="contained"
          sx={{
            backgroundColor: "primary.dark",
            display: "flex",
            marginTop: "40px",
          }}
          startIcon={<Save />}
          onClick={saveGraph}
        >
          {isstatusnew ? "Shto" : "Ndrysho"}
        </Button>
      </Stack>
    </ModalUI>
  );
};
export default ChartModalEditor;
