import FormatePlotesuaraTable2 from "../components/FormatePlotesuara/FormatePlotesuaraTable2";
import StartingPageContent from "../components/StartingPage/StartingPageContent";
const FormatePlotesuara = () => {
  return (
    <StartingPageContent pageName="Forma të plotësuara">
      <FormatePlotesuaraTable2></FormatePlotesuaraTable2>
    </StartingPageContent>
  );
};

export default FormatePlotesuara;
