import axios from "axios";
import authHeader from "./auth-header";
import { APP_URL } from "./http-common";

const URLEntityName = APP_URL + "file";

async function uploadFile(fileData) {
  return axios
    .post(URLEntityName + "/upload", fileData, {
      headers: authHeader(),
    })
    .then((response) => {
      return response.data;
    });
}

async function loadFile(id) {
  return axios
    .post(URLEntityName + "/" + id, null, {
      headers: authHeader(),
      responseType: "blob",

      "Content-Type": "application/octet-stream",
    })
    .then((response) => {
      const { data, headers } = response;

      var filenameDoc;
      var filename = "";
      const disposition = headers["content-disposition"];
      if (disposition && disposition.indexOf("attachment") !== -1) {
        var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        var matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, "");
          filenameDoc = filename;
        }
      }

      const blobb = new Blob([response.data]);
      const url = window.URL.createObjectURL(blobb);

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filenameDoc);
      document.body.appendChild(link);
      link.click();

      return response;
    });
}

const uploadFileService = {
  uploadFile,
  loadFile,
};
export default uploadFileService;
