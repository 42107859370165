import axios from "axios";
import authHeader from "./auth-header";
import { APP_URL } from "./http-common";

const URLEntityName = APP_URL + "dashboard";

const getGraphData = () => {
  return axios
    .get(URLEntityName, {
      headers: authHeader(),
    })
    .then((response) => {
      return response.data;
    });
};

async function createGraph(graph) {
  return axios
    .post(URLEntityName, graph, {
      headers: authHeader(),
    })
    .then((response) => {
      return response;
    });
}
async function updateGraph(graph, id) {
  return axios
    .put(URLEntityName + "/" + id, graph, {
      headers: authHeader(),
    })
    .then((response) => {
      return response;
    });
}

async function getGraphById(id) {
  return axios
    .get(URLEntityName + "/" + id, { headers: authHeader() })
    .then((response) => {
      return response;
    });
}

async function deleteGraph(id) {
  return axios
    .delete(URLEntityName + "/" + id, { headers: authHeader() })
    .then((response) => {
      return response;
    });
}

const graphDashboardData = {
  getGraphData,
  createGraph,
  deleteGraph,
  getGraphById,
  updateGraph,
};
export default graphDashboardData;
