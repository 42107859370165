const CsvToJson = (csv) => {
  // Convert the data to String and
  // split it in an array
  const rows = csv.split("\n");
  let cols = rows.splice(0, 1)[0];
  cols = cols.split(",");

  const result = rows.map((row) => {
    const vals = row.split(",");
    return vals.reduce((res, val, idx) => {
      const prop = cols[idx];
      res[prop] = val;
      return res;
    }, {});
  });

  result.pop();

  return result;
};

export default CsvToJson;
