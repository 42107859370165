import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";
import { setLoading } from "./loading";
import FormSubmissionService from "../services/formSubmission.service";
const initialState = {
  formSubmissionDashboard: [],
  exportData: "",
  exportLogsData: "",
  formSubmissionAll: [],
  totalElements: 0,
  totalPages: 0,
  size: 0,
  pageCurrent: 0,
  formSubmissionSummary: {},
};
export const getFormSubmissionDashboard = createAsyncThunk(
  "form/getFormSubmissionDashboard",
  async (thunkAPI) => {
    //thunkAPI.dispatch(setLoading(true));
    try {
      const data = await FormSubmissionService.getFormSubmissionDashboard();
      if (data.length === undefined) {
        data = [];
      }
      //thunkAPI.dispatch(setLoading(false));
      return { formSubmissionDashboard: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // thunkAPI.dispatch(setLoading(false));
      //thunkAPI.dispatch(setMessage({ message: message }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getFormSubmissionAll = createAsyncThunk(
  "form/getFormSubmissionAll",
  async ({ page, searchValue }, thunkAPI) => {
    try {
      const data = await FormSubmissionService.getFormSubmissionAll(
        page,
        searchValue,
        10
      );
      //  thunkAPI.dispatch(setLoading(false));
      return {
        formSubmissionAll: data.content,
        totalElements: data.totalElements,
        totalPages: data.totalPages,
        size: data.size,
        pageCurrent: data.number,
      };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // thunkAPI.dispatch(setMessage(message));
      //  thunkAPI.dispatch(setLoading(false));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const submitFormSubmission = createAsyncThunk(
  "form/submitFormSubmission",
  async ({ formSubmission }, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    try {
      const data = await FormSubmissionService.submitFormSubmission(
        formSubmission
      );
      await thunkAPI.dispatch(getFormSubmissionDashboard());
      await thunkAPI.dispatch(getFormSubmissionSummary());
      thunkAPI.dispatch(setLoading(false));
      // return thunkAPI.rejectWithValue();
      return;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      await thunkAPI.dispatch(getFormSubmissionDashboard());
      thunkAPI.dispatch(setLoading(false));
      thunkAPI.dispatch(setMessage({ message: message }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const submitFormSubmissionOverride = createAsyncThunk(
  "form/submitFormSubmissionOverride",
  async ({ formSubmission }, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    try {
      const data = await FormSubmissionService.submitFormSubmissionOverride(
        formSubmission
      );
      await thunkAPI.dispatch(getFormSubmissionDashboard());
      await thunkAPI.dispatch(getFormSubmissionSummary());
      thunkAPI.dispatch(setLoading(false));
      // return thunkAPI.rejectWithValue();
      return;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      await thunkAPI.dispatch(getFormSubmissionDashboard());
      thunkAPI.dispatch(setLoading(false));
      thunkAPI.dispatch(setMessage({ message: message }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getFormSubmissionSummary = createAsyncThunk(
  "form/getFormSubmissionSummary",
  async (thunkAPI) => {
    // thunkAPI.dispatch(setLoading(true));
    try {
      const data = await FormSubmissionService.getFormSubmissionSummary();
      // thunkAPI.dispatch(setLoading(false));
      return { formSubmissionSummary: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // thunkAPI.dispatch(setMessage(message));
      // thunkAPI.dispatch(setLoading(false));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getFormSubmissionExportData = createAsyncThunk(
  "form/getFormSubmissionExportData",
  async ({ dataFilter }, thunkAPI) => {
    // thunkAPI.dispatch(setLoading(true));
    try {
      const data = await FormSubmissionService.getFormSubmissionExport(
        dataFilter
      );
      // thunkAPI.dispatch(setLoading(false));
      return { exportData: data.data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // thunkAPI.dispatch(setMessage(message));
      // thunkAPI.dispatch(setLoading(false));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const clearFormSubmissionExportData = createAsyncThunk(
  "form/clearFormSubmissionExportData",
  async (thunkAPI) => {
    return { exportData: "" };
  }
);

export const getFormSubmissionLogsExportData = createAsyncThunk(
  "form/getFormSubmissionLogsExportData",
  async ({ id }, thunkAPI) => {
    // thunkAPI.dispatch(setLoading(true));
    try {
      const data = await FormSubmissionService.getFormSubmissionLogsExport(id);
      // thunkAPI.dispatch(setLoading(false));
      return { exportLogsData: data.data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // thunkAPI.dispatch(setMessage(message));
      // thunkAPI.dispatch(setLoading(false));
      return thunkAPI.rejectWithValue();
    }
  }
);

const formSubmissionSlice = createSlice({
  name: "formSubmission",
  initialState,
  extraReducers: {
    [getFormSubmissionDashboard.fulfilled]: (state, action) => {
      state.formSubmissionDashboard = action.payload.formSubmissionDashboard;
    },
    [getFormSubmissionDashboard.rejected]: (state, action) => {
      state.formSubmissionDashboard = [];
    },
    [getFormSubmissionAll.fulfilled]: (state, action) => {
      state.formSubmissionAll = action.payload.formSubmissionAll;
      state.totalElements = action.payload.totalElements;
      state.totalPages = action.payload.totalPages;
      state.size = action.payload.size;
      state.pageCurrent = action.payload.pageCurrent;
    },
    [getFormSubmissionAll.rejected]: (state, action) => {
      state.formSubmissionAll = [];
      state.totalElements = 0;
      state.totalPages = 0;
      state.size = 0;
    },
    [getFormSubmissionExportData.fulfilled]: (state, action) => {
      state.exportData = action.payload.exportData;
    },
    [getFormSubmissionExportData.rejected]: (state, action) => {
      state.exportData = "";
    },

    [getFormSubmissionLogsExportData.fulfilled]: (state, action) => {
      state.exportLogsData = action.payload.exportLogsData;
    },
    [getFormSubmissionLogsExportData.rejected]: (state, action) => {
      state.exportLogsData = "";
    },

    [getFormSubmissionSummary.fulfilled]: (state, action) => {
      state.formSubmissionSummary = action.payload.formSubmissionSummary;
    },
    [getFormSubmissionSummary.rejected]: (state, action) => {
      state.formSubmissionSummary = {};
    },
    [clearFormSubmissionExportData.fulfilled]: (state, action) => {
      state.exportData = "";
    },
  },
});

const { reducer } = formSubmissionSlice;
export default reducer;
