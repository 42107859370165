import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  backState: { formSubmission: false, formTemplate: false },
};
const backStateSlice = createSlice({
  name: "backState",
  initialState,
  reducers: {
    setBackState: (state, action) => {
      return { backState: action.payload };
    },
  },
});
const { reducer, actions } = backStateSlice;
export const { setBackState } = actions;
export default reducer;
