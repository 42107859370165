import * as React from "react";
import { Box } from "@mui/material";

const BoxRowButtonsIcon = (props) => {
  return (
    <Box
      sx={{
        display: "flex",
        marginRight: "30px",
        justifyContent: "flex-end",
        align: "right",
      }}
    >
      {props.children}
    </Box>
  );
};

export default BoxRowButtonsIcon;
