import axios from "axios";
import authHeader from "./auth-header";
import { APP_URL } from "./http-common";

const URLEntityName = APP_URL + "users";

const getAllUsers = (page, searchValue, status) => {
  var valueUser = "";
  var valueStatus = "";
  if (searchValue !== "") {
    valueUser = "&search=username=" + searchValue;
  }
  if (status === "all") {
    valueStatus = "";
  }
  if (searchValue === "" && status !== "all") {
    valueStatus = "&search=status=" + status;
  }
  if (searchValue !== "" && status !== "all") {
    valueStatus = "%26status=" + status;
  }
  return axios
    .get(URLEntityName + "?page=" + page + valueUser + valueStatus, {
      headers: authHeader(),
    })
    .then((response) => {
      if (response.data) {
      }
      return response.data;
    });
};

const getFilterUsersUsername = (username) => {
  return axios
    .get(URLEntityName + "?search=username=" + username, {
      headers: authHeader(),
    })
    .then((response) => {
      if (response.data) {
      }
      return response.data;
    });
};

async function deleteUser(id) {
  await axios
    .delete(URLEntityName + "/" + id, { headers: authHeader() })
    .then((response) => {
      return response;
    });
}
async function createUser(user) {
  await axios
    .post(URLEntityName, user, {
      headers: authHeader(),
    })
    .then((response) => {
      return response;
    });
}

async function updateUser(user) {
  await axios
    .put(URLEntityName + "/" + user.id, user, {
      headers: authHeader(),
    })
    .then((response) => {
      return response;
    });
}

async function updateToGlobalEditor(user) {
  await axios.put(
    URLEntityName + "/" + user.id + "/global-editor",
    { id: user.id, isGlobalEditor: user.isGlobalEditor },
    {
      headers: authHeader(),
    }
  );
}
const perdoruesService = {
  getAllUsers,
  createUser,
  deleteUser,
  updateUser,
  getFilterUsersUsername,
  updateToGlobalEditor,
};
export default perdoruesService;
