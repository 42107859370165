import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";

import authReducer from "./auth";
import perdoruesReducer from "./perdorues";
import formaReducer from "./forma";
import formTemplateReducer from "./formTemplate";
import njesiRaportueseReducer from "./njesiRaportuese";
import messageReducer from "./message";
import loadingReducer from "./loading";
import formInputesReducer from "./formInputes";
import formSubmissionReducer from "./formSubmission";
import formInputesSubmissionReducer from "./formInputesSubmission";
import graphDataReducer from "./graphData";
import graphDashboardReducer from "./graphDashboard";
import uplaodFileReducer from "./uploadFile";
import backStateReducer from "./backState";
import formSubmisionFilterReducer from "./formSubmisionFilter";
import companyReducer from "./company";
import productReducer from "./product";
import formCategoryReducer from "./formCategory";

const reducer = {
  auth: authReducer,
  perdorues: perdoruesReducer,
  forma: formaReducer,
  formTemplate: formTemplateReducer,
  njesiRaportuese: njesiRaportueseReducer,
  message: messageReducer,
  loading: loadingReducer,
  formInputesById: formInputesReducer,
  formSubmission: formSubmissionReducer,
  formInputesSubmission: formInputesSubmissionReducer,
  graphData: graphDataReducer,
  graphDashboard: graphDashboardReducer,
  uplaodFile: uplaodFileReducer,
  backState: backStateReducer,
  formSubmisionFilter: formSubmisionFilterReducer,
  company: companyReducer,
  product: productReducer,
  formCategory: formCategoryReducer,
};

const store = configureStore({
  reducer: reducer,
  devTools: true,
  middleware: getDefaultMiddleware({
    serializableCheck: false,
  }),
});

export default store;
