import Grid from "../components/Grid/Grid";
import StartingPageContent from "../components/StartingPage/StartingPageContent";
const Inpute = () => {
  return (
    <StartingPageContent pageName="Inpute">
      <Grid></Grid>
    </StartingPageContent>
  );
};

export default Inpute;
