import * as React from "react";
import {
  TextField,
  Button,
  Stack,
  InputAdornment,
  MenuItem,
} from "@mui/material";
import { Save } from "@mui/icons-material";
import ModalUI from "../UI/ModalUI";
import { useDispatch, useSelector } from "react-redux";
import {
  createUnit,
  getAllUnit,
  updateUnit,
} from "../../store/njesiRaportuese";
import { createPrice, getPrice, getProduct } from "../../store/product";

const ProduktModalEditor = (props) => {
  const dispatch = useDispatch();
  // const { currentPage, searchValueCurrent } = useSelector(
  //   (state) => state.njesiRaportuese
  // );
  const { currentPage, searchValueCurrent, products } = useSelector(
    (state) => state.product
  );

  const { data, isstatusnew, open } = props;
  const [state, setState] = React.useState({
    productId: "",
    price: "",
  });
  const [errorValues, setErrorValues] = React.useState([]);
  React.useEffect(() => {
    dispatch(getProduct({ page: 0, searchValue: "" }));
  }, [open]);

  React.useEffect(() => {
    setErrorValues([]);
    setState({
      productId: data.id ? data.id : "",
      price: data.price ? data.price : "",
    });
  }, [data, open]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setState({ ...state, [name]: value ? value : "" });
  };

  const saveUser = () => {
    var initialError = [];
    Object.keys(state).map(function (key, index) {
      if (state[key] !== "" || Object.keys(state[key]).length !== 0) {
      } else {
        initialError.push(key);
      }
    });

    setErrorValues(initialError);

    var initialErrorTemp = [];
    initialErrorTemp = initialError.filter((item) => item !== "id"); // remove id item

    if (initialErrorTemp.length === 0) {
      if (isstatusnew) {
        const { id, ...newPrice } = state;
        dispatch(createPrice({ price: newPrice }));
        dispatch(getPrice({ page: 0, searchValue: "" }));
      } else {
        const { ...updateUnitTemp } = state;
        dispatch(
          updateUnit({
            unit: updateUnitTemp,
            currentPage: currentPage,
            searchValueCurrent: searchValueCurrent,
          })
        );
      }

      dispatch(getPrice({ page: 0, searchValue: "" }));
      props.onClose();
    }

    // if (initialErrorTemp.length === 0) {
    //   if (isstatusnew) {
    //     const { id, ...newUnit } = state;
    //     dispatch(createUnit({ unit: newUnit }));
    //   } else {
    //     const { ...updateUnitTemp } = state;
    //     dispatch(
    //       updateUnit({
    //         unit: updateUnitTemp,
    //         currentPage: currentPage,
    //         searchValueCurrent: searchValueCurrent,
    //       })
    //     );
    //   }
    //   dispatch(
    //     getAllUnit({ page: currentPage, searchValue: searchValueCurrent })
    //   );
    //   props.onClose();
    // }
  };
  const changeProductId = (e) => {
    setState((prevState) => {
      return {
        ...prevState,
        productId: e.target.value,
      };
    });
  };

  const checkError = (item) => {
    if (errorValues.find((element) => element === item)) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <ModalUI {...props}>
      {/* <Stack spacing={2}>
        <TextField
          name="name"
          autoComplete="off"
          id="name"
          variant="outlined"
          error={checkError("name")}
          helperText={checkError("name") ? "Emri është i detyruar" : null}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">Emër</InputAdornment>
            ),
          }}
          value={state.name}
          onChange={handleInputChange}
        />
      </Stack> */}
      <Stack spacing={2}>
        {/* <TextField
          name="name"
          autoComplete="off"
          id="name"
          variant="outlined"
          error={checkError("name")}
          helperText={checkError("name") ? "Emri është i detyruar" : null}
          InputProps={{
            startAdornment: (
              <InputAdornment
                sx={{
                  "&.MuiInputAdornment-root.MuiInputAdornment-root": {
                    minWidth: "0px",
                  },
                }}
                position="start"
              >
                Emër
              </InputAdornment>
            ),
          }}
          value={state.name}
          onChange={handleInputChange}
        /> */}

        <TextField
          labelId="demo-simple-select-label"
          select
          name="productId"
          id="productId"
          error={checkError("productId")}
          helperText={
            checkError("productId") ? "Emri i produktit është i detyruar" : null
          }
          value={state.productId ? state.productId : null}
          onChange={changeProductId}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">Emër</InputAdornment>
            ),
          }}
        >
          {products?.map((item) => {
            return (
              <MenuItem key={item.id} value={item.id}>
                {item.name}
              </MenuItem>
            );
          })}
        </TextField>
      </Stack>
      <Stack spacing={2}>
        <TextField
          name="price"
          autoComplete="off"
          id="price"
          variant="outlined"
          error={checkError("price")}
          helperText={checkError("price") ? "Vlera është i detyruar" : null}
          InputProps={{
            startAdornment: (
              <InputAdornment
                sx={{
                  "&.MuiInputAdornment-root.MuiInputAdornment-root": {
                    minWidth: "0px",
                  },
                }}
                position="start"
              >
                Vlera
              </InputAdornment>
            ),
          }}
          value={state.price}
          onChange={handleInputChange}
        />
      </Stack>
      <Stack sx={{ display: "flex", width: "100%" }}>
        <Button
          variant="contained"
          sx={{
            backgroundColor: "primary.dark",
            display: "flex",
            marginTop: "40px",
          }}
          startIcon={<Save />}
          onClick={saveUser}
        >
          {isstatusnew ? "Shto" : "Ndrysho"}
        </Button>
      </Stack>
    </ModalUI>
  );
};
export default ProduktModalEditor;
