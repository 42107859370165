import axios from "axios";
import authHeader from "./auth-header";
import { APP_URL } from "./http-common";

const URLEntityName = APP_URL + "company";

const getCompanyById = (id) => {
  return axios
    .get(URLEntityName + "/" + id, { headers: authHeader() })
    .then((response) => {
      return response.data;
    });
};

async function updateCompany(company) {
  await axios
    .put(URLEntityName + "/" + company.id, company, {
      headers: authHeader(),
    })
    .then((response) => {
      return response;
    });
}

const CompanyService = {
  updateCompany,
  getCompanyById,
};
export default CompanyService;
