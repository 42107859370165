import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";
import AuthService from "../services/auth.service";
import { setLoading } from "./loading";
const user = JSON.parse(localStorage.getItem("userData"));

export const register = createAsyncThunk(
  "auth/register",
  async ({ username, email, password }, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    try {
      const response = await AuthService.register(username, email, password);
      thunkAPI.dispatch(setLoading(false));
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setLoading(false));
      thunkAPI.dispatch(setMessage({ message: message }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const registerCompany = createAsyncThunk(
  "auth/registerCompany",
  async ({ data }, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    try {
      const response = await AuthService.registerCompany(data);
      thunkAPI.dispatch(setLoading(false));
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setLoading(false));
      thunkAPI.dispatch(setMessage({ message: message }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const login = createAsyncThunk(
  "auth/login",
  async ({ username, password }, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    try {
      const data = await AuthService.login(username, password);
      //thunkAPI.dispatch(setMessage({ message: "Status 200" }));
      thunkAPI.dispatch(setLoading(false));
      return { user: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      error.toString();
      thunkAPI.dispatch(setLoading(false));
      thunkAPI.dispatch(setMessage({ message: message }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const me = createAsyncThunk("auth/me", async (thunkAPI) => {
  try {
    const data = await AuthService.me();
    const userValue = JSON.parse(localStorage.getItem("userData"));
    localStorage.setItem("userData", JSON.stringify({ ...userValue, ...data }));
    return { user: { ...userValue, ...data } };
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    thunkAPI.dispatch(setMessage({ message: message }));
    return thunkAPI.rejectWithValue();
  }
});
export const logout = createAsyncThunk("auth/logout", async () => {
  await AuthService.logout();
});

export const resetPassword = createAsyncThunk(
  "auth/reset",
  async ({ passwordData }, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    try {
      const data = await AuthService.resetUserPassword(passwordData);
      thunkAPI.dispatch(logout());
      thunkAPI.dispatch(setLoading(false));
      thunkAPI.dispatch(
        setMessage({ message: "Fjalekalimi ndryshoi me sukses!" })
      );
      return thunkAPI.rejectWithValue();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setLoading(false));
      thunkAPI.dispatch(setMessage({ message: message }));
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState = user
  ? { isLoggedIn: true, user }
  : { isLoggedIn: false, user: null };
const authSlice = createSlice({
  name: "auth",
  initialState,
  extraReducers: {
    [register.fulfilled]: (state, action) => {
      state.isLoggedIn = false;
    },
    [register.rejected]: (state, action) => {
      state.isLoggedIn = false;
    },
    [registerCompany.fulfilled]: (state, action) => {
      state.isLoggedIn = false;
    },
    [registerCompany.rejected]: (state, action) => {
      state.isLoggedIn = false;
    },
    [login.fulfilled]: (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload.user;
    },
    [login.rejected]: (state, action) => {
      state.isLoggedIn = false;
      state.user = null;
    },
    [logout.fulfilled]: (state, action) => {
      state.isLoggedIn = false;
      state.user = null;
    },
    [me.fulfilled]: (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload.user;
    },
    [me.rejected]: (state, action) => {
      state.isLoggedIn = false;
      state.user = null;
    },
  },
});
const { reducer } = authSlice;
export default reducer;
