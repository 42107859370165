import * as React from "react";
import { Stack, DialogTitle } from "@mui/material";
import { Add } from "@mui/icons-material";
import ModalUI from "../UI/ModalUI";
import { useDispatch, useSelector } from "react-redux";
import {
  cleanFormTemplateSearch,
  getAllFormTemplateSearch,
} from "../../store/formTemplate";
import gridLabels from "../Labels/GridLabels";
import { DataGrid } from "@mui/x-data-grid";
import SearchInput from "../UI/SearchInput";
import { setLoading } from "../../store/loading";
import BoxRowButtonsIcon from "../UI/BoxRowButtonsIcon";
import RowButtonIcon from "../UI/RowButtonIcon";

const SearchModal = (props) => {
  const dispatch = useDispatch();
  const { formTemplate, searchValueCurrent } = useSelector(
    (state) => state.formTemplate
  );
  const { open } = props;
  const searchState = React.useRef("");
  const [searchValueServer, setSearchValueServer] =
    React.useState(searchValueCurrent);

  const searchItems = (e) => {
    const searchValue = e.target.value;
    if (searchValue !== "") {
      searchState.current.value = searchValue;
      let filterTimeout;

      filterTimeout = setTimeout(() => {
        setSearchValueServer(searchState?.current?.value);
      }, 1000);
    } else {
      dispatch(cleanFormTemplateSearch());
    }
  };

  React.useEffect(() => {
    if (open === false) {
      dispatch(cleanFormTemplateSearch());
    } else {
      if (searchValueServer !== "" && open === true) {
        getAllDataFormSearch();
      } else {
        dispatch(cleanFormTemplateSearch());
      }
    }
  }, [open, searchValueServer]);

  const getAllDataFormSearch = () => {
    dispatch(setLoading(true));
    dispatch(
      getAllFormTemplateSearch({
        searchValue: searchValueServer,
      })
    )
      .unwrap()
      .then(() => {
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };

  return (
    <ModalUI width="800px" {...props}>
      <DialogTitle
        id="alert-dialog-title"
        sx={{
          "&.MuiTypography-root.MuiDialogTitle-root": {
            padding: 0,
            textAlign: "center",
          },
          marginTop: "-30px",
          fontSize: "30px",
          color: "#091B3D",
          fontFamily: "Basier Circle Medium",
        }}
      >
        Kërko Formë
      </DialogTitle>
      <Stack spacing={2}>
        <SearchInput
          autoComplete="off"
          variant="outlined"
          onChange={searchItems}
          //   onchangesearch={searchItems}
          inputRef={searchState}
          defaultValue={searchState?.current?.value}
          sx={{
            height: "50px",
            "& .MuiInputBase-input.MuiOutlinedInput-input": {
              width: "100%",
              maxWidth: "100%",
            },
            "&.MuiFormControl-root.MuiTextField-root": {
              width: "100%",
            },
            "& .MuiInputBase-root.MuiOutlinedInput-root": {
              borderRadius: "12px",
              height: "50px",
            },
          }}
        />
      </Stack>
      <div style={{ height: 500, width: "100%" }}>
        <DataGrid
          labelRowsPerPage="Artikuj per faqe"
          disableSelectionOnClick
          columns={[
            {
              field: "name",
              headerName: "Emër",
              width: 450,
              minWidth: 400,
              flex: 1,
              editable: false,
            },
            {
              field: "",
              headerName: "",
              width: 50,
              minWidth: 50,
              overflow: "hidden",
              editable: false,
              flex: 1,
              justifyContent: "flex-end",
              disableClickEventBubbling: true,
              align: "right",
              background: "red",
              headers: false,
              renderCell: (params) => (
                <BoxRowButtonsIcon>
                  <RowButtonIcon
                    onClick={(event) => {
                      props.onSelectForm(params.row);
                    }}
                  >
                    <Add />
                  </RowButtonIcon>
                </BoxRowButtonsIcon>
              ),
            },
          ]}
          rows={formTemplate}
          density="comfortable"
          editMode="row"
          disableColumnMenu
          localeText={gridLabels}
          hideFooterPagination
          sx={{
            "&.MuiDataGrid-root .MuiDataGrid-columnHeader--moving": {
              backgroundColor: "transparent",
            },
          }}
        />
      </div>
    </ModalUI>
  );
};
export default SearchModal;
