import { createSlice } from "@reduxjs/toolkit";
import { FORMAT } from "../data/dummy-data";

const initialFormaState = FORMAT;

const formaSlice = createSlice({
  name: "forma",
  initialState: initialFormaState,
  reducers: {
    addForma(state, action) {
      const valueNew = action.payload.forma;
      state = state.push(valueNew);
    },
    updateForma(state, action) {
      var objIndex;
      state[objIndex] = action.payload;
      return state;
    },
    deleteForma(state, action) {
      var objIndex;
      state[objIndex] = action.payload;
      return state;
    },
  },
});

export const formaActions = formaSlice.actions;
export default formaSlice.reducer;
