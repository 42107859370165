import Grid from "../components/Grid/Grid";
import NjesiRaportueseTable from "../components/NjesiRaportuese/NjesiRaportueseTable";
import StartingPageContent from "../components/StartingPage/StartingPageContent";
const NjesiRaportuese = () => {
  return (
    <StartingPageContent pageName="Drejtoritë">
      <NjesiRaportueseTable></NjesiRaportueseTable>
    </StartingPageContent>
  );
};

export default NjesiRaportuese;
