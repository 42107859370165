import ProduktTable from "../components/Produkt/ProduktTable";
import StartingPageContent from "../components/StartingPage/StartingPageContent";
const ProduktPage = () => {
  return (
    <StartingPageContent pageName="Produkt">
      <ProduktTable></ProduktTable>
    </StartingPageContent>
  );
};

export default ProduktPage;
