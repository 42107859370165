import * as React from "react";
import { Autocomplete, InputAdornment, TextField } from "@mui/material";
import { Box } from "@mui/system";
import PropTypes from "prop-types";
import CorporateFareOutlinedIcon from "@mui/icons-material/CorporateFareOutlined";

const TextFieldDateFilter = (props) => {
  return (
    <TextField
      autoComplete="off"
      placeholder={props.placeholder}
      sx={{
        height: props?.height ? props?.height : "50px",
        "& .Mui-disabled": {
          "fieldset ": {
            //borderColor: "#72aeff !important",
            borderColor: "white !important",
            minWidth: props?.maxWidth ? props?.maxWidth : "250px",
            maxWidth: props?.maxWidth ? props?.maxWidth : "250px",
          },
          "& .MuiInputBase-root.MuiOutlinedInput-root": {
            // borderColor: "#72aeff !important",
            borderColor: "white !important",
            //backgroundColor: props.disabledfilter ? "#f5f8fc" : "white",
            backgroundColor: props.disabledfilter ? "#a0a7b4" : "#a0a7b4",
            borderRadius: "12px",
            height: props?.height ? props?.height : "50px",
          },
        },
        "& .MuiInputBase-root.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline":
          {
            borderColor: "white",
          },

        "& .Mui-disabled": {
          "fieldset ": {
            // borderColor: "#72aeff !important",
            borderColor: "white !important",
          },
        },
        "& .MuiInputBase-root.MuiOutlinedInput-root": {
          // borderColor: "#72aeff !important",
          borderColor: "white !important",
          //backgroundColor: props.disabledfilter ? "#f5f8fc" : "white",
          backgroundColor: props.disabledfilter ? "#f5f8fc" : "#f5f8fc",
          borderRadius: "12px",
          height: props?.height ? props?.height : "50px",
        },
        "& .MuiInputBase-input.MuiOutlinedInput-input": {
          minWidth: props?.inputWidth ? props?.inputWidth : "180px",
          maxWidth: "180px",
        },
        "& .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input.Mui-disabled":
          {
            WebkitTextFillColor: props.disabledfilter
              ? "#a0a7b4 !important"
              : "#091B3D !important",
          },

        "&.MuiTextField-root": {
          paddingLeft: 0,
          paddingRight: 0,
          minWidth: props?.maxWidth ? props?.maxWidth : "250px",
          maxWidth: props?.maxWidth ? props?.maxWidth : "250px",
        },
        "& fieldset": {
          top: 0,
          minWidth: props?.maxWidth ? props?.maxWidth : "250px",
          maxWidth: props?.maxWidth ? props?.maxWidth : "250px",
          // borderColor: "#72aeff !important",
        },
        "& legend": {
          display: "none",
        },
      }}
      InputProps={{
        startAdornment: null,
      }}
      {...props}
    ></TextField>
  );
};

export default TextFieldDateFilter;
