import * as React from "react";
import { Fragment, useEffect, useState, useCallback } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import MenuIcon from "@mui/icons-material/Menu";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import LogoutIcon from "@mui/icons-material/Logout";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { logout } from "../../store/auth";
import Main from "./Main";
import ArchiveOutlinedIcon from "@mui/icons-material/ArchiveOutlined";
import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import CorporateFareOutlinedIcon from "@mui/icons-material/CorporateFareOutlined";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import {
  Button,
  ClickAwayListener,
  InputAdornment,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import EreLogo from "../../image/ere_logo.jpeg";
import DialogDelete from "../UI/DialogDelete";
import LockResetIcon from "@mui/icons-material/LockReset";
import ModalUI from "../UI/ModalUI";
import ResetPassword from "../Perdorues/ResetPassword";
import BarChartIcon from "@mui/icons-material/BarChart";
import { setBackState } from "../../store/backState";

export default function DrawerLeft(props) {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const navigate = useNavigate();
  const location = useLocation();
  const user = useSelector((state) => state.auth.user);
  const [stateDialoglogout, setStateDialogout] = React.useState(false);
  const [stateDialogreset, setStateDialogreset] = React.useState(false);
  const { backState } = useSelector((state) => state.backState);

  const itemListEkzekutive = [
    {
      label: "Forma të plotësuara",
      value: "formatePlotesuara",
      icon: <ArchiveOutlinedIcon />,
    },
    {
      label: "Modelet e Formave",
      value: "formTemplate",
      icon: <ArticleOutlinedIcon />,
    },
    {
      label: "Kategoritë e Formave",
      value: "formCategory",
      icon: <CategoryOutlinedIcon />,
    },
    {
      label: "Drejtoritë",
      value: "njesiRaportuese",
      icon: <CorporateFareOutlinedIcon />,
    },
    {
      label: "List Produktesh",
      value: "produktList",
      icon: <ListAltOutlinedIcon />,
    },
    { label: "Përdorues", value: "perdorues", icon: <PeopleOutlineIcon /> },
  ];

  const itemListSoftEkzekutive = [
    {
      label: "Forma të plotësuara",
      value: "formatePlotesuara",
      icon: <ArchiveOutlinedIcon />,
    },
    {
      label: "Raporte / Grafikë",
      value: "dashboard",
      icon: <GridViewOutlinedIcon />,
    },
    {
      label: "Gjenero Raporte",
      value: "raporte",
      icon: <BarChartIcon />,
    },
  ];

  const itemListMinorEkzekutive = [
    {
      label: "Gjenero Raporte",
      value: "raporte",
      icon: <BarChartIcon />,
    },
    {
      label: "Forma të plotësuara",
      value: "formatePlotesuara",
      icon: <ArchiveOutlinedIcon />,
    },
    {
      label: "Eksport",
      value: "export",
      icon: <FileDownloadIcon />,
    },
  ];

  const itemListAnalyst = [
    {
      label: "Raporte / Grafikë",
      value: "dashboard",
      icon: <GridViewOutlinedIcon />,
    },
    {
      label: "Gjenero Raporte",
      value: "raporte",
      icon: <BarChartIcon />,
    },
    {
      label: "Forma të plotësuara",
      value: "formatePlotesuara",
      icon: <ArchiveOutlinedIcon />,
    },
    {
      label: "Eksport",
      value: "export",
      icon: <FileDownloadIcon />,
    },
    { label: "Përdorues", value: "perdorues", icon: <PeopleOutlineIcon /> },
  ];

  const itemListExtra = [
    {
      label: "Modelet e Formave",
      value: "formTemplate",
      icon: <ArticleOutlinedIcon />,
    },
    // {
    //   label: "Njësi Raportuese",
    //   value: "njesiRaportuese",
    //   icon: <CorporateFareOutlinedIcon />,
    // },
    { label: "Përdorues", value: "perdorues", icon: <PeopleOutlineIcon /> },
  ];

  const itemListOperatorEditor = [
    {
      label: "Plotëso Formën",
      value: "dashboard",
      icon: <GridViewOutlinedIcon />,
    },
    {
      label: "Produkt",
      value: "produkt",
      icon: <ElectricBoltIcon />,
    },
    // {
    //   label: "Gjenero Raporte",
    //   value: "raporte",
    //   icon: <BarChartIcon />,
    // },
    // {
    //   label: "Forma të plotësuara",
    //   value: "formatePlotesuara",
    //   icon: <ArchiveOutlinedIcon />,
    // },
    // {
    //   label: "Eksport",
    //   value: "export",
    //   icon: <FileDownloadIcon />,
    // },
    {
      label: "Profil",
      value: "profile",
      icon: <PersonOutlineIcon />,
    },
    ...(user ? (user?.role === "ROLE_EXECUTIVE" ? itemListExtra : []) : []),
  ];

  const itemProductNav = user?.reportingUnit?.priceApproval
    ? [
        {
          label: "Produkt",
          value: "produkt",
          icon: <ElectricBoltIcon />,
        },
      ]
    : [];

  const itemListEditor = [
    {
      label: "Plotëso Formën",
      value: "dashboard",
      icon: <GridViewOutlinedIcon />,
    },
    ...itemProductNav,
    {
      label: "Gjenero Raporte",
      value: "raporte",
      icon: <BarChartIcon />,
    },
    {
      label: "Forma të plotësuara",
      value: "formatePlotesuara",
      icon: <ArchiveOutlinedIcon />,
    },
    {
      label: "Eksport",
      value: "export",
      icon: <FileDownloadIcon />,
    },
    ...(user ? (user?.role === "ROLE_EXECUTIVE" ? itemListExtra : []) : []),
  ];

  const itemListNavBar = user
    ? user?.role === "ROLE_EXECUTIVE"
      ? itemListEkzekutive
      : user?.role === "ROLE_SOFT_EXECUTIVE"
      ? itemListSoftEkzekutive
      : user?.role === "ROLE_ANALYST"
      ? itemListAnalyst
      : user?.role === "ROLE_MINOR_EXECUTIVE"
      ? itemListMinorEkzekutive
      : user?.role === "ROLE_EDITOR"
      ? itemListEditor
      : itemListOperatorEditor
    : [];

  const [selectedIndex, setSelectedIndex] = React.useState(0);

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/auth");
    }
    checkUrl(location.pathname);
  }, [isLoggedIn, location.pathname]);

  const openEntity = (item, index) => {
    clearModal();
    dispatch(setBackState({ formSubmission: true, formTemplate: true }));
    // handleDrawerOpen();
    setSelectedIndex(index);
    if (item?.value === "formatePlotesuara") {
      dispatch(setBackState({ formSubmission: false, formTemplate: true }));
    }
    if (item?.value === "formTemplate") {
      dispatch(setBackState({ formSubmission: true, formTemplate: false }));
    }

    if (isLoggedIn) {
      navigate("/" + item.value);
    }
  };
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    clearModal();
    setOpen(!open);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleClickLogout = (e) => {
    setStateDialogreset(false);
    setStateDialogout(true);
  };

  const handleClickResetPassword = (e) => {
    setStateDialogout(false);
    setStateDialogreset(true);
  };

  const handleCloseDialogLogout = (e) => {
    setStateDialogout(false);
  };

  const handleCloseDialogreset = (e) => {
    setStateDialogreset(false);
  };
  const logOut = useCallback(() => {
    dispatch(logout());
    setStateDialogout(false);
  }, [dispatch]);

  const clearModal = () => {
    setStateDialogout(false);
    setStateDialogreset(false);
  };

  const checkUrl = (url) => {
    const urlTemp = url.split("/")[1];
    itemListNavBar.map((item, index) => {
      if (item.value === urlTemp) {
        setSelectedIndex(index);
        handleDrawerClose();
      }
    });
  };

  const getManualPath = () => {};

  const drawerWidth = open ? 330 : 100;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      {isLoggedIn && location?.pathname !== "/auth" ? (
        <Drawer
          variant="permanent"
          sx={{
            width: drawerWidth,
            backgroundColor: "primary",
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: {
              width: drawerWidth,
              backgroundColor: "#008fc6",
              color: "white",
              boxSizing: "border-box",
            },
          }}
        >
          {" "}
          <ClickAwayListener onClickAway={handleDrawerClose}>
            <div
            //onMouseOver={() => setOpen(true)}
            //onMouseOut={() => setOpen(false)}
            >
              <List>
                <ListItem
                  button
                  onClick={handleDrawerOpen}
                  sx={{
                    ":hover": { backgroundColor: "transparent !important" },
                  }}
                  key={111}
                >
                  <ListItemIcon sx={{ color: "white", width: open ? 100 : 0 }}>
                    <MenuIcon onClick={handleDrawerOpen} />
                  </ListItemIcon>
                </ListItem>
                <Toolbar sx={{ minHeight: "5px !important" }} />

                {itemListNavBar.map((item, index) => (
                  <Tooltip
                    key={Math.floor(Math.random() * 100000)}
                    title={item.label}
                    arrow
                    placement="right"
                    sx={{ zIndex: "30 !important" }}
                  >
                    <div key={Math.floor(Math.random() * 100000)}>
                      <ListItem
                        selected={selectedIndex === index}
                        button
                        key={Math.floor(Math.random() * 100000)}
                        onClick={() => openEntity(item, index)}
                      >
                        <ListItemIcon
                          key={Math.floor(Math.random() * 100000)}
                          sx={{ color: "white", width: 20 }}
                        >
                          {item.icon}
                        </ListItemIcon>
                        <ListItemText
                          key={Math.floor(Math.random() * 100000)}
                          sx={{
                            "& .MuiTypography-root": {
                              fontSize: "20px",
                              fontWeight: 400,
                              fontFamily: "Basier Circle",
                              color: "#F9F9FC",
                            },
                          }}
                          primary={open ? item.label : ""}
                        />
                      </ListItem>
                    </div>
                  </Tooltip>
                ))}
              </List>

              <Box
                sx={{
                  alignSelf: "flex-end",
                  // mt: (user ? user.role === "ROLE_EXECUTIVE" : "250px")
                  //   ? "250px"
                  //   : "390px",
                  bottom: " 10px",
                  position: "absolute",
                  alignSelf: "left",
                  width: "100%",
                }}
              >
                <List>
                  {/* <a
                    href={getManualPath()}
                    style={{ textDecoration: "none" }}
                    // download
                    target="_blank"
                  >
                    <ListItem
                      button
                      key={908}
                      sx={{
                        ":hover": { backgroundColor: "transparent !important" },
                      }}
                    >
                      <ListItemIcon sx={{ color: "white", width: 20 }}>
                        <HelpOutlineOutlinedIcon />
                      </ListItemIcon>

                      <ListItemText
                        sx={{
                          "& .MuiTypography-root": {
                            fontSize: "20px",
                            fontWeight: 400,
                            fontFamily: "Basier Circle",
                            color: "#F9F9FC",
                          },
                        }}
                        primary={open ? "Manual Përdorimi" : ""}
                      />
                    </ListItem>
                  </a> */}

                  <ListItem
                    button
                    onClick={handleClickResetPassword}
                    key={666}
                    sx={{
                      ":hover": { backgroundColor: "transparent !important" },
                    }}
                  >
                    <ListItemIcon sx={{ color: "white", width: 20 }}>
                      <LockResetIcon />
                    </ListItemIcon>
                    <ListItemText
                      sx={{
                        "& .MuiTypography-root": {
                          fontSize: "20px",
                          fontWeight: 400,
                          fontFamily: "Basier Circle",
                          color: "#F9F9FC",
                        },
                      }}
                      primary={open ? "Ndrysho Fjalëkalimin" : ""}
                    />
                  </ListItem>

                  <ListItem
                    button
                    onClick={handleClickLogout}
                    key={222}
                    sx={{
                      ":hover": { backgroundColor: "transparent !important" },
                    }}
                  >
                    <ListItemIcon sx={{ color: "white", width: 20 }}>
                      <LogoutIcon />
                    </ListItemIcon>
                    <ListItemText
                      sx={{
                        "& .MuiTypography-root": {
                          fontSize: "20px",
                          fontWeight: 400,
                          fontFamily: "Basier Circle",
                          color: "#F9F9FC",
                        },
                      }}
                      primary={open ? "Dil" : ""}
                    />
                  </ListItem>

                  <ListItem
                    onClick={handleDrawerOpen}
                    key={333}
                    sx={{
                      display: "list-item",
                      // margin: "15px 20px 15px 10px",
                      paddingLeft: !open ? "27px" : "27px",
                      textAlign: !open ? "center" : "left",
                      ":hover": { backgroundColor: "transparent !important" },
                    }}
                  >
                    {/* <ListItemIcon sx={{ color: "white", width: 20 }}>
                      <img
                        style={{ textAlign: "center", height: "50px" }}
                        src={EreLogo}
                        alt="ERE Logo"
                      />
                    </ListItemIcon> */}
                  </ListItem>
                </List>
              </Box>
            </div>
          </ClickAwayListener>
        </Drawer>
      ) : null}
      <Main open={open}>{props.children}</Main>
      <DialogDelete
        open={stateDialoglogout}
        disagreedelete={handleCloseDialogLogout}
        agreedelete={logOut}
        description={"Dëshironi të largoheni?"}
      />
      <ResetPassword
        open={stateDialogreset}
        onClose={handleCloseDialogreset}
        formname="Ndrysho Fjalëkalimin"
      ></ResetPassword>
      {/* <ModalUI
        open={stateDialogreset}
        buttons={
          <Stack sx={{ display: "flex", width: "100%" }}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "primary.dark",
                display: "flex",
                marginTop: "40px",
              }}
              // startIcon={<Save />}
              // onClick={saveUser}
            >
              {"Ndrysho"}
            </Button>
          </Stack>
        }
        //onClickAway={handleCloseUnit}
      >
        <Stack spacing={2}>
          <TextField
            name="password"
            autoComplete="new-password"
            id="password"
            type="password"
            variant="outlined"
            // value={state.password}
            // error={checkError("password")}
            // helperText={
            //   checkError("password") ? "Fjalekalimi është i detyruar" : null
            // }
            // onChange={changePassword}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">Fjalëkalimi</InputAdornment>
              ),
            }}
          />
        </Stack>
      </ModalUI> */}
    </Box>
  );
}
