import * as React from "react";
import {
  Box,
  Typography,
  TextField,
  InputLabel,
  Button,
  Stack,
  AppBar,
  Toolbar,
  InputAdornment,
  FormHelperText,
  Chip,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import { useNavigate, useLocation } from "react-router-dom";
import StartingPageContent from "../components/StartingPage/StartingPageContent";
import { getFormInputesById } from "../store/formInputes";
import {
  getFormSubmissionById,
  setCheckedFormById,
  setFormSubmissionChangeAnswer,
} from "../store/formInputesSubmission";
import {
  uploadFile,
  clearUpdateFileId,
  loadFileById,
  uploadFileTemp,
} from "../store/uploadFile";
import {
  submitFormSubmission,
  submitFormSubmissionOverride,
} from "../store/formSubmission";
import { useDispatch, useSelector } from "react-redux";
import ContainerDashboard from "../components/UI/ContainerDashboard";
import BottomToolbar from "../components/UI/BottomToolbar";
import DialogDelete from "../components/UI/DialogDelete";
import LogTable from "../components/FormatePlotesuara/LogTable";
import DialogComment from "../components/UI/DialogComment";
import InfoIcon from "@mui/icons-material/Info";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { getDateFormat } from "../components/helper/CustomLabel";
import TextFieldDateFilter from "../components/UI/TextFieldDateFilter";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { id, sq } from "date-fns/locale";
import GetAppIcon from "@mui/icons-material/GetApp";

const FormGridInputet = () => {
  const initialAnswers = [];

  const user = useSelector((state) => state.auth.user);

  const isAdministrator = user?.role === "ROLE_EXECUTIVE" ? true : false;
  const isGlobalEditor = user?.isGlobalEditor ? user?.isGlobalEditor : false;
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const stateFormInputId = location.state.stateFormInputId;
  const stateForm = location.state.stateForm;
  const nameForm = location.state.nameForm;
  const analystCheck = location.state?.analystCheck;
  const status = location.state?.status;
  const company = location.state?.company;
  const formSubmissionState = location.state?.formSubmissionState;
  const variableSubmission = location.state?.variableSubmission;

  const { formInputesSubmission, previousSubmissions } = useSelector(
    (state) => state.formInputesSubmission
  );

  const { uplaodFileId, loadFile, uplaodFileIdTemp } = useSelector(
    (state) => state.uplaodFile
  );

  const [dataValues, setDataValues] = React.useState([]);
  const [errorValues, setErrorValues] = React.useState([]);
  const [stateComment, setStateComment] = React.useState("");
  const [stateFileId, setStateFileId] = React.useState("");
  const [stateCommentSend, setStateCommentSend] = React.useState("");
  const [dataSendWithComment, setDataSendWithComment] = React.useState({});
  const [dataSendWithApprove, setDataSendWithApprove] = React.useState({});
  const [errorDataCommnet, setErrorDataComment] = React.useState(false);
  const [stateDialogWarning, setStateDialogWarning] = React.useState(false);
  const [stateDialogComment, setStateDialogComment] = React.useState(false);
  const [stateDialogApprove, setStateDialogApprove] = React.useState(false);
  const [files, setFiles] = React.useState([]);
  const [checked, setChecked] = React.useState(false);
  const [stateDialogExtraSubmission, setStateDialogExtraSubmission] =
    React.useState(false);
  const [finalDataSend, setFinalDataSend] = React.useState(false);

  const changeHandlerFiles = (event) => {
    const f = event.target.files[0];

    const formData = new FormData();
    formData.append("file", f);

    dispatch(uploadFile({ fileData: formData }));
    setFiles(event.target.files[0]);
  };

  const changeHandlerInputFiles = (event, item) => {
    const f = event.target.files[0];

    const formData = new FormData();
    formData.append("file", f);

    dispatch(
      uploadFileTemp({
        fileData: formData,
        stateFormInputesSubmission: formInputesSubmission,
        itemId: item?.id,
      })
    );

    // dispatch(
    //   setFormSubmissionChangeAnswer({
    //     state: formInputesSubmission,
    //     id: item?.id,
    //     answerValue: uplaodFileIdTemp,
    //   })
    // );
  };

  const onDeleteInputFiles = (event, item) => {
    dispatch(
      setFormSubmissionChangeAnswer({
        state: formInputesSubmission,
        id: item?.id,
        answerValue: "",
      })
    );
  };
  const onLoadInputFiles = (event, item) => {
    dispatch(loadFileById({ id: item?.answer?.answer }));
  };

  const onDeleteFile = () => {
    setFiles([]);
    dispatch(clearUpdateFileId());
  };

  const [logsFormInputesSubmission, setLogsFormInputesSubmission] =
    React.useState([]);

  React.useEffect(() => {
    if (stateFormInputId) {
      dispatch(getFormSubmissionById({ id: stateFormInputId }));
      setDataValues(formInputesSubmission);
      setChecked(analystCheck);
    }
    if (isAdministrator) {
      setLogsFormInputesSubmission(formInputesSubmission);
    }
  }, [stateFormInputId, stateForm, nameForm]);

  React.useEffect(() => {
    if (stateFormInputId) {
      dispatch(getFormSubmissionById({ id: stateFormInputId }));
      setDataValues(formInputesSubmission);
    }
    if (isAdministrator) {
      dispatch(getFormSubmissionById({ id: stateFormInputId }));
      setLogsFormInputesSubmission(formInputesSubmission);
    }
  }, []);

  React.useEffect(() => {
    if (isAdministrator) {
      setLogsFormInputesSubmission(formInputesSubmission);
    }
  }, [formInputesSubmission]);

  const handleChange = (event) => {
    const { name, value, id } = event.target;

    for (var i = 0; i < errorValues.length; i++) {
      if (errorValues[i].id == id && value !== "") {
        errorValues.splice(i, 1);
        break;
      }
    }
    var valueTemp = parseInt(value);

    if (valueTemp < -1) {
      dispatch(
        setFormSubmissionChangeAnswer({
          state: formInputesSubmission,
          id: id,
          answerValue: -1,
        })
      );
    } else {
      dispatch(
        setFormSubmissionChangeAnswer({
          state: formInputesSubmission,
          id: id,
          answerValue: value,
        })
      );
    }
  };

  const saveForm = () => {
    formInputesSubmission.map((item) => {
      if (item.answer != null) {
        initialAnswers.push({
          answer: item.answer ? item.answer.answer : null,
          id: item.answer ? item.answer.id : null,
        });
      } else {
      }
    });
    const dataSend = {};
    dataSend.id = stateFormInputId;
    dataSend.answers = initialAnswers;
    if (user?.role === "ROLE_EDITOR") {
      dataSend.status = "REVIEWED";
    } else {
      if (stateForm === "new") {
        dataSend.status = "DRAFT";
      } else {
        dataSend.status = "ACTIVE";
      }
    }
    dataSend.extraSubmission = false;
    dispatch(submitFormSubmission({ formSubmission: dataSend }));
    onBackNavigation();
  };

  const sendForm = (e) => {
    var initialAnswersError = [];
    formInputesSubmission.map((item) => {
      if (item.answer.answer !== "" || item.type !== "DOC") {
        initialAnswers.push({
          answer: item.answer ? item.answer.answer : null,
          id: item.answer ? item.answer.id : null,
        });
      } else {
        if (item.type !== "DOC") {
          initialAnswersError.push(item);
        }
      }
    });
    setErrorValues(initialAnswersError);

    if (initialAnswersError.length === 0) {
      const dataSend = {};
      dataSend.id = stateFormInputId;
      dataSend.answers = initialAnswers;

      if (isGlobalEditor && stateForm === "old") {
        dataSend.status = "REVIEWED";
        if (uplaodFileId !== "") {
          dataSend.file = uplaodFileId;
        }
        setDataSendWithComment(dataSend);
        handleCommentEdit(dataSend);
      } else {
        if (user?.role === "ROLE_EDITOR") {
          ///message: A jeni i sigurt qe doni te Aprovoni Formen?
          dataSend.status = "REVIEWED";
          dataSend.extraSubmission = false;
          setDataSendWithApprove(dataSend);
          handleApprove(dataSend);
        } else {
          dataSend.status = "SUBMITTED";
          if (variableSubmission) {
            setStateDialogExtraSubmission(true);
            setFinalDataSend(dataSend);
          } else {
            const finalDataSendTemp = {
              ...dataSend,
              extraSubmission: false,
            };
            dispatch(
              submitFormSubmission({ formSubmission: finalDataSendTemp })
            );
            navigate(-1);
          }
          // setStateDialogExtraSubmission(true);
          // setFinalDataSend(dataSend);

          // Add new = Modal

          // dispatch(submitFormSubmission({ formSubmission: dataSend }));
          // navigate(-1);
        }
      }
    } else {
      handleWarningMessage();
    }
  };

  const handleSendDataSimple = () => {
    const finalDataSendTemp = { ...finalDataSend, extraSubmission: false };
    dispatch(submitFormSubmission({ formSubmission: finalDataSendTemp }));
    navigate(-1);
  };

  const handleSendDataExtra = () => {
    const finalDataSendTemp = { ...finalDataSend, extraSubmission: true };
    dispatch(submitFormSubmission({ formSubmission: finalDataSendTemp }));
    navigate(-1);
  };

  const handleWarningMessage = (e) => {
    setStateDialogWarning(true);
  };

  const handleBackForm = (e) => {
    setStateDialogWarning(false);
  };

  const handleApprove = (e) => {
    setStateDialogApprove(true);
  };

  const handleBackFormApprove = (e) => {
    setStateDialogApprove(false);
  };

  const handleCommentEdit = (e) => {
    setErrorDataComment(false);
    setStateDialogComment(true);
  };

  const handleBackFormComment = (e) => {
    setStateDialogComment(false);
  };
  const handleSendDataComent = () => {
    const tempDataWithComent = dataSendWithComment;
    if (stateCommentSend == "" || stateCommentSend.trim().length === 0) {
      setErrorDataComment(true);
    } else {
      tempDataWithComent.comment = stateCommentSend;
      if (uplaodFileId !== "") {
        tempDataWithComent.file = uplaodFileId;
      }
      dispatch(
        submitFormSubmissionOverride({ formSubmission: tempDataWithComent })
      );
      navigate(-1);
    }
  };

  const handleSendDataApprove = () => {
    dispatch(submitFormSubmission({ formSubmission: dataSendWithApprove }));
    navigate(-1);
  };

  const changeComment = (e) => {
    const commentValue = e.target.value;
    if (commentValue !== "" || commentValue == " ") {
      setErrorDataComment(false);
      setStateCommentSend(e.target.value);
    } else {
    }
  };

  const handleSelectLog = (e) => {
    const getLog = e.answers;
    setStateComment(e?.comment);

    setStateFileId(e?.file);

    const newLogInputes = [];
    if (logsFormInputesSubmission == []) {
      dispatch(getFormSubmissionById({ id: stateFormInputId }));
      setLogsFormInputesSubmission(formInputesSubmission);
    }
    getLog.forEach((itemLog) => {
      const newList = formInputesSubmission?.map((item) => {
        if (item?.answer?.id === itemLog?.id) {
          const updatedItem = {
            ...item,
            answer: {
              id: item?.answer?.id,
              answer: itemLog?.answer ? itemLog?.answer : "",
            },
          };
          newLogInputes.push(updatedItem);
          return updatedItem;
        }

        return item;
      });
    });
    setLogsFormInputesSubmission(newLogInputes);
  };

  const handleClickExportDoc = () => {
    if (stateFileId) {
      dispatch(loadFileById({ id: stateFileId }));
    }
  };

  const handleChangeCheck = (event) => {
    dispatch(setCheckedFormById({ id: stateFormInputId }));
    setChecked(event.target.checked);
  };

  const onBackNavigation = () => {
    navigate(-1);
  };

  const companyName = company?.name ? " - " + company?.name : "";

  const handleClickStartDate = (event) => {
    // setState({
    //   ...state,
    //   //dateEnd: "",
    // });
  };

  return (
    <StartingPageContent
      pageName={
        stateForm === "old"
          ? isAdministrator
            ? "Logs - Forma e plotësuar"
            : "Forma e plotësuar"
          : "Plotësimi i formës"
      }
      back
      onClick={onBackNavigation}
    >
      <Stack spacing={2}>
        <Box sx={{ flexGrow: 1 }}>
          <ContainerDashboard
            header={
              <Box>
                <Typography variant="h5" sx={{ ml: 1 }}>
                  {nameForm.charAt(0).toUpperCase() +
                    nameForm.slice(1) +
                    companyName}
                </Typography>
              </Box>
            }
            analystCheck={checked}
            onChange={handleChangeCheck}
            status={status}
            id={stateFormInputId}
          >
            {!isAdministrator ? (
              <Grid
                container
                // spacing={{ xs: 4, md: 4 }}
                columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
              >
                <Grid
                  sx={{ height: "80px", color: "black", fontSize: "30px" }}
                  container
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                >
                  <Grid item xs={3.2} sm={3.2} md={3.2} lg={3.2}>
                    <InputLabel sx={{ color: "black", fontSize: "22px" }}>
                      Indikator
                    </InputLabel>
                  </Grid>
                  <Grid
                    // sx={{ textAlign: "right" }}
                    item
                    xs={2.2}
                    sm={2.2}
                    md={2.2}
                    lg={2.2}
                  >
                    <InputLabel
                      sx={{
                        color: "black",
                        fontSize: "22px",
                      }}
                    >
                      Vlera
                    </InputLabel>
                  </Grid>
                  <Grid item xs={2.2} sm={2.2} md={2.2} lg={2.2}>
                    <InputLabel
                      sx={{
                        color: "black",
                        fontSize: "22px",
                        textAlign: "center",
                      }}
                    >
                      {previousSubmissions[0]}
                    </InputLabel>
                  </Grid>
                  <Grid item xs={2.2} sm={2.2} md={2.2} lg={2.2}>
                    <InputLabel
                      sx={{
                        color: "black",
                        fontSize: "22px",
                        textAlign: "center",
                      }}
                    >
                      {previousSubmissions[1]}
                    </InputLabel>
                  </Grid>
                  <Grid item xs={2.2} sm={2.2} md={2.2} lg={2.2}>
                    <InputLabel
                      sx={{
                        color: "black",
                        fontSize: "22px",
                        textAlign: "center",
                      }}
                    >
                      {previousSubmissions[2]}
                    </InputLabel>
                  </Grid>
                </Grid>
                {formInputesSubmission
                  ? formInputesSubmission.map((item) => (
                      <Grid
                        spacing={{ xs: 1, md: 1 }}
                        sx={{
                          alignItems: "center",
                          border: "1px solid #f5f8fc",
                          marginBottom: "2px",
                        }}
                        container
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                      >
                        <Grid item xs={3.2} sm={3.2} md={3.2} lg={3.2}>
                          <InputLabel>{item.label}</InputLabel>
                          <FormHelperText> </FormHelperText>
                        </Grid>
                        <Grid
                          sx={{
                            alignItems: "center",
                            "&.MuiGrid-item": {
                              marginTop: "auto",
                            },

                            // textAlign: "right",
                          }}
                          item
                          xs={2.2}
                          sm={2.2}
                          md={2.2}
                          lg={2.2}
                          key={item.key}
                        >
                          {item.type === "TEXT" || item.type === "NUMBER" ? (
                            <TextField
                              name={item.key}
                              autoComplete="off"
                              id={item.id}
                              variant="outlined"
                              defaultValue={
                                item.defaultAnswer ? item.defaultAnswer : 0
                              }
                              value={item?.answer?.answer}
                              onChange={handleChange}
                              error={errorValues.find(
                                (error) => error.key === item.key
                              )}
                              type={item.type} /// variable type
                              disabled={
                                (stateForm === "old" && !isGlobalEditor) ||
                                user?.role === "ROLE_EDITOR"
                              }
                              sx={{
                                "& .MuiInputBase-root.MuiOutlinedInput-root": {
                                  backgroundColor: "#F6F8FC",
                                },
                              }}
                              InputProps={{
                                inputProps: { min: -1 },

                                endAdornment: item.type === "NUMBER" && (
                                  <InputAdornment
                                    sx={{
                                      "&.MuiInputAdornment-root": {
                                        minWidth: "0px !important",
                                      },
                                    }}
                                    position="end"
                                  >
                                    {item.unit}
                                  </InputAdornment>
                                ),
                              }}
                              helperText={
                                errorValues.find(
                                  (error) => error.key === item.key
                                )
                                  ? item.label + " është i detyruar"
                                  : " "
                              }
                            />
                          ) : item.type === "DATE" ? (
                            <LocalizationProvider
                              dateAdapter={AdapterDateFns}
                              locale={sq}
                            >
                              <DatePicker
                                id={item?.id}
                                name={item?.key}
                                value={item?.answer?.answer}
                                // views={["year", "month"]}
                                // onChange={handleChange}
                                disabled={
                                  (stateForm === "old" && !isGlobalEditor) ||
                                  user?.role === "ROLE_EDITOR"
                                }
                                onChange={(newValue) => {
                                  dispatch(
                                    setFormSubmissionChangeAnswer({
                                      state: formInputesSubmission,
                                      id: item?.id,
                                      answerValue: getDateFormat(newValue),
                                    })
                                  );
                                }}
                                renderInput={(params) => (
                                  <TextFieldDateFilter
                                    onClick={handleChange}
                                    {...params}
                                    inputProps={{
                                      ...params.inputProps,
                                      placeholder: "Date",
                                    }}
                                    maxWidth="170px"
                                    inputWidth="100px"
                                    height="65px"
                                  />
                                )}
                              />
                              <FormHelperText> </FormHelperText>
                            </LocalizationProvider>
                          ) : (
                            <InputUploadFile
                              onchangefile={(e) =>
                                changeHandlerInputFiles(e, item)
                              }
                              filename={item?.answer?.answer}
                              onDelete={(e) => onDeleteInputFiles(e, item)}
                              onClick={(e) => onLoadInputFiles(e, item)}
                              deletable={
                                formSubmissionState === "ACTIVE" ||
                                formSubmissionState === "DRAFT"
                              }
                            />
                          )}
                        </Grid>
                        {/* Add previousAnswers grid */}
                        <Grid item xs={2.2} sm={2.2} md={2.2} lg={2.2}>
                          <InputLabel sx={{ textAlign: "center" }}>
                            {item?.previousAnswers !== null
                              ? item?.previousAnswers[previousSubmissions[0]]
                              : "-"}
                          </InputLabel>
                          <FormHelperText> </FormHelperText>
                        </Grid>
                        <Grid item xs={2.2} sm={2.2} md={2.2} lg={2.2}>
                          <InputLabel sx={{ textAlign: "center" }}>
                            {item?.previousAnswers !== null
                              ? item?.previousAnswers[previousSubmissions[1]]
                              : "-"}
                          </InputLabel>
                          <FormHelperText> </FormHelperText>
                        </Grid>
                        <Grid item xs={2.2} sm={2.2} md={2.2} lg={2.2}>
                          <InputLabel sx={{ textAlign: "center" }}>
                            {item?.previousAnswers !== null
                              ? item?.previousAnswers[previousSubmissions[2]]
                              : "-"}
                          </InputLabel>
                          <FormHelperText> </FormHelperText>
                        </Grid>
                      </Grid>
                    ))
                  : null}
              </Grid>
            ) : (
              <Box>
                <Box
                  sx={{
                    width: "100%",
                    mb: 2,
                  }}
                >
                  <LogTable
                    id={stateFormInputId}
                    title={nameForm.charAt(0).toUpperCase() + nameForm.slice(1)}
                    onSelectLog={handleSelectLog}
                  ></LogTable>
                </Box>
                {stateComment && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      paddingBottom: "15px",
                      paddingTop: "15px",
                    }}
                  >
                    <InfoIcon color="error" sx={{ mr: "5px" }}></InfoIcon>
                    <Typography color="error" sx={{ fontSize: "16px" }}>
                      {stateComment}
                    </Typography>
                  </Box>
                )}
                {stateFileId && (
                  <Button
                    sx={{
                      backgroundColor: "#7CB0FF",
                      height: "40px",
                      mb: "10px",
                      ":hover": { backgroundColor: "#7CB0FF" },
                    }}
                    variant="contained"
                    onClick={handleClickExportDoc}
                    // href={`data:image/png;charset=utf-8,${escape(loadFile)}`}

                    // download={"Logs_Dokuments.png"}
                    startIcon={<FileDownloadIcon />}
                  >
                    Eksporto Dokumentin
                  </Button>
                )}
                <Box>
                  <Grid
                    container
                    spacing={{ xs: 4, md: 4 }}
                    columns={{ xs: 1, sm: 8, md: 8, lg: 12 }}
                  >
                    {logsFormInputesSubmission
                      ? logsFormInputesSubmission.map((item) => (
                          <Grid
                            sx={{
                              "&.MuiGrid-item": {
                                marginTop: "auto",
                              },
                            }}
                            item
                            xs={2}
                            sm={4}
                            md={4}
                            key={item.key}
                          >
                            <Stack spacing={2}>
                              <InputLabel>{item.label}</InputLabel>
                              <TextField
                                name={item.key}
                                autoComplete="off"
                                id={item.id}
                                variant="outlined"
                                value={item?.answer?.answer}
                                type="text"
                                disabled
                                sx={{
                                  "& .MuiInputBase-root.MuiOutlinedInput-root":
                                    {
                                      backgroundColor: "#F6F8FC",
                                    },
                                }}
                                InputProps={{
                                  inputProps: { min: -1 },
                                  endAdornment: (
                                    <InputAdornment
                                      sx={{
                                        "&.MuiInputAdornment-root": {
                                          minWidth: "0px !important",
                                        },
                                      }}
                                      position="end"
                                    >
                                      {item.unit}
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Stack>
                          </Grid>
                        ))
                      : null}
                  </Grid>
                </Box>
              </Box>
            )}
          </ContainerDashboard>
        </Box>
      </Stack>
      <Toolbar></Toolbar>
      {stateForm === "old" || stateForm === "old" ? null : (
        <BottomToolbar>
          <Button
            variant="contained"
            sx={{
              width: "200px",
              height: "52px",
              backgroundColor: "primary.dark",
            }}
            onClick={user?.role === "ROLE_EDITOR" ? sendForm : saveForm}
          >
            {user?.role === "ROLE_EDITOR" ? "Aprovo" : "Ruaj"}
          </Button>
          {user?.role !== "ROLE_EDITOR" ? (
            <Button
              variant="contained"
              sx={{
                width: "200px",
                backgroundColor: "#45C8D0",
                ":hover": {
                  backgroundColor: "#45C8D0",
                },
                height: "52px",
              }}
              onClick={sendForm}
            >
              Dërgo
            </Button>
          ) : null}
        </BottomToolbar>
      )}
      {isGlobalEditor && stateForm === "old" ? (
        <BottomToolbar>
          <Button
            variant="contained"
            sx={{
              width: "200px",
              height: "52px",
              backgroundColor: "primary.dark",
            }}
            onClick={sendForm}
          >
            Aprovo
          </Button>
        </BottomToolbar>
      ) : null}
      <DialogDelete
        open={stateDialogWarning}
        agreedelete={handleBackForm}
        warning
        description={
          "Keni vlera të pa plotësuara, ju lutem plotësoni të gjitha fushat e kërkuara!"
        }
      />
      <DialogDelete
        open={stateDialogExtraSubmission}
        description={"A doni të rikrijoni një form tjetër?"}
        disagreedelete={handleSendDataSimple}
        agreedelete={handleSendDataExtra}
      />
      <DialogComment
        open={stateDialogComment}
        //comment={commen}
        required
        error={errorDataCommnet ? errorDataCommnet : undefined}
        //error={errorDataCommnet?error={condition ? value : undefined}}
        inputref={stateComment}
        onchangecomment={changeComment}
        disagreeComment={handleBackFormComment}
        submitComment={handleSendDataComent}
        description={"Lini komentin tuaj për ndryshimet përkatëse"}
        // files={files}
        filename={files?.name}
        filevalue={files}
        onDelete={onDeleteFile}
        onchangefile={changeHandlerFiles}
      />
      <DialogDelete
        open={stateDialogApprove}
        description={"Jeni të sigurt që doni të Aprovoni këtë formë?"}
        disagreedelete={handleBackFormApprove}
        agreedelete={handleSendDataApprove}
      />
    </StartingPageContent>
  );
};

const InputUploadFile = (props) => {
  return (
    <Box style={{ height: "90px", marginTop: "10px", textAlign: "left" }}>
      {!props.filename && (
        <Button
          variant="outlined"
          component="label"
          color="primary"
          disabled={!props.deletable}
          sx={{
            height: "50px",
            color: "#091B3D",
            fontSize: "12px",
            backgroundColor: "#f5f8fc",
            width: "170px",
          }}
        >
          Ngarko dok
          <input
            onChange={props.onchangefile}
            value={props.filevalue}
            type="file"
            hidden
          />
        </Button>
      )}
      {props.filename && (
        <Chip
          icon={<GetAppIcon />}
          sx={{
            height: "30px",
            color: "#000",
            fontSize: "12px",
            background: "#f6f8fc",
            borderRadius: "8px",
          }}
          onClick={props.onClick}
          label={"Dokument_" + props.filename}
          variant="outlined"
          onDelete={props.deletable && props.onDelete}
        ></Chip>
      )}
    </Box>
  );
};

export default FormGridInputet;
