import * as React from "react";
import StartingPageContent from "../components/StartingPage/StartingPageContent";
import Box from "@mui/material/Box";
import { setLoading } from "../store/loading";
import { useDispatch, useSelector } from "react-redux";
import {
  getFormSubmissionDashboard,
  getFormSubmissionSummary,
} from "../store/formSubmission";
import { ChartView2 } from "../components/Raports/ChartView2";
import { useNavigate, useLocation } from "react-router-dom";

const Raports = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const stateGraphId = location.state?.stateGraphId;
  const stateGraph = location.state?.stateGraph;

  const stateGraphIsUpdate = stateGraph === "update" ?? null;

  const { formSubmissionDashboard } = useSelector(
    (state) => state.formSubmission
  );

  const user = useSelector((state) => state.auth.user);
  const isAnalyst = user?.role === "ROLE_ANALYST";
  React.useEffect(() => {
    getAllData();
    //dispatch(getFormSubmissionDashboard());
    dispatch(getFormSubmissionSummary());
  }, [dispatch, getFormSubmissionDashboard, getFormSubmissionSummary]);

  const getAllData = () => {
    dispatch(setLoading(true));
    dispatch(getFormSubmissionDashboard())
      .unwrap()
      .then(() => {
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };
  const onBackNavigation = () => {
    navigate(-1);
  };
  return (
    <StartingPageContent
      back={stateGraphIsUpdate}
      onClick={onBackNavigation}
      pageName={stateGraphIsUpdate ? "Ndrysho Raport" : "Gjenero Raporte"}
    >
      <Box sx={{ justifyContent: "space-between", display: "flex" }}>
        <Box sx={{ mr: isAnalyst ? "0px" : "60px", width: 1 }}>
          <ChartView2></ChartView2>
        </Box>
      </Box>
    </StartingPageContent>
  );
};

export default Raports;
