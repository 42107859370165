import ProduktListTable from "../components/Produkt/ProduktListTable";
import StartingPageContent from "../components/StartingPage/StartingPageContent";
const ProduktListPage = () => {
  return (
    <StartingPageContent pageName="Lista e Produkteve">
      <ProduktListTable></ProduktListTable>
    </StartingPageContent>
  );
};

export default ProduktListPage;
