import React from "react";
import { useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  InputLabel,
  ListItem,
  Stack,
  TextField,
  Typography,
  Tooltip as MUITooltip,
  IconButton,
} from "@mui/material";
import BarChart from "./BarChart";
import LineChart from "./LineChart";
import PieChart from "./PieChart";
import { useDispatch, useSelector } from "react-redux";
import { getAllUnit } from "../../store/njesiRaportuese";
import { setLoading } from "../../store/loading";
import SelectFilter from "../UI/SelectFilter";
import ButtonAdd from "../UI/ButtonAdd";
import {
  getByReportingUnit,
  cleanFormTemplateSearch,
} from "../../store/formTemplate";
import { getFormInputesById } from "../../store/formInputes";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import TextFieldDateFilter from "../UI/TextFieldDateFilter";
import { id, sq } from "date-fns/locale";
import {
  getDateFormat,
  getfirstDayOfCurrentMonthTwoYearsAgo,
  getlastDayOfCurrentMonth,
  getLastDayOfCurrentMonth,
} from "../helper/CustomLabel";
import { getGraphData } from "../../store/graphData";
import BarChartIcon from "@mui/icons-material/BarChart";
import ButtonChartSelect from "../UI/ButtonChartSelect";
import ContainerAnalyst from "../UI/ContainerAnalyst";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { getCustomTextLength } from "../helper/CustomLabel";
import { backgroundColorList, colorsBorderGraph } from "./GraphProps";
import ChartModalEditor from "./ChartModalEditor";
import { useNavigate, useLocation } from "react-router-dom";
import { getGraphByIdData } from "../../store/graphDashboard";
import { getLastDayOfYearForPie } from "../helper/CustomLabel";
import SearchModal from "./SearchModal";
import { Search } from "@mui/icons-material";
import ExportList from "../UI/ExportList";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export function ChartView2() {
  const { njesiRaportuese } = useSelector((state) => state.njesiRaportuese);
  const { formTemplate } = useSelector((state) => state.formTemplate);
  const { graphById } = useSelector((state) => state.graphDashboard);
  const { formInputesById } = useSelector((state) => state.formInputesById);
  const { graphData } = useSelector((state) => state.graphData);
  const user = useSelector((state) => state.auth.user);
  const isEditor = user?.role === "ROLE_EDITOR";
  const isOperator = user?.role === "ROLE_OPERATOR";
  const isAnalist = user?.role === "ROLE_ANALYST";
  const isMinor = user?.role === "ROLE_MINOR_EXECUTIVE";

  const navigate = useNavigate();
  const location = useLocation();
  const stateGraphId = location.state?.stateGraphId;
  const stateGraph = location.state?.stateGraph;

  const stateGraphIsUpdate = stateGraph === "update" ?? null;

  const dispatch = useDispatch();
  const printRef = React.useRef();
  const [stateChipListBox, setStateChipListBox] = React.useState(true);

  const [state, setState] = React.useState({
    type: "line",
    reportingUnitId: "",
    formId: "",
    indikatorId: "",
    labelNameIndikator: "",
    dateStart: "",
    dateEnd: "",
    date: "",
    indikatorsList: [],
    listFilter: {
      dateEnd: "",
      dateStart: "",
      formInputIds: [],
    },
  });
  const typeChartList = [
    { label: "Vije", value: "line" },
    { label: "Bar", value: "bar" },
    { label: "Pie", value: "pie" },
  ];

  const changeTypeState = (value) => {
    if (value === "pie") {
      setState((state) => ({
        ...state,
        type: value ? value : "",
        // reportingUnitId: "",
        formId: "",
        indikatorId: "",
        labelNameIndikator: "",
        dateStart: "",
        dateEnd: "",
        date: "",
        indikatorsList: [],
        listFilter: {
          date: "",
          formInputIds: [],
        },
      }));
    } else {
      setState((state) => ({
        ...state,
        type: value ? value : "",
      }));
    }
  };

  const [userData, setUserData] = useState({
    labels: graphData.map((data) => data.graphDate),
    datasets: [
      {
        label: "Indikatori 1",
        data: graphData.map(
          (data) => data["077babb9-c3b7-41bc-926a-399bff50bdea"]
        ),
        backgroundColor: ["rgba(75,192,192,1)"],
        borderColor: "red",
        borderWidth: 2,
      },
    ],
  });

  const searchStateUnit = React.useRef("");
  const searchStateForm = React.useRef("");
  const [searchValueServerUnit, setSearchValueServerUnit] = React.useState("");
  const [searchValueServerForm, setSearchValueServerForm] = React.useState("");
  const [anchorElUnit, setAnchorElUnit] = React.useState(null);
  const [anchorElForm, setAnchorElForm] = React.useState(null);
  const [anchorElIndikator, setAnchorElIndikator] = React.useState(null);
  const [openChartModal, setOpenChartModal] = React.useState(false);
  const [isStatusNewChart, setIsStatusNewChart] = React.useState(true);
  const [openSearchModal, setOpenSearchModal] = React.useState(false);

  const handleClickUnit = (event) => {
    dispatch(
      getAllUnit({ page: 0, searchValue: "", size: 1000, filterSelect: true })
    );
    setState({
      ...state,
      formId: "",
      indikatorId: "",
      labelNameIndikator: "",
      indikatorsList: [],
    });
    setAnchorElUnit(event.currentTarget);
  };
  const handleClickForm = (event) => {
    getAllDataFormUnit();
    setState({
      ...state,
      indikatorId: "",
      labelNameIndikator: "",
      indikatorsList: [],
    });
    setAnchorElForm(event.currentTarget);
  };
  const handleClickIndikator = (event) => {
    // dispatch(getAllUnit({ page: 0, searchValue: "", size: 11 }));
    getAllDataFormIndikator();
    setAnchorElIndikator(event.currentTarget);
  };

  const handleClickStartDate = (event) => {
    setState({
      ...state,
      //dateEnd: "",
    });
  };
  const handleCloseUnit = () => {
    setAnchorElUnit(null);
  };
  const handleCloseForm = () => {
    setAnchorElForm(null);
  };
  const handleCloseIndikator = () => {
    setAnchorElIndikator(null);
  };

  const openUnit = Boolean(anchorElUnit);
  const openForm = Boolean(anchorElForm);

  const handleCloseChartModal = () => setOpenChartModal(false);

  const searchItemsUnit = (e) => {
    const searchValue = e.target.value;
    if (searchValue !== "") {
      searchStateUnit.current.value = searchValue;
      let filterTimeout;
      filterTimeout = setTimeout(() => {
        setSearchValueServerUnit(searchStateUnit.current.value);
      }, 1000);
    } else {
      setSearchValueServerUnit("");
      dispatch(getAllUnit({ page: 0, searchValue: "", filterSelect: true }));
    }
  };

  const searchItemsForm = (e) => {
    const searchValue = e.target.value;
    if (searchValue !== "") {
      searchStateForm.current.value = searchValue;
      let filterTimeout;
      filterTimeout = setTimeout(() => {
        setSearchValueServerForm(searchStateForm.current.value);
      }, 1000);
    } else {
      setSearchValueServerForm("");
      // dispatch(getAllUnit({ page: 0, searchValue: "" }));
    }
  };

  React.useEffect(() => {
    getAllDataUnit();
  }, [dispatch, searchValueServerUnit]);
  React.useEffect(() => {
    reRenderGraph();
  }, [graphData]);

  const listIndicatorUpdate = [];
  for (var r = 0; r < graphById?.inputs?.length; r++) {
    listIndicatorUpdate.push({
      id: graphById?.inputs[r].formInput,
      label: graphById?.inputs[r].label,
    });
  }

  React.useEffect(() => {
    if (stateGraphIsUpdate) {
      setState({
        ...state,
        id: graphById.id,
        name: graphById.name,
        order: graphById.order,
        dateEnd: getLastDayOfCurrentMonth(),
        dateStart: getfirstDayOfCurrentMonthTwoYearsAgo(),
        date: getLastDayOfYearForPie(),
        type:
          graphById?.type === "SINGLE_LINE_CHART"
            ? "line"
            : graphById?.type?.toLowerCase(),
        listFilter: {
          dateStart: getfirstDayOfCurrentMonthTwoYearsAgo(),
          dateEnd: getLastDayOfCurrentMonth(),
          formInputIds: listIndicatorUpdate ? listIndicatorUpdate : [],
        },
      });

      const listIndikatorSend = [];
      const sendListTemp = [];
      graphById?.inputs?.map((item) => {
        sendListTemp.push(item.formInput);
      });

      listIndikatorSend.push(...sendListTemp);

      if (graphById?.type?.toLowerCase() === "pie") {
        const dataSend = {
          date: getLastDayOfYearForPie(),
          formInputIds: listIndikatorSend,
        };
        getGraphtFromAPI(dataSend);
      } else {
        const dataSend = {
          dateStart: getfirstDayOfCurrentMonthTwoYearsAgo(),
          dateEnd: getLastDayOfCurrentMonth(),
          formInputIds: listIndikatorSend,
        };

        getGraphtFromAPI(dataSend);
      }

      reRenderGraph();
    }
  }, [graphById?.type, graphById?.inputs]);

  React.useEffect(() => {
    if (isEditor || isOperator) {
      setState({
        ...state,
        reportingUnitId: user?.reportingUnit.id,
        formId: "",
      });
    }
    if (stateGraphIsUpdate) {
      dispatch(getGraphByIdData({ id: stateGraphId }));
    }
  }, []);

  const reRenderGraph = () => {
    const objectConst = [];

    if (state.type === "pie") {
      const dataLabel = [];
      const dataTemp = [];
      const backgroundTemp = [];
      for (var i = 0; i < state.listFilter?.formInputIds?.length; i++) {
        dataLabel.push(String(state.listFilter?.formInputIds[i]?.label));
        if (graphData[0]) {
          dataTemp.push(
            graphData[0][String(state.listFilter?.formInputIds[i]?.id)]
          );
        }
        backgroundTemp.push(backgroundColorList[i]);
      }

      if (state.listFilter?.formInputIds.length > 0) {
        setUserData({
          //labels: graphData.map((data) => data.graphDate),
          labels: dataLabel,
          datasets: [
            {
              label: dataLabel,
              data: dataTemp,
              backgroundColor: backgroundTemp,
            },
          ],
        });
      } else {
        setUserData({
          labels: dataLabel,
          datasets: [],
        });
      }
    } else {
      for (var i = 0; i < state.listFilter?.formInputIds?.length; i++) {
        objectConst.push({
          label: String(state.listFilter?.formInputIds[i]?.label),
          data: graphData.map(
            (data) => data[String(state.listFilter?.formInputIds[i]?.id)]
          ),
          backgroundColor: backgroundColorList[i],
          borderColor: colorsBorderGraph[i],
          borderWidth: 4,
        });
      }
      if (state.listFilter?.formInputIds?.length > 0) {
        setUserData({
          labels: graphData.map((data) => data.graphDate),
          datasets: objectConst,
        });
      } else {
        setUserData({
          labels: graphData.map((data) => data.graphDate),
          datasets: [],
        });
      }
    }
  };

  const getAllDataUnit = () => {
    dispatch(setLoading(true));
    dispatch(
      getAllUnit({
        page: 0,
        searchValue: searchValueServerUnit,
        size: 1000,
        filterSelect: true,
      })
    )
      .unwrap()
      .then(() => {
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };

  const getAllDataFormUnit = () => {
    dispatch(setLoading(true));
    dispatch(
      getByReportingUnit({
        unit: state.reportingUnitId,
        searchValue: searchValueServerForm,
      })
    )
      .unwrap()
      .then(() => {
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };

  const getAllDataFormIndikator = () => {
    dispatch(setLoading(true));
    dispatch(getFormInputesById({ id: state.formId }))
      .unwrap()
      .then(() => {
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };

  const sendRequestIndikator = () => {
    var resultNew = [];
    state.indikatorsList.map((item) => {
      resultNew.push({ id: item.id, label: item.label });
    });

    setState({
      ...state,
      // reportingUnitId: "",
      // formId: "",
      indikatorId: "",
      labelNameIndikator: "",
      indikatorsList: [],
      listFilter: {
        dateStart: state.dateStart,
        dateEnd: state.dateEnd,
        formInputIds: [...state.listFilter.formInputIds, ...resultNew],
      },
    });

    const listIndikatorSend = [];
    const sendListTemp = [];
    resultNew.map((item) => {
      sendListTemp.push(item.id);
    });

    listIndikatorSend.push(...sendListTemp);
    for (var i = 0; i < state.listFilter?.formInputIds?.length; i++) {
      listIndikatorSend.push(state.listFilter?.formInputIds[i].id);
    }

    if (state.type === "pie") {
      const dataSend = {
        date: state.date,
        formInputIds: listIndikatorSend,
      };
      getGraphtFromAPI(dataSend);
    } else {
      const dataSend = {
        dateStart: state.dateStart,
        dateEnd: state.dateEnd,
        formInputIds: listIndikatorSend,
      };
      getGraphtFromAPI(dataSend);
    }
  };

  const getGraphtFromAPI = (dataSend) => {
    dispatch(getGraphData({ indikators: dataSend }));
  };

  const DeleteIndikator = (itemDelete) => {
    setState({
      ...state,
      listFilter: {
        dateStart: state.dateStart,
        dateEnd: state.dateEnd,
        date: state.date,
        formInputIds: state.listFilter?.formInputIds?.filter(
          (item) => item.id !== itemDelete.id
        ),
      },
    });
    const listIndikatorSend = [];

    for (var i = 0; i < state.listFilter?.formInputIds?.length; i++) {
      listIndikatorSend.push(state.listFilter?.formInputIds[i].id);
    }
    const listIndikatorSendTemp = listIndikatorSend.filter(
      (item) => item.id !== itemDelete.id
    );

    if (state.type === "pie") {
      const dataSend = {
        date: state.date,
        formInputIds: listIndikatorSendTemp,
      };
      getGraphtFromAPI(dataSend);
    } else {
      const dataSend = {
        dateStart: state.dateStart,
        dateEnd: state.dateEnd,
        formInputIds: listIndikatorSendTemp,
      };

      getGraphtFromAPI(dataSend);
    }
  };

  const getValidation = () => {
    if (state.type !== "pie") {
      if (
        state.indikatorsList === [] ||
        state.dateStart === "" ||
        state.dateEnd === ""
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      if (state.indikatorsList === [] || state.date === "") {
        return false;
      } else {
        return true;
      }
    }
  };

  const handleDownloadImage = async () => {
    await setStateChipListBox(false);
    const element = printRef.current;
    const canvas = await html2canvas(element);

    const data = canvas.toDataURL("image/jpg");
    const link = document.createElement("a");
    if (typeof link.download === "string") {
      link.href = data;
      link.download = "print_Ere.jpg";

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      window.open(data);
    }
    setStateChipListBox(true);
  };
  const handleDownloadPdf = async () => {
    await setStateChipListBox(false);
    const element = printRef.current;
    const canvas = await html2canvas(element);
    const data = canvas.toDataURL("image/png");

    const pdf = new jsPDF();
    const imgProperties = pdf.getImageProperties(data);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;

    pdf.addImage(data, "PNG", 0, 0, pdfWidth, pdfHeight);
    pdf.save("print_Ere.pdf");
    setStateChipListBox(true);
  };

  const addGraphOnDashboard = () => {
    if (stateGraphIsUpdate) {
      setIsStatusNewChart(false);
    }
    setOpenChartModal(true);
  };

  const handleOpenSearchModal = () => {
    if (openSearchModal) {
      setOpenSearchModal(false);
    } else {
      dispatch(cleanFormTemplateSearch());
      setOpenSearchModal(true);
    }
  };

  const handleOnSelectForm = (item) => {
    handleOpenSearchModal();
    var newIndikators = [];
    item.inputs.map((itemInd) => {
      newIndikators.push({ id: itemInd.id, label: itemInd.name });
    });

    setState({
      ...state,
      reportingUnit: item?.reportingUnit ? item.reportingUnit : "",
      formId: item?.id ? item?.id : "",
      indikatorsList: [
        { id: "39ad2812-8a0d-4bea-9bcf-49db155ddbc6", label: "E-Charger" },
      ],
    });

    /// from modal serach filter
    var resultNew = [];
    state.indikatorsList.map((item) => {
      resultNew.push({ id: item.id, label: item.label });
    });

    setState({
      ...state,
      indikatorId: "",
      labelNameIndikator: "",
      indikatorsList: [],
      listFilter: {
        dateStart: state?.dateStart,
        dateEnd: state?.dateEnd,
        formInputIds: [...state.listFilter.formInputIds, ...newIndikators],
      },
    });

    const listIndikatorSend = [];
    const sendListTemp = [];
    newIndikators.map((item) => {
      sendListTemp.push(item.id);
    });

    listIndikatorSend.push(...sendListTemp);
    for (var i = 0; i < state.listFilter?.formInputIds?.length; i++) {
      listIndikatorSend.push(state.listFilter?.formInputIds[i].id);
    }

    if (state.type === "pie") {
      const dataSend = {
        date: state.date ? state.date : getlastDayOfCurrentMonth(),
        formInputIds: listIndikatorSend,
      };
      getGraphtFromAPI(dataSend);
    } else {
      const dataSend = {
        dateStart: state?.dateStart
          ? state?.dateStart
          : getfirstDayOfCurrentMonthTwoYearsAgo(),
        dateEnd: state?.dateEnd ? state?.dateEnd : getlastDayOfCurrentMonth(),
        formInputIds: listIndikatorSend,
      };
      getGraphtFromAPI(dataSend);
    }
  };

  return (
    <ContainerAnalyst
      header={
        <Box sx={{ width: "100%" }}>
          <Stack direction="row" sx={{ mb: 2, width: "100%" }} spacing={1}>
            {typeChartList.map((item) => (
              <ButtonChartSelect
                type={item.value}
                key={item.value}
                selected={state.type == item.value}
                onClick={() => changeTypeState(item.value)}
              />
            ))}

            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
                alignItems: "center",
              }}
            >
              {state.listFilter?.formInputIds?.length !== 0 && isAnalist && (
                <Button
                  sx={{
                    height: "40px",
                    backgroundColor: "#CD3728",
                    color: "#fff",
                    borderRadius: "8px",
                    "&.MuiButtonBase-root:hover": {
                      backgroundColor: "#ff3824",
                    },
                    marginRight: "5px",
                  }}
                  onClick={addGraphOnDashboard}
                >
                  {stateGraphIsUpdate ? "Ndrysho" : "Shto në Raporte"}
                </Button>
              )}
              {state.listFilter?.formInputIds?.length !== 0 && (
                <ExportList
                  onPdf={handleDownloadPdf}
                  onJpg={handleDownloadImage}
                ></ExportList>
              )}
              {!isEditor && !isOperator && (
                <IconButton
                  sx={{
                    backgroundColor: "#B2D4FF",
                    ":hover": { backgroundColor: "#B2D4FF" },
                    marginRight: "5px",
                    color: "white",
                  }}
                  type="button"
                  onClick={handleOpenSearchModal}
                >
                  <Search></Search>
                </IconButton>
              )}
              <ButtonAdd
                labelbutton={
                  state?.listFilter?.formInputIds.length == 0 && "Gjenero"
                }
                style={{}}
                disabled={!getValidation()}
                onClick={sendRequestIndikator}
              ></ButtonAdd>
            </Box>
          </Stack>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              alignContent: "flex-start",
              mb: "20px",
            }}
          >
            {user?.role === "ROLE_ANALYST" ||
            user?.role === "ROLE_EXECUTIVE" ||
            user?.role === "ROLE_SOFT_EXECUTIVE" ||
            isMinor ? (
              <Stack spacing={2} sx={{ mr: "10px" }}>
                <InputLabel required>Drejtoria</InputLabel>
                <SelectFilter
                  textfield
                  name="reportingUnitId"
                  id="reportingUnitId"
                  label="Drejtoria"
                  placeholder="Kërko Drejtori"
                  noOptionsText="Nuk ka Drejtori"
                  value={state?.reportingUnitId}
                  onClick={handleClickUnit}
                  source={njesiRaportuese}
                  open={openUnit}
                  anchorEl={anchorElUnit}
                  onClickAway={handleCloseUnit}
                  onClose={(event, reason) => {
                    if (reason === "escape") {
                      handleCloseUnit();
                    }
                  }}
                  onChange={(event, newValue, reason) => {
                    if (
                      event.type === "keydown" &&
                      event.key === "Backspace" &&
                      reason === "removeOption"
                    ) {
                      return;
                    }
                    setState({
                      ...state,
                      reportingUnitId: newValue.id ? newValue.id : "",
                      formId: "",
                    });
                    handleCloseUnit();
                    getAllDataFormUnit();
                  }}
                  onchangesearch={searchItemsUnit}
                  inputRef={searchStateUnit}
                ></SelectFilter>
              </Stack>
            ) : null}

            <Stack spacing={2} sx={{ mr: "10px" }}>
              <InputLabel required>Forma</InputLabel>
              <SelectFilter
                textfield
                disabledfilter={!state.reportingUnitId}
                name="formId"
                id="formId"
                label="Forma"
                placeholder="Kërko Formë"
                noOptionsText="Nuk ka formë"
                value={state.formId}
                onClick={handleClickForm}
                source={formTemplate}
                open={openForm}
                anchorEl={anchorElForm}
                onClickAway={handleCloseForm}
                onClose={(event, reason) => {
                  if (reason === "escape") {
                    handleCloseForm();
                  }
                }}
                onChange={(event, newValue, reason) => {
                  if (
                    event.type === "keydown" &&
                    event.key === "Backspace" &&
                    reason === "removeOption"
                  ) {
                    return;
                  }
                  setState({
                    ...state,
                    formId: newValue.id ? newValue.id : "",
                  });
                  getAllDataFormIndikator();
                  handleCloseForm();
                }}
                onchangesearch={searchItemsForm}
                inputRef={searchStateForm}
              ></SelectFilter>
            </Stack>

            <Stack spacing={2} sx={{ mr: "10px" }}>
              <InputLabel required>Indikatori</InputLabel>
              <MUITooltip
                title={
                  state?.indikatorsList ? state?.indikatorsList[0]?.label : ""
                }
                arrow
              >
                <Autocomplete
                  multiple
                  disabled={!state.formId}
                  onOpen={handleClickIndikator}
                  // onClick={handleClickIndikator}
                  sx={{
                    "& .MuiAutocomplete-root .MuiOutlinedInput-root": {
                      padding: "0px !important",
                    },
                  }}
                  renderTags={(values) =>
                    state.indikatorsList !== []
                      ? getCustomTextLength(
                          state.indikatorsList[0]?.label,
                          20
                        ) +
                        String(state.indikatorsList?.length > 1 ? "  +" : "") +
                        String(
                          state.indikatorsList?.length > 1
                            ? state.indikatorsList?.length - 1
                            : ""
                        )
                      : ""
                  }
                  id="checkboxes-tags-demo"
                  options={formInputesById}
                  disableCloseOnSelect
                  value={state.indikatorsList}
                  getOptionLabel={(option) => option.label}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.label}
                    </li>
                  )}
                  onChange={(event, newValue, reason) => {
                    if (
                      event.type === "keydown" &&
                      event.key === "Backspace" &&
                      reason === "removeOption"
                    ) {
                      return;
                    }

                    setState((state) => ({
                      ...state,
                      indikatorsList: newValue ? newValue : [],
                    }));
                  }}
                  renderInput={(params) => (
                    <TextField
                      sx={{
                        minWidth: "250px",
                        maxWidth: "250px",
                        height: "50px",

                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        // height: "auto",
                        marginRight: "20px",
                        width: "min-content",

                        "& .Mui-disabled": {
                          "fieldset ": {
                            // borderColor: "#72aeff !important",
                            minWidth: "250px",
                            maxWidth: "250px",
                          },
                        },
                        "& .MuiInputBase-root.MuiOutlinedInput-root": {
                          backgroundColor: "#f6f8fc",
                          borderRadius: "12px",
                          height: "50px",
                        },
                        "& .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input.Mui-disabled":
                          {
                            WebkitTextFillColor: "#091B3D !important",
                          },
                      }}
                      {...params}
                    />
                  )}
                />
              </MUITooltip>
            </Stack>

            {state.type === "pie" && (
              <Stack spacing={2} sx={{ mr: "10px" }}>
                <InputLabel>Date</InputLabel>
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={sq}>
                  <DatePicker
                    value={state.date}
                    views={["year", "month"]}
                    // disabled={state.listFilter?.formInputIds.length > 0}
                    onChange={(newValue) => {
                      var last_date = new Date(
                        newValue.getFullYear(),
                        newValue.getMonth() + 1,
                        0
                      );
                      setState({
                        ...state,
                        date: last_date ? getDateFormat(last_date) : "",
                      });
                    }}
                    renderInput={(params) => (
                      <TextFieldDateFilter
                        onClick={handleClickStartDate}
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "muaj viti",
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Stack>
            )}

            {state.type !== "pie" && (
              <Stack spacing={2} sx={{ mr: "10px" }}>
                <InputLabel required>Datë Fillimi</InputLabel>
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={sq}>
                  <DatePicker
                    value={state.dateStart}
                    views={["year", "month"]}
                    // disabled={state.listFilter?.formInputIds.length > 0}
                    onChange={(newValue) => {
                      var first_date = new Date(
                        newValue.getFullYear(),
                        newValue.getMonth(),
                        1
                      );
                      setState({
                        ...state,
                        dateStart: first_date ? getDateFormat(first_date) : "",
                        //dateEnd: "",
                      });
                    }}
                    renderInput={(params) => (
                      <TextFieldDateFilter
                        onClick={handleClickStartDate}
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "muaj viti",
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Stack>
            )}

            {state.type !== "pie" && (
              <Stack spacing={2} sx={{ mr: "10px" }}>
                <InputLabel required>Datë Mbarimi</InputLabel>
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={sq}>
                  <DatePicker
                    value={state.dateEnd}
                    views={["year", "month"]}
                    //   disabled={
                    //     state.dateStart === "" ||
                    //     state.listFilter?.formInputIds.length > 0
                    //   }
                    // openTo="year"
                    // minDate={state.dateStart}
                    onChange={(newValue) => {
                      var last_date = new Date(
                        newValue.getFullYear(),
                        newValue.getMonth() + 1,
                        0
                      );
                      setState({
                        ...state,
                        dateEnd: last_date ? getDateFormat(last_date) : "",
                      });
                    }}
                    renderInput={(params) => (
                      <TextFieldDateFilter
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "muaj viti",
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Stack>
            )}
          </Box>
        </Box>
      }
    >
      <Box></Box>
      <div
        style={{
          backgroundColor: "white",
          textAlign: "-webkit-center",
          padding: "0px 20px 20px 20px",
        }}
      >
        {state.listFilter?.formInputIds?.length === 0 ? (
          <Box
            sx={{
              height: "500px",
              width: "100%",
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              borderRadius: "15px",
              backgroundColor: "#f5f8fc",
            }}
          >
            <Stack sx={{ textAlign: "center", alignItems: "center" }}>
              <BarChartIcon
                sx={{ fontSize: "70px", color: "rgb(151 154 156)" }}
              ></BarChartIcon>
              <br />
              <Typography sx={{ color: "rgb(151 154 156)" }}>
                {"Nuk ka asnjë Grafik"}
              </Typography>
            </Stack>
          </Box>
        ) : state.type === "bar" ? (
          <div
            style={{
              width: "1100px",
              borderRadius: "15px",
              padding: "30px",
              backgroundColor: "#f5f8fc",
            }}
            ref={printRef}
          >
            {stateChipListBox && (
              <Box
                sx={{ display: "flex", flexWrap: "wrap", flexDirection: "row" }}
              >
                {state?.listFilter?.formInputIds?.map((item) => {
                  return (
                    <ListItem
                      sx={{
                        width: "auto",
                      }}
                      key={item?.indikatorId}
                    >
                      <Chip
                        key={item?.indikatorId}
                        value={item?.indikatorId}
                        label={item?.label}
                        variant="outlined"
                        //onClick={handleClick}
                        onDelete={() => DeleteIndikator(item)}
                      />
                    </ListItem>
                  );
                })}
              </Box>
            )}
            <BarChart
              id={state.type}
              chartData={userData}
              options={{
                hoverOffset: 8,
                layout: {
                  padding: {
                    left: 50,
                    right: 0,
                    top: 0,
                    bottom: 0,
                  },
                },
                plugins: {
                  tooltip: {
                    callbacks: {
                      labelPointStyle: function (context) {
                        return {
                          pointStyle: "triangle",
                          rotation: 0,
                        };
                      },
                    },
                  },
                },
              }}
            />
          </div>
        ) : state.type === "line" ? (
          <div
            ref={printRef}
            style={{
              width: "1100px",
              padding: "30px",
              borderRadius: "15px",
              backgroundColor: "#f5f8fc",
            }}
          >
            {stateChipListBox && (
              <Box
                sx={{ display: "flex", flexWrap: "wrap", flexDirection: "row" }}
              >
                {state?.listFilter?.formInputIds?.map((item) => {
                  return (
                    <ListItem sx={{ width: "auto" }} key={item?.indikatorId}>
                      <Chip
                        key={item?.indikatorId}
                        value={item?.indikatorId}
                        label={item?.label}
                        variant="outlined"
                        //onClick={handleClick}
                        onDelete={() => DeleteIndikator(item)}
                      />
                    </ListItem>
                  );
                })}
              </Box>
            )}
            <LineChart chartData={userData} />
          </div>
        ) : state.type === "pie" ? (
          <div>
            {stateChipListBox && (
              <Box
                sx={{ display: "flex", flexWrap: "wrap", flexDirection: "row" }}
              >
                {state?.listFilter?.formInputIds?.map((item) => {
                  return (
                    <ListItem sx={{ width: "auto" }} key={item?.indikatorId}>
                      <Chip
                        key={item?.indikatorId}
                        value={item?.indikatorId}
                        label={item?.label}
                        variant="outlined"
                        //onClick={handleClick}
                        onDelete={() => DeleteIndikator(item)}
                      />
                    </ListItem>
                  );
                })}
              </Box>
            )}
            <div
              ref={printRef}
              style={{
                padding: "30px",
                //height: "550px",
                borderRadius: "15px",
                backgroundColor: "#f5f8fc",
                width: "600px",
              }}
            >
              <PieChart
                id={state.type}
                chartData={userData}
                options={{
                  responsive: true,
                  plugins: {
                    tooltip: {
                      enabled: true,
                      callbacks: {
                        label: function (value) {
                          let sum = 0;
                          let dataArr = value.dataset.data;
                          dataArr.map((data) => {
                            if (data) {
                              sum += parseInt(data);
                            }
                          });
                          let percentage =
                            (
                              parseInt(parseFloat(value.parsed) * 100) / sum
                            ).toFixed(2) + "%";

                          let label =
                            " " +
                              value.label +
                              ": " +
                              value.formattedValue +
                              " ( " +
                              percentage +
                              " )" || "";
                          return label;
                        },
                      },
                    },
                  },
                }}
              />
            </div>
          </div>
        ) : null}
      </div>
      <ChartModalEditor
        open={openChartModal}
        onClose={handleCloseChartModal}
        formname="Raport / Grafik"
        isstatusnew={isStatusNewChart ? 1 : 0}
        data={state}
      ></ChartModalEditor>
      <SearchModal
        open={openSearchModal}
        onClose={handleOpenSearchModal}
        onSelectForm={handleOnSelectForm}
      ></SearchModal>
    </ContainerAnalyst>
  );
}
