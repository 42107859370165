import * as React from "react";
import { Box, MenuItem, Button } from "@mui/material";
import { useLocation } from "react-router-dom";
import { getLogsFormSubmissionById } from "../../store/formInputesSubmission";
import { useDispatch, useSelector } from "react-redux";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarExportContainer,
} from "@mui/x-data-grid";
import {
  convertDataTime,
  getName,
  getStatusName,
  getRoleName,
} from "./../helper/CustomLabel";
import { ButtonExportCustom } from "../UI/ButtonExport";
import gridLabels from "../Labels/GridLabels";
import { getFormSubmissionLogsExportData } from "../../store/formSubmission";
import { setLoading } from "../../store/loading";

const LogTable = (props) => {
  const dispatch = useDispatch();
  const { logsFormSubmission } = useSelector(
    (state) => state.formInputesSubmission
  );
  const exportLogsData = useSelector(
    (state) => state.formSubmission.exportLogsData
  );

  React.useEffect(() => {
    dispatch(getLogsFormSubmissionById({ id: props.id }));
    getDataExport();
  }, [dispatch]);

  const convertJson = (stringData) => {
    const outpute = JSON.parse(stringData);
    return outpute;
  };

  const MyIclaExportMenuItem = ({ hideMenu }) => (
    <Button
      button
      onClick={() => {
        // get and export the data as you want
        //getDataExport();
        hideMenu();
      }}
      href={`data:text/csv;charset=utf-8,${escape(exportLogsData)}`}
      download={"Logs_" + props?.title + ".csv"}
    >
      Eksporto si CSV
    </Button>
  );

  const getDataExport = () => {
    dispatch(setLoading(true));
    dispatch(getFormSubmissionLogsExportData({ id: props.id }))
      .unwrap()
      .then(() => {
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };

  function CustomToolbar({ ...other }) {
    return (
      <GridToolbarContainer {...other}>
        {/* <GridToolbarExportContainer
          {...other}
          href={`data:text/csv;charset=utf-8,${escape(exportLogsData)}`}
          download={"Logs_" + props?.title + ".csv"}
        >
          <GridToolbarExport
            printOptions={{ disableToolbarButton: true }}
            csvOptions={{
              fileName: "Logs " + props?.title,
          
            }}
          ></GridToolbarExport>
          <MyIclaExportMenuItem />
        </GridToolbarExportContainer> */}
        <ButtonExportCustom
          href={`data:text/csv;charset=utf-8,${escape(exportLogsData)}`}
          download={"Logs_" + props?.title + ".csv"}
        ></ButtonExportCustom>
      </GridToolbarContainer>
    );
  }
  const columnValue = [
    {
      field: "user",
      headerName: "Përdoruesi",
      width: 200,
      renderCell: (params) => params.row?.user?.username,
      valueGetter: (params) => params.row?.user?.username,
    },
    {
      field: "name",
      headerName: "Emër Mbiemër",
      width: 200,
      renderCell: (params) =>
        getName(params.row?.user?.firstName, params.row?.user?.lastName),
      valueGetter: (params) =>
        getName(params.row?.user?.firstName, params.row?.user?.lastName),
    },
    {
      field: "roli",
      headerName: "Roli",
      width: 200,
      renderCell: (params) => getRoleName(params.row?.user?.role),
      valueGetter: (params) => getRoleName(params.row?.user?.role),
    },
    {
      field: "time",
      headerName: "Data / Ora",
      width: 200,
      renderCell: (params) => convertDataTime(params.row.actionDate),
      valueGetter: (params) => convertDataTime(params.row.actionDate),
    },
    {
      field: "comment",
      headerName: "Koment",
      width: 300,
      renderCell: (params) => (params.row?.comment ? params.row.comment : "-"),
      valueGetter: (params) => convertDataTime(params.row.actionDate),
    },
    {
      field: "status",
      headerName: "Statusi",
      width: 100,
      paddingRight: "50px",
      "&.MuiDataGrid-root.MuiDataGrid-root .MuiDataGrid-cell": {
        paddingRight: "50px",
      },
      renderCell: (params) =>
        getStatusName(convertJson(params.row?.newState).status),
      valueGetter: (params) =>
        getStatusName(convertJson(params.row?.newState).status),
    },
  ];
  return (
    <Box
      sx={{
        backgroundColor: "#f5f8fc",
        padding: 2,
        height: "300px",
        borderRadius: "15px",
        width: "100%",
      }}
    >
      <DataGrid
        components={{
          Toolbar: CustomToolbar,
          customExports: [
            {
              translationKey: "exportAsICal",
              // some function that returns the content of the file
              createContent: (visibleColumns, visibleSortedRows) => {
                // TODO
                return "";
              },
              filename: "games-schedule.ical",
              type: "text/calendar",
            },
          ],
        }}
        disableColumnMenu
        localeText={gridLabels}
        hideFooterPagination
        hideFooter
        onRowClick={(params, event) => {
          // convertJson
          props.onSelectLog(convertJson(params.row.newState));
        }}
        sx={{
          minWidth: 650,
          paddingBottom: "20px",
          width: "100%",
          "&.MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-root .MuiDataGrid-cell":
            {
              padding: "0 0 0 20px",
            },
          "&.MuiDataGrid-root .MuiDataGrid-cell": {
            padding: "0 0 0 20px",
          },
          "&.MuiDataGrid-root.MuiDataGrid-root .MuiDataGrid-row": {
            marginTop: "5px",
            marginBottom: "5px",
            maxHeight: "40px !important",
            minHeight: "40px !important",
          },
          "&.MuiDataGrid-root.MuiDataGrid-root .MuiDataGrid-cell": {
            minHeight: "40px !important",
            maxHeight: "40px !important",
          },
        }}
        columns={columnValue}
        rows={logsFormSubmission ? logsFormSubmission : []}
        size="small"
        aria-label="a dense table"
      ></DataGrid>
    </Box>
  );
};

export default LogTable;
