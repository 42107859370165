import axios from "axios";
import authHeader from "./auth-header";
import { APP_URL } from "./http-common";

const URLEntityName = APP_URL + "form-input";

async function deleteFormInput(id) {
  await axios
    .delete(URLEntityName + "/" + id, { headers: authHeader() })
    .then((response) => {
      if (
        response.status === 200 ||
        response.status === 201 ||
        response.status === 204
      ) {
        return response;
      }
      return response;
    });
}
async function createFormInput(formInput) {
  await axios
    .post(URLEntityName, formInput, {
      headers: authHeader(),
    })
    .then((response) => {
      return response;
    });
}

async function updateFormInput(formInput) {
  await axios
    .put(URLEntityName + "/" + formInput.id, formInput, {
      headers: authHeader(),
    })
    .then((response) => {
      return response;
    });
}
const formInputesService = {
  createFormInput,
  deleteFormInput,
  updateFormInput,
};
export default formInputesService;
