import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";
import { setLoading } from "./loading";
import PerdroruesService from "../services/perdorues.service";

const initialState = {
  perdorues: [],
  totalElements: 0,
  totalPages: 0,
  size: 0,
  page: 0,
  searchValue: "",
  status: "all",
};
export const setSearch = createAsyncThunk(
  "users/setSearchValue",
  ({ searchValue }, thunkAPI) => {
    return {
      searchValue: searchValue,
    };
  }
);

export const getAll = createAsyncThunk(
  "users/getAll",
  async ({ page, searchValue, status = "all" }, thunkAPI) => {
    // thunkAPI.dispatch(setMessage(""));
    //thunkAPI.rejectWithValue();
    try {
      const data = await PerdroruesService.getAllUsers(
        page,
        searchValue,
        status
      );
      //thunkAPI.dispatch(setLoading(false));
      //  thunkAPI.rejectWithValue();
      return {
        perdorues: data.content,
        totalElements: data.totalElements,
        totalPages: data.totalPages,
        size: data.size,
        page: data.number,
      };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // thunkAPI.dispatch(setLoading(false));
      //thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getFilterUsersUsername = createAsyncThunk(
  "users/getFilterUsersUsername",
  async ({ username }, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    try {
      const data = await PerdroruesService.getFilterUsersUsername(username);
      thunkAPI.dispatch(setLoading(false));
      return { perdorues: data.content };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setLoading(false));
      thunkAPI.dispatch(setMessage({ message: message }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const createUser = createAsyncThunk(
  "users/create",
  async ({ user }, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    try {
      const data = await PerdroruesService.createUser(user);
      //thunkAPI.dispatch(getAll());
      thunkAPI.dispatch(getAll({ page: 0, searchValue: "" }));
      thunkAPI.dispatch(setLoading(false));
      return thunkAPI.rejectWithValue();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setLoading(false));
      thunkAPI.dispatch(setMessage({ message: message }));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const updateUser = createAsyncThunk(
  "users/update",
  async ({ user }, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    try {
      const data = await PerdroruesService.updateUser(user);
      //thunkAPI.dispatch(getAll());
      thunkAPI.dispatch(getAll({ page: 0, searchValue: "" }));
      thunkAPI.dispatch(setLoading(false));
      return thunkAPI.rejectWithValue();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      thunkAPI.dispatch(setLoading(false));
      thunkAPI.dispatch(setMessage({ message: message }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const deleteUser = createAsyncThunk(
  "users/delete",
  async ({ id }, thunkAPI) => {
    try {
      const data = await PerdroruesService.deleteUser(id);
      thunkAPI.dispatch(getAll({ page: 0, searchValue: "" }));
      return thunkAPI.rejectWithValue();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setLoading(false));
      thunkAPI.dispatch(setMessage({ message: message }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateToGlobalEditor = createAsyncThunk(
  "users/updateToGlobalEditor",
  async ({ user, page, searchValue }, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    try {
      const data = await PerdroruesService.updateToGlobalEditor(user);
      thunkAPI.dispatch(getAll({ page: page, searchValue: searchValue }));
      thunkAPI.dispatch(setLoading(false));
      return thunkAPI.rejectWithValue();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setLoading(false));
      thunkAPI.dispatch(setMessage({ message: message }));
      return thunkAPI.rejectWithValue();
    }
  }
);
const perdoruesSlice = createSlice({
  name: "perdorues",
  initialState,
  extraReducers: {
    [getAll.fulfilled]: (state, action) => {
      state.perdorues = action.payload.perdorues;
      state.totalElements = action.payload.totalElements;
      state.totalPages = action.payload.totalPages;
      state.size = action.payload.size;
      state.searchValue = action.payload.searchValue;
    },
    [getAll.rejected]: (state, action) => {
      state.perdorues = null;
      state.totalElements = 0;
      state.totalPages = 0;
      state.size = 0;
      state.searchValue = "";
    },
    [setSearch.fulfilled]: (state, action) => {
      state.searchValue = action.payload.searchValue;
    },
    [setSearch.rejected]: (state, action) => {
      state.searchValue = "";
    },
  },
});

const { reducer } = perdoruesSlice;
export default reducer;
