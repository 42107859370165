import * as React from "react";
import { Avatar, Drawer, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getFormSubmissionSummary } from "../../store/formSubmission";
import { getName, getRoleName } from "../helper/CustomLabel";

const ItemCard = (props) => {
  return (
    <Box
      sx={{
        width: "200px",
        height: "200px",
        background: "#fff",
        borderRadius: "25px",
        m: 1,
      }}
    >
      <Typography
        sx={{
          mt: "8px",
          ml: "24px",
          fontSize: "80px",
          color: props.color,
          textAlign: "left",
          fontFamily: "Basier Circle",
        }}
      >
        {props.value}
      </Typography>
      <Typography
        sx={{
          ml: "24px",
          mr: "80px",
          color: "#091B3D",
          fontFamily: "Basier Circle",
          fontSize: "16px",
          lineHeight: "20px",
          fontWeight: "400",
          textAlign: "left",
          mt: "10px",
        }}
      >
        {props.title}
      </Typography>
    </Box>
  );
};

const PanelRight = (props) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);

  const formSubmissionSummary = useSelector(
    (state) => state.formSubmission.formSubmissionSummary
  );

  const [userState, setUserState] = useState(user);
  const [summaryState, setSummaryState] = useState(formSubmissionSummary);
  useEffect(() => {
    setUserState(user);
    //dispatch(getFormSubmissionSummary());
    setSummaryState(formSubmissionSummary);
  }, [user, formSubmissionSummary]);
  return (
    <Drawer
      variant="permanent"
      anchor="right"
      sx={{
        width: "220px",
        zIndex: 1,
        //backgroundColor: "#F1F3F9",
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          zIndex: -1,
          width: "330px",
          backgroundColor: "#EBF0FA",
          color: "white",
          boxSizing: "border-box",
          borderLeft: "transparent",
        },
      }}
    >
      <Box
        sx={{
          paddingTop: "30px",
          paddingBottom: "50px",
          color: "black",
          textAlign: "right",
          paddingRight: "70px",
          display: "flex",
          alignItems: "center",
          flexDirection: "row-reverse",
        }}
      >
        {/* <Box sx={{ display: "flex", alignItems: "center" }}>
          <FolderIcon sx={{ color: "#B2D4FF" }}></FolderIcon>
          {props.header}
        </Box> */}
        <Avatar sx={{ backgroundColor: "primary.main" }}></Avatar>
        <Stack sx={{ textAlign: "center" }}>
          <Typography sx={{ mr: "6px", color: "primary.main" }}>
            {userState?.firstName === null || userState?.firstName === ""
              ? userState?.username
              : getName(userState?.firstName, userState?.lastName)}
          </Typography>
          {/* <Typography sx={{ mr: "6px", color: "primary.main" }}>
            {"(" + getRoleName(userState?.role) + ")"}
          </Typography> */}
        </Stack>
      </Box>
      <Box sx={{ height: "50px", textAlign: "center", padding: "20px" }}>
        <Typography variant="h5">Përmbledhja për Drejtorinë</Typography>
      </Box>
      <Box
        sx={{
          flexDirection: "column",
          display: "flex",
          textAlign: "center",
          alignItems: "center",
          borderTop: "20px",
          m: 1,
        }}
      >
        <ItemCard
          title="forma të paplotësuara"
          value={
            summaryState.ACTIVE
              ? parseInt(summaryState.ACTIVE) < 100
                ? ("0" + parseInt(summaryState.ACTIVE)).slice(-2)
                : "+99"
              : "00"
          }
          color="#45C8D0"
        ></ItemCard>
        <ItemCard
          title="forma në draft"
          value={
            summaryState.DRAFT
              ? parseInt(summaryState.DRAFT) < 100
                ? ("0" + parseInt(summaryState.DRAFT)).slice(-2)
                : "+99"
              : "00"
          }
          color="#CD3728"
        ></ItemCard>
        <ItemCard
          title="forma të plotësuara"
          value={
            summaryState.SUBMITTED
              ? parseInt(summaryState.SUBMITTED) < 1000
                ? ("0" + parseInt(summaryState.SUBMITTED)).slice(-3)
                : "+999"
              : "00"
          }
          color="#8C54FF"
        ></ItemCard>
      </Box>
    </Drawer>
  );
};

export default PanelRight;
