import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Box, Checkbox, InputLabel, Stack } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getAllUnit } from "../../store/njesiRaportuese";
import { setLoading } from "../../store/loading";
import SelectFilter from "../UI/SelectFilter";
import { getByReportingUnit } from "../../store/formTemplate";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import TextFieldDateFilter from "../UI/TextFieldDateFilter";
import { id, sq } from "date-fns/locale";
import { getDateFormat, getStatusName } from "../helper/CustomLabel";
import ContainerAnalyst from "../UI/ContainerAnalyst";
import { getFormSubmissionAll } from "../../store/formSubmission";
import { useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import gridLabels from "../Labels/GridLabels";
import CsvToJson from "../helper/CsvToJson";
import periodText from "../helper/PeriodText";
import BoxRowButtonsIcon from "../UI/BoxRowButtonsIcon";
import RowButtonIcon from "../UI/RowButtonIcon";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { setBackState } from "../../store/backState";
import { setFormSubmisionFilter } from "../../store/formSubmisionFilter";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export default function FormatePlotesuaraTable2() {
  const { formSubmissionAll, totalElements, totalPages, size, pageCurrent } =
    useSelector((state) => state.formSubmission);
  const { backState } = useSelector((state) => state.backState);
  const { formSubmisionFilter } = useSelector(
    (state) => state.formSubmisionFilter
  );

  const { njesiRaportuese } = useSelector((state) => state.njesiRaportuese);
  const { formTemplate } = useSelector((state) => state.formTemplate);
  const user = useSelector((state) => state.auth.user);
  const isEditor = user?.role === "ROLE_EDITOR";
  const isOperator = user?.role === "ROLE_OPERATOR";
  const isMinor = user?.role === "ROLE_MINOR_EXECUTIVE";
  const isSoftExecutive = user?.role === "ROLE_SOFT_EXECUTIVE";
  const isAnalist = user?.role === "ROLE_ANALYST";
  const [page, setPage] = React.useState(
    backState.formSubmission ? pageCurrent : 0
  );

  const dispatch = useDispatch();

  const [state, setState] = React.useState(
    backState.formSubmission
      ? formSubmisionFilter
      : {
          reportingUnitId: "",
          formId: "",
          dateStart: "",
          dateEnd: "",
          analystCheck: null,
        }
  );

  const searchStateUnit = React.useRef("");
  const searchStateForm = React.useRef("");
  const searchStateAnalystCheck = React.useRef("");
  const [searchValueServerUnit, setSearchValueServerUnit] = React.useState("");
  const [searchValueServerForm, setSearchValueServerForm] = React.useState("");
  const [anchorElUnit, setAnchorElUnit] = React.useState(null);
  const [anchorElForm, setAnchorElForm] = React.useState(null);
  const [anchorElAnalystCheck, setAnchorElAnalystCheck] = React.useState(null);

  const handleClickUnit = (event) => {
    dispatch(getAllUnit({ page: 0, searchValue: "", filterSelect: true }));
    setState({
      ...state,
      formId: "",
    });
    setAnchorElUnit(event.currentTarget);
  };
  const handleClickForm = (event) => {
    getAllDataFormUnit();
    setState({
      ...state,
    });
    setAnchorElForm(event.currentTarget);
  };
  const handleClickStartDate = (event) => {
    setState({
      ...state,
      //dateEnd: "",
    });
  };

  const handleClickAnalystCheck = (event) => {
    setState({
      ...state,
    });
    setAnchorElAnalystCheck(event.currentTarget);
  };
  const handleCloseUnit = () => {
    setAnchorElUnit(null);
  };
  const handleCloseForm = () => {
    setAnchorElForm(null);
  };

  const handleCloseAnalystCheck = () => {
    setAnchorElAnalystCheck(null);
  };

  const openUnit = Boolean(anchorElUnit);
  const openForm = Boolean(anchorElForm);
  const openAnalystCheck = Boolean(anchorElAnalystCheck);

  const handleChangePage = (event, newPage) => {
    setPage(event);
  };
  const navigate = useNavigate();

  const searchItemsUnit = (e) => {
    const searchValue = e.target.value;
    if (searchValue !== "") {
      searchStateUnit.current.value = searchValue;
      let filterTimeout;
      filterTimeout = setTimeout(() => {
        setSearchValueServerUnit(searchStateUnit.current.value);
      }, 1000);
    } else {
      setSearchValueServerUnit("");
      dispatch(getAllUnit({ page: 0, searchValue: "", filterSelect: true }));
    }
  };

  const searchItemsForm = (e) => {
    const searchValue = e.target.value;
    if (searchValue !== "") {
      searchStateForm.current.value = searchValue;
      let filterTimeout;
      filterTimeout = setTimeout(() => {
        setSearchValueServerForm(searchStateForm.current.value);
      }, 1000);
    } else {
      setSearchValueServerForm("");
    }
  };

  React.useEffect(() => {
    getAllDataUnit();
  }, [dispatch, searchValueServerUnit]);

  const getAllDataUnit = () => {
    dispatch(setLoading(true));
    dispatch(
      getAllUnit({
        page: 0,
        searchValue: searchValueServerUnit,
        size: 1000,
        filterSelect: true,
      })
    )
      .unwrap()
      .then(() => {
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };

  const getAllDataFormUnit = () => {
    dispatch(setLoading(true));
    dispatch(
      getByReportingUnit({
        unit: state.reportingUnitId,
        searchValue: searchValueServerForm,
      })
    )
      .unwrap()
      .then(() => {
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };

  React.useEffect(() => {
    getAllData();
  }, [dispatch, page, state]);

  React.useEffect(() => {
    if (isEditor || isOperator) {
      backState.formSubmission
        ? setState(formSubmisionFilter)
        : setState({
            ...state,
            reportingUnitId: user?.reportingUnit.id,
            formId: "",
          });
    }
  }, []);

  const getAllData = () => {
    dispatch(setLoading(true));
    dispatch(setFormSubmisionFilter(state));
    dispatch(getFormSubmissionAll({ page: page, searchValue: state }))
      .unwrap()
      .then(() => {
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };

  const [filterModel, setFilterModel] = React.useState({
    items: [
      {
        columnField: "rating",
        operatorValue: ">",
        value: "2.5",
      },
    ],
  });

  const currentlySelected = (e) => {
    navigate("/formgrid/inputet", {
      state: {
        stateFormInputId: e.row.id,
        nameForm: e.row.form.name,
        stateForm: "old",
        analystCheck: e.row?.analystCheck,
        status: e.row?.status,
        company: e.row?.company,
      },
    });
    dispatch(setFormSubmisionFilter(state));
    dispatch(setBackState({ formSubmission: true, formTemplate: false }));
  };

  const CheckIcon = () => {
    return (
      <CheckCircleIcon
        sx={{
          "&.MuiSvgIcon-root": {
            width: "20px",
            height: "20px",
          },
          color: "#45C8D0",
        }}
      />
    );
  };

  return (
    <ContainerAnalyst
      header={
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              alignContent: "flex-start",
              mb: "20px",
            }}
          >
            {user?.role === "ROLE_ANALYST" ||
            user?.role === "ROLE_EXECUTIVE" ||
            user?.role === "ROLE_SOFT_EXECUTIVE" ||
            isMinor ? (
              <Stack spacing={2} sx={{ mr: "10px" }}>
                <InputLabel>Drejtoria</InputLabel>
                <SelectFilter
                  textfield
                  name="reportingUnitId"
                  id="reportingUnitId"
                  label="Drejtoria"
                  placeholder="Kërko Drejtori"
                  noOptionsText="Nuk ka Drejtori"
                  value={state.reportingUnitId}
                  onClick={handleClickUnit}
                  source={njesiRaportuese}
                  open={openUnit}
                  anchorEl={anchorElUnit}
                  onClickAway={handleCloseUnit}
                  onClose={(event, reason) => {
                    if (reason === "escape") {
                      handleCloseUnit();
                    }
                  }}
                  onChange={(event, newValue, reason) => {
                    if (
                      event.type === "keydown" &&
                      event.key === "Backspace" &&
                      reason === "removeOption"
                    ) {
                      return;
                    }
                    setState({
                      ...state,
                      reportingUnitId: newValue.id ? newValue.id : "",
                      formId: "",
                    });
                    setPage(0);
                    handleCloseUnit();
                    getAllDataFormUnit();
                  }}
                  onchangesearch={searchItemsUnit}
                  inputRef={searchStateUnit}
                ></SelectFilter>
              </Stack>
            ) : null}

            <Stack spacing={2} sx={{ mr: "10px" }}>
              <InputLabel>Forma</InputLabel>
              <SelectFilter
                textfield
                disabledfilter={!state.reportingUnitId}
                name="formId"
                id="formId"
                label="Forma"
                placeholder="Kërko Formë"
                noOptionsText="Nuk ka formë"
                value={state.formId}
                onClick={handleClickForm}
                source={formTemplate}
                open={openForm}
                anchorEl={anchorElForm}
                onClickAway={handleCloseForm}
                onClose={(event, reason) => {
                  if (reason === "escape") {
                    handleCloseForm();
                  }
                }}
                onChange={(event, newValue, reason) => {
                  if (
                    event.type === "keydown" &&
                    event.key === "Backspace" &&
                    reason === "removeOption"
                  ) {
                    return;
                  }
                  setState({
                    ...state,
                    formId: newValue.id ? newValue.id : "",
                  });
                  setPage(0);
                  handleCloseForm();
                }}
                onchangesearch={searchItemsForm}
                inputRef={searchStateForm}
              ></SelectFilter>
            </Stack>

            <Stack spacing={2} sx={{ mr: "10px" }}>
              <InputLabel>Datë Fillimi</InputLabel>
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={sq}>
                <DatePicker
                  value={state.dateStart}
                  views={["year", "month"]}
                  // disabled={state.listFilter?.formInputIds.length > 0}
                  onChange={(newValue) => {
                    var first_date = new Date(
                      newValue.getFullYear(),
                      newValue.getMonth(),
                      1
                    );
                    setState({
                      ...state,
                      dateStart: first_date ? getDateFormat(first_date) : "",
                      //dateEnd: "",
                    });
                    setPage(0);
                  }}
                  renderInput={(params) => (
                    <TextFieldDateFilter
                      onClick={handleClickStartDate}
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: "muaj viti",
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            </Stack>

            <Stack spacing={2} sx={{ mr: "10px" }}>
              <InputLabel>Datë Mbarimi</InputLabel>
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={sq}>
                <DatePicker
                  value={state.dateEnd}
                  views={["year", "month"]}
                  onChange={(newValue) => {
                    var last_date = new Date(
                      newValue.getFullYear(),
                      newValue.getMonth() + 1,
                      0
                    );
                    setState({
                      ...state,
                      dateEnd: last_date ? getDateFormat(last_date) : "",
                    });
                    setPage(0);
                  }}
                  renderInput={(params) => (
                    <TextFieldDateFilter
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: "muaj viti",
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            </Stack>
            {isAnalist && (
              <Stack spacing={2} sx={{ mr: "10px" }}>
                <InputLabel>Kontrolluar</InputLabel>
                <SelectFilter
                  textfield
                  name="analystCheck"
                  id="analystCheck"
                  label="Kontrolluar"
                  placeholder="Kërko Alternativë"
                  // noOptionsText="Nuk ka forme"
                  value={state.analystCheck}
                  onClick={handleClickAnalystCheck}
                  source={[
                    { id: "true", label: "Po" },
                    { id: "false", label: "Jo" },
                    { id: "all", label: "Të gjitha" },
                  ]}
                  open={openAnalystCheck}
                  anchorEl={anchorElAnalystCheck}
                  onClickAway={handleCloseAnalystCheck}
                  onClose={(event, reason) => {
                    if (reason === "escape") {
                      handleCloseAnalystCheck();
                    }
                  }}
                  onChange={(event, newValue, reason) => {
                    if (
                      event.type === "keydown" &&
                      event.key === "Backspace" &&
                      reason === "removeOption"
                    ) {
                      return;
                    }
                    setState({
                      ...state,
                      analystCheck: newValue.id ? newValue.id : null,
                    });
                    setPage(0);
                    searchStateAnalystCheck.current.id = newValue.id;
                    handleCloseAnalystCheck();
                  }}
                  inputRef={searchStateAnalystCheck}
                ></SelectFilter>
              </Stack>
            )}
          </Box>
        </Box>
      }
    >
      <div
        style={{ height: "100%", width: "100%", padding: "0px 20px 0px 20px" }}
      >
        <DataGrid
          sx={{
            paddingTop: 0,
            paddingLeft: 0,
            paddingRight: 0,
            "& .MuiDataGrid-root.MuiDataGrid-root.MuiDataGrid-row": {
              minHeight: "60px !important",
              paddingRight: "20px",
            },
          }}
          labelRowsPerPage="Artikuj për faqe"
          disableSelectionOnClick
          columns={[
            {
              field: "form",
              headerName: "Titulli Formës",
              width: 450,
              editable: false,
              renderCell: (params) =>
                !params.row.form ? "" : params.row.form.name,
            },
            {
              field: "periudha",
              headerName: "Periudha",
              width: 200,
              editable: false,
              renderCell: (params) =>
                !params.row.scheduledStartDate
                  ? ""
                  : periodText(
                      params.row.scheduledStartDate,
                      params.row.scheduledEndDate,
                      params.row?.form?.schedule === "MONTHLY" ? true : false
                    ),
            },
            {
              field: "status",
              headerName: "Statusi",
              width: 100,
              editable: false,
              renderCell: (params) =>
                getStatusName(params.row.status ? params.row.status : ""),
            },
            {
              field: "submittedDate",
              headerName: "Data e dërgimit",
              width: 150,
              editable: false,
              renderCell: (params) =>
                params.row.submittedDate
                  ? converDataFormat(params.row.submittedDate)
                  : "-",
            },
            {
              field: "company",
              headerName: "Kompania",
              width: 150,
              editable: false,
              renderCell: (params) =>
                params.row?.company ? params.row?.company?.name : "-",
            },
            isAnalist && {
              headerName: "",
              overflow: "hidden",
              field: "pristine",
              editable: false,
              flex: 1,

              headers: false,
              renderHeader: () => {},
              renderCell: (params) => (
                <BoxRowButtonsIcon>
                  {/* <RowButtonIcon> */}
                  {params?.row?.pristine && (
                    <FiberManualRecordIcon
                      sx={{
                        color: "#B90000",
                        "&.MuiSvgIcon-root": {
                          height: "16px",
                          width: "16px",
                        },
                      }}
                    />
                  )}
                  {/* </RowButtonIcon> */}
                </BoxRowButtonsIcon>
              ),
            },
            isAnalist && {
              headerName: "",
              overflow: "hidden",
              field: "analystCheck",
              editable: false,
              flex: 1,

              headers: false,
              renderHeader: () => {},
              renderCell: (params) => (
                <BoxRowButtonsIcon>
                  {/* <RowButtonIcon> */}
                  {params?.row?.analystCheck && <CheckIcon />}
                  {/* </RowButtonIcon> */}
                </BoxRowButtonsIcon>
              ),
            },

            {
              headerName: "",
              overflow: "hidden",
              field: "editim",
              editable: false,
              flex: 1,
              justifyContent: "flex-end",
              disableClickEventBubbling: true,
              align: "right",
              background: "red",
              headers: false,
              minWidth: "50px",
              renderHeader: () => {},
              renderCell: (params) => (
                <BoxRowButtonsIcon>
                  <RowButtonIcon onClick={currentlySelected}>
                    <RemoveRedEyeOutlinedIcon />
                  </RowButtonIcon>
                </BoxRowButtonsIcon>
              ),
            },
          ]}
          rows={formSubmissionAll ? formSubmissionAll : []}
          density="comfortable"
          editMode="row"
          disableColumnMenu
          localeText={gridLabels}
          componentsProps={{
            pagination: {
              labelRowsPerPage: "Rreshta per Faqe",
              showLastButton: true,
              showFirstButton: true,
            },
          }}
          // components={{
          //   Toolbar: CustomToolbar,
          // }}
          onCellClick={currentlySelected}
          filterModel={filterModel}
          onFilterModelChange={(newFilterModel) =>
            setFilterModel(newFilterModel)
          }
          page={page}
          onPageChange={handleChangePage}
          count={totalPages}
          rowCount={totalElements}
          pageSize={size}
          pagination
          paginationMode="server"
        />
      </div>
      {/* </div> */}
    </ContainerAnalyst>
  );
}

const converDataFormat = (dataValue) => {
  const dateOutput = new Date(dataValue);

  return dateOutput.toJSON().split("T")[0];
};
