import { Edit } from "@mui/icons-material";
import { IconButton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import classes from "./StartingPageContent.module.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useLocation, useNavigate } from "react-router-dom";

const StartingPageContent = (props) => {
  const location = useLocation();
  const navigate = useNavigate();

  const onBackNavigation = () => {
    navigate(-1);
  };
  return (
    <div className={classes.starting}>
      <Box>
        {props.back ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              textAlign: "left",
              alignSelf: "left",
              marginLeft: props.back ? "-40px" : 0,
            }}
          >
            <IconButton
              onClick={props.onClick ? props.onClick : onBackNavigation}
              sx={{ color: "#5c56a4" }}
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography
              variant="h5"
              sx={{ color: "#808EB1", fontSize: "16px" }}
            >
              ERE Platforme | {props.pageName}
            </Typography>
          </Box>
        ) : null}
        <Typography variant="h3">{props.pageName}</Typography>
        <Typography variant="h5" sx={{ mt: "10px", color: "#979797" }}>
          Përshëndetje, Mirësevini në ERE Platforme!
        </Typography>
      </Box>
      <Box sx={{ py: "45px" }}> {props.children}</Box>
    </div>
  );
};

export default StartingPageContent;
