import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";
import { setLoading } from "./loading";
import FormTemplateService from "../services/formTemplate.service";
import { useSelector } from "react-redux";

const initialState = {
  formTemplate: [],
  totalElements: 0,
  totalPages: 0,
  size: 0,
  page: 0,
  searchValue: "",
  unitId: "",
  currentPage: 0,
  searchValueCurrent: "",
};
export const getAllFormTemplate = createAsyncThunk(
  "form/getAllFormTemplate",
  async ({ page, searchValue, limit }, thunkAPI) => {
    try {
      const data = await FormTemplateService.getAllFormTemplate(
        page,
        searchValue,
        limit
      );
      return {
        formTemplate: data.content,
        totalElements: data.totalElements,
        totalPages: data.totalPages,
        size: data.size,
        currentPage: data.number,
        searchValueCurrent: searchValue,
      };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getAllFormTemplateSearch = createAsyncThunk(
  "form/getAllFormTemplateSearch",
  async ({ searchValue }, thunkAPI) => {
    try {
      if (searchValue !== "") {
        const data = await FormTemplateService.getAllFormTemplateSearch(
          searchValue
        );
        return {
          formTemplate: data === undefined ? [] : data,
          searchValueCurrent: data === undefined ? searchValue : "",
        };
      } else {
        return {
          formTemplate: [],
          searchValueCurrent: "",
        };
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const cleanFormTemplateSearch = createAsyncThunk(
  "form/cleanFormTemplateSearch",
  async (thunkAPI) => {
    try {
      return {
        formTemplate: [],
        searchValueCurrent: "",
      };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getByReportingUnit = createAsyncThunk(
  "form/getByReportingUnit",
  async ({ unit, searchValue }, thunkAPI) => {
    try {
      const data = await FormTemplateService.getByReportingUnit(
        unit,
        searchValue
      );
      return {
        formTemplate: data,
        totalElements: data.length,
        totalPages: data.length / 10,
        size: 10,
      };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const createFormTemplate = createAsyncThunk(
  "form/create",
  async ({ formTemplate }, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    try {
      const data = await FormTemplateService.createFormTemplate(formTemplate);
      thunkAPI.dispatch(getAllFormTemplate({ page: 0, searchValue: "" }));
      thunkAPI.dispatch(setLoading(false));
      return thunkAPI.rejectWithValue();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setLoading(false));
      thunkAPI.dispatch(setMessage({ message: message }));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const updateFormTemplate = createAsyncThunk(
  "form/update",
  async ({ formTemplate, currentPage, searchValueCurrent }, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    try {
      const data = await FormTemplateService.updateFormTemplate(formTemplate);
      thunkAPI.dispatch(
        getAllFormTemplate({
          page: currentPage ? currentPage : 0,
          searchValue: searchValueCurrent ? searchValueCurrent : "",
        })
      );
      thunkAPI.dispatch(setMessage("ok", data));
      thunkAPI.dispatch(setLoading(false));
      return thunkAPI.rejectWithValue();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setLoading(false));
      thunkAPI.dispatch(setMessage({ message: message }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const deleteFormTemplate = createAsyncThunk(
  "form/delete",
  async ({ id }, thunkAPI) => {
    try {
      const data = await FormTemplateService.deleteFormTemplate(id);
      thunkAPI.dispatch(setMessage({ message: "Rekordi u fshi me sukses!" }));
      thunkAPI.dispatch(getAllFormTemplate({ page: 0, searchValue: "" }));
      return thunkAPI.rejectWithValue();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message }));
      return thunkAPI.rejectWithValue();
    }
  }
);

const formTemplateSlice = createSlice({
  name: "formTemplate",
  initialState,
  extraReducers: {
    [getAllFormTemplate.fulfilled]: (state, action) => {
      state.formTemplate = action.payload.formTemplate;
      state.totalElements = action.payload.totalElements;
      state.totalPages = action.payload.totalPages;
      state.size = action.payload.size;
      state.searchValue = action.payload.searchValue;
      state.currentPage = action.payload.currentPage;
      state.searchValueCurrent = action.payload.searchValueCurrent;
    },
    [getAllFormTemplate.rejected]: (state, action) => {
      state.formTemplate = [];
      state.totalElements = 0;
      state.totalPages = 0;
      state.size = 0;
      state.searchValue = "";
    },
    [getAllFormTemplateSearch.fulfilled]: (state, action) => {
      state.formTemplate = action.payload.formTemplate;
      state.searchValueCurrent = action.payload.searchValueCurrent;
    },
    [getAllFormTemplateSearch.rejected]: (state, action) => {
      state.formTemplate = [];
      state.searchValueCurrent = "";
    },
    [cleanFormTemplateSearch.fulfilled]: (state, action) => {
      state.formTemplate = action.payload.formTemplate;
      state.searchValueCurrent = action.payload.searchValueCurrent;
    },
    [getByReportingUnit.fulfilled]: (state, action) => {
      state.formTemplate = action.payload.formTemplate;
      state.totalElements = action.payload.totalElements;
      state.totalPages = action.payload.totalPages;
      state.size = action.payload.size;
      state.searchValue = action.payload.searchValue;
    },
    [getByReportingUnit.rejected]: (state, action) => {
      state.formTemplate = [];
      state.totalElements = 0;
      state.totalPages = 0;
      state.size = 0;
      state.searchValue = "";
    },
  },
});

const { reducer } = formTemplateSlice;
export default reducer;
