import React from "react";
import { useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Box, IconButton } from "@mui/material";
import BarChart from "./BarChart";
import LineChart from "./LineChart";
import PieChart from "./PieChart";
import { useDispatch, useSelector } from "react-redux";
import ContainerAnalyst from "../UI/ContainerAnalyst";
import { backgroundColorList, colorsBorderGraph } from "./GraphProps";
import { useNavigate } from "react-router-dom";
import {
  deleteGraphDashboard,
  getGraphDashboardData,
} from "../../store/graphDashboard";
import { Delete, Edit } from "@mui/icons-material";
import DialogDelete from "../UI/DialogDelete";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export function DashboardRaports() {
  const { formInputesById } = useSelector((state) => state.formInputesById);
  const { graphDashboard } = useSelector((state) => state.graphDashboard);
  const user = useSelector((state) => state.auth.user);
  const isAnalist = user?.role === "ROLE_ANALYST";
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  const [stateDialogDelete, setStateDialogDelete] = React.useState(false);
  const [selectRowId, setSelectRowId] = React.useState("");
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [graphList, setGraphList] = React.useState([]);

  const definitionGraphList = () => {
    const graphListTemp = [];
    for (var i = 0; i < graphDashboard.length; i++) {
      // SINGLE_LINE_CHART & BAR case
      if (
        graphDashboard[i].type === "SINGLE_LINE_CHART" ||
        graphDashboard[i].type === "BAR" ||
        graphDashboard[i].type === "LINE"
      ) {
        let indikatorList = [];
        const objectConst = [];
        const labelsData = graphDashboard[i]?.data?.map(
          (data) => data["graphDate"]
        );

        for (var d = 0; d < graphDashboard[i]?.data.length; d++) {
          const objectNew = graphDashboard[i]?.data[d];

          const listaData = Object?.values(objectNew);
          const listaLabels = Object?.keys(objectNew);

          const firstElement1 = listaData.shift();
          const firstElement2 = listaLabels.shift();

          listaData.splice(-1, 1);
          listaLabels.splice(-1, 1);

          let listObj = listaLabels;
          indikatorList = [...indikatorList, ...listObj];
        }

        var unique = [...new Set(indikatorList)];

        for (var j = 0; j < unique.length; j++) {
          objectConst.push({
            label: unique[j],
            data: graphDashboard[i].data.map((data) =>
              data[unique[j]] == undefined ? "" : data[unique[j]]
            ),
            backgroundColor: backgroundColorList[j],
            borderColor: colorsBorderGraph[j],
            borderWidth: 4,
          });
        }

        const dashBoardItem = {
          labels: labelsData,
          datasets: objectConst,
        };

        const optionsChartItem = {
          responsive: true,
          plugins: {
            title: {
              display: true,
              text: graphDashboard[i]?.name,
              font: {
                size: 20,
              },
            },
            legend: {
              display: true,
              position: "bottom",
            },
          },
        };
        graphListTemp.push({
          id: graphDashboard[i]?.id,
          order: graphDashboard[i]?.order,
          type: graphDashboard[i]?.type,
          optionsChart: optionsChartItem,
          chart: dashBoardItem,
        });
      }
      // PIE case
      if (graphDashboard[i].type === "PIE") {
        const objectNew = graphDashboard[i]?.data[0];

        //delete objectNew?.date;
        // delete objectNew?.graphDate;

        const listaData = Object?.values(graphDashboard[i]?.data[0]);
        const listaLabels = Object?.keys(graphDashboard[i]?.data[0]);

        const firstElement1 = listaData.shift();
        const firstElement2 = listaLabels.shift();

        listaData.splice(-1, 1);
        listaLabels.splice(-1, 1);

        if (graphDashboard[i]?.data[0]) {
          const dashBoardItem = {
            labels: listaLabels,
            datasets: [
              {
                label: graphDashboard[i]?.name,
                data: listaData,
                backgroundColor: backgroundColorList,
              },
            ],
          };

          const optionsChartItem = {
            responsive: true,
            plugins: {
              title: {
                display: true,
                text: graphDashboard[i]?.name,
                font: {
                  size: 20,
                },
              },
              legend: {
                display: true,
                position: "bottom",
              },
              tooltip: {
                enabled: true,
                callbacks: {
                  label: function (value) {
                    let sum = 0;

                    let dataArr = value.dataset.data;
                    dataArr.map((data) => {
                      if (data) {
                        sum += parseInt(data);
                      }
                    });

                    let percentage =
                      (parseInt(parseFloat(value.parsed) * 100) / sum).toFixed(
                        2
                      ) + "%";

                    let label =
                      " " +
                        value.label +
                        ": " +
                        value.formattedValue +
                        " ( " +
                        percentage +
                        " )" || "";
                    return label;
                  },
                },
              },
            },
          };
          graphListTemp.push({
            id: graphDashboard[i]?.id,
            order: graphDashboard[i]?.order,
            type: graphDashboard[i]?.type,
            optionsChart: optionsChartItem,
            chart: dashBoardItem,
          });
        }
      }
    }
    setGraphList(graphListTemp);
  };

  React.useEffect(() => {
    dispatch(getGraphDashboardData());
  }, []);

  React.useEffect(() => {
    if (graphDashboard) {
      definitionGraphList();
    }
  }, [graphDashboard]);

  const openUpdateGraph = (id) => {
    navigate("/raporte", {
      state: {
        stateGraphId: id,
        stateGraph: "update",
      },
    });
  };

  const handleClickDelete = (id) => {
    setSelectRowId(id);
    setStateDialogDelete(true);
  };

  const handleCloseDialogDelete = (e) => {
    setStateDialogDelete(false);
  };
  const handleDeleteGraph = (e) => {
    dispatch(deleteGraphDashboard({ id: selectRowId }));
    setStateDialogDelete(false);
  };

  return (
    <Box
      sx={{ justifyContent: "space-between", display: "flex", width: "100%" }}
    >
      <Box sx={{ mr: "0px", width: "100%" }}>
        <ContainerAnalyst heightcustom="820px">
          <Box
            sx={{
              display: "flex",
              flexWrap: { xs: "nowrap", md: "wrap" },
              m: 1,
              justifyContent: { xs: "center", md: "center", lg: "center" },
              flexDirection: "row",
              m: 1,
              height: "auto",
              width: "100%",
            }}
          >
            {graphList.map((item) => (
              <div
                style={{
                  backgroundColor: "#F6F8FC",
                  borderRadius: "15px",
                  textAlign: "-webkit-center",
                  padding: "0px 20px 20px 20px",
                  margin: "10px",
                  width: item.type === "PIE" ? "500px" : "800px",
                  // width: "100%",
                }}
              >
                {item.type === "SINGLE_LINE_CHART" && (
                  <LineChart
                    chartData={item.chart}
                    options={item.optionsChart}
                  />
                )}
                {item.type === "LINE" && (
                  <LineChart
                    chartData={item.chart}
                    options={item.optionsChart}
                  />
                )}
                {item.type === "BAR" && (
                  <BarChart
                    chartData={item.chart}
                    options={item.optionsChart}
                  />
                )}
                {item.type === "PIE" && (
                  <PieChart
                    chartData={item.chart}
                    options={item.optionsChart}
                  />
                )}
                {isAnalist && (
                  <Box sx={{ textAlign: "right" }}>
                    <IconButton
                      sx={{ color: "#3B376C" }}
                      onClick={(e) => openUpdateGraph(item.id)}
                    >
                      <Edit></Edit>
                    </IconButton>
                    <IconButton
                      onClick={(e) => handleClickDelete(item.id)}
                      sx={{ color: "#CD3728" }}
                    >
                      <Delete></Delete>
                    </IconButton>
                  </Box>
                )}
              </div>
            ))}
          </Box>
        </ContainerAnalyst>
      </Box>
      <DialogDelete
        open={stateDialogDelete}
        disagreedelete={handleCloseDialogDelete}
        agreedelete={handleDeleteGraph}
      />
    </Box>
  );
}
