import * as React from "react";
import { Box, AppBar, Toolbar } from "@mui/material";

const BottomToolbar = (props) => {
  return (
    <AppBar
      position="fixed"
      color="inherit"
      sx={{
        zIndex: 1,
        top: "auto",
        bottom: 0,
        height: "140px",
        paddingTop: "20px",
        backgroundColor: "#f6f8fc",
        "&.MuiPaper-root.MuiAppBar-root": {
          boxShadow: "none",
        },
      }}
    >
      <Toolbar>
        <Box
          sx={{
            flexFlow: "row",
            flexGrow: 1,
            alignItems: "end",
            justifyContent: "end",
            paddingRight: "100px",
            paddingLeft: "125px",
          }}
        >
          <Box
            sx={{
              marginLeft: "70px",
              height: "100px",
              borderRadius: "15px",
              backgroundColor: "white",
              alignItems: "center",
              display: "flex",
            }}
          >
            <Box sx={{ display: "flex", flexGrow: 1 }}></Box>
            <Box
              sx={{
                display: "flex",
                // flexGrow: 1,
                alignItems: "end",
                marginRight: "20px",
                columnGap: 1.5,
                left: 1,
                justifyContent: "end",
              }}
            >
              {props.children}
            </Box>
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default BottomToolbar;
