import * as React from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { Add } from "@mui/icons-material";

const ButtonAdd = (props) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        textAlign: "left",
        alignSelf: "left",
      }}
    >
      <Typography
        variant="h5"
        sx={{
          pl: "2px",
          pr: "15px",
          justifyContent: "center",
          fontFamily: "Basier Circle",
          color: props.disabled ? "rgba(0,0,0,0.38)" : "#091B3D",
        }}
      >
        {props?.labelbutton ? props.labelbutton : "Shto"}
      </Typography>
      <IconButton
        onClick={props.onClick}
        sx={{
          height: 55,
          width: 55,
          backgroundColor: props.disabled ? "rgba(0,0,0,0.38)" : "#036288",
          color: "#fff",
          borderRadius: "8px",
          "&.MuiButtonBase-root.MuiIconButton-root:hover": {
            backgroundColor: "#008fc6",
          },
          "&.MuiButtonBase-root.MuiIconButton-root.Mui-disabled": {
            backgroundColor: props.disabled ? "rgba(0,0,0,0.38)" : "#036288",
          },
        }}
        {...props}
      >
        <Add
          sx={{
            color: "#fff",
            "&.MuiSvgIcon-root": {
              height: "30px",
              width: "30px",
            },
          }}
        />
      </IconButton>
    </Box>
  );
};

export default ButtonAdd;
