import FormCard from "../components/Grid/FormCard";
import StartingPageContent from "../components/StartingPage/StartingPageContent";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
const FormGrid = () => {
  const listForm = [
    {
      id: 1,
      njesiraportuese: "Njesia Pare",
      name: "Title 1",
      rekuenca: "3 mujore",
    },
    {
      id: 2,
      njesiraportuese: "Njesia Pare",
      name: "Title 2 Test",
      rekuenca: "6 mujore",
    },
    {
      id: 3,
      njesiraportuese: "Njesia Pare",
      name: "Title 3",
      rekuenca: "3 mujore",
    },
    {
      id: 4,
      njesiraportuese: "Njesia Pare",
      name: "Title 4",
      rekuenca: "1 vjecare",
    },
  ];
  return (
    <StartingPageContent pageName="Forma Grid">
      <Box sx={{ flexGrow: 1 }}>
        <Grid
          container
          spacing={{ xs: 2, md: 2 }}
          columns={{ xs: 4, sm: 8, md: 16 }}
        >
          {listForm.map((item, index) => (
            <Grid item xs={2} sm={4} md={4} key={item.id}>
              <FormCard data={item}></FormCard>
            </Grid>
          ))}
          {/* {Array.from(Array(6)).map((_, index) => (
            <Grid item xs={2} sm={4} md={4} key={index}>
              <FormaCard></FormaCard>
            </Grid>
          ))} */}
        </Grid>
      </Box>
    </StartingPageContent>
  );
};

export default FormGrid;
