import axios from "axios";
import authHeader from "./auth-header";
import { APP_URL } from "./http-common";

const URLEntityName = APP_URL + "auth";

const register = (username, email, password) => {
  return axios.post(URLEntityName + "/signup", {
    username,
    email,
    password,
  });
};
const login = (username, password) => {
  return axios
    .post(URLEntityName + "/login", {
      username,
      password,
    })
    .then((response) => {
      if (response.data.token) {
        localStorage.setItem("userData", JSON.stringify(response.data));
      }
      return response.data;
    });
};

const me = () => {
  return axios
    .get(URLEntityName + "/me", { headers: authHeader() })
    .then((response) => {
      if (response.data) {
        // localStorage.setItem("userData", JSON.stringify(response.data));
      }
      return response.data;
    });
};
const logout = () => {
  localStorage.removeItem("userData");
};

const resetUserPassword = (data) => {
  return axios
    .post(URLEntityName + "/reset-password", data, { headers: authHeader() })
    .then((response) => {
      if (response.data) {
        //logout();
      }
      return response.data;
    });
};
const registerCompany = (data) => {
  return axios.post(URLEntityName + "/register", data).then((response) => {
    if (response.data) {
      // localStorage.setItem("userData", JSON.stringify(response.data));
    }
    return response.data;
  });
};

const authService = {
  register,
  me,
  login,
  logout,
  resetUserPassword,
  registerCompany,
};
export default authService;
