import axios from "axios";
import authHeader from "./auth-header";
import { APP_URL } from "./http-common";

const URLEntityName = APP_URL + "form-category";

const getAllFormCategory = (page, searchValue, limit, filterSelect) => {
  var valueFormCategory = "";
  var pageValue = page;
  if (searchValue !== "") {
    if (filterSelect) {
      valueFormCategory = "&search=status=ACTIVE&name=" + searchValue;
    } else {
      valueFormCategory = "&search=name=" + searchValue;
    }
  } else {
    if (filterSelect) {
      valueFormCategory = "&search=status=ACTIVE";
    } else {
      valueFormCategory = "";
    }
  }
  var valueLimit = "";
  if (limit !== "" && limit !== undefined) {
    pageValue = 0;
    valueLimit = "&size=" + limit;
  }

  return axios
    .get(
      URLEntityName + "?page=" + pageValue + valueFormCategory + valueLimit,
      {
        headers: authHeader(),
      }
    )
    .then((response) => {
      return response.data;
    });
};

async function createFormCategory(formCategory) {
  await axios
    .post(URLEntityName, formCategory, {
      headers: authHeader(),
    })
    .then((response) => {
      return response;
    });
}

async function updateFormCategory(formCategory) {
  await axios
    .put(URLEntityName + "/" + formCategory.id, formCategory, {
      headers: authHeader(),
    })
    .then((response) => {
      return response;
    });
}

async function deleteFormCategory(id) {
  await axios
    .delete(URLEntityName + "/" + id, { headers: authHeader() })
    .then((response) => {
      if (
        response.status === 200 ||
        response.status === 201 ||
        response.status === 204
      ) {
        return response;
      }
    })
    .catch((err) => {
      return err;
    });
}

const formCategoryService = {
  getAllFormCategory,
  createFormCategory,
  deleteFormCategory,
  updateFormCategory,
};
export default formCategoryService;
