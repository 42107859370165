import Grid from "../components/Grid/Grid";
import StartingPageContent from "../components/StartingPage/StartingPageContent";
const Entitet1 = () => {
  return (
    <StartingPageContent pageName="Entitet 1">
      <Grid></Grid>
    </StartingPageContent>
  );
};

export default Entitet1;
