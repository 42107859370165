import * as React from "react";
import { Box, Button, Typography } from "@mui/material";
import { GridToolbarExport } from "@mui/x-data-grid";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

export const ButtonExportCustom = (props) => {
  return (
    <Button {...props}>
      <FileDownloadIcon></FileDownloadIcon>
    </Button>
  );
};

const ButtonExport = (props) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        textAlign: "left",
        alignSelf: "left",
      }}
    >
      <GridToolbarExport
        csvOptions={{ disable: true }}
        onClick={() => {}}
        printOptions={{
          hideFooter: true,
          hideToolbar: true,
        }}
      ></GridToolbarExport>
      <Typography
        variant="h5"
        sx={{
          pl: "0px",
          pr: "15px",
          justifyContent: "center",
          fontFamily: "Basier Circle",
        }}
      >
        Eksporto
      </Typography>
    </Box>
  );
};

export default ButtonExport;
