export const convertDataTime = (stringData) => {
  const outpute = new Date(stringData);
  const event = new Date(Date.UTC(stringData));

  // British English uses day-month-year order and 24-hour time without AM/PM

  var dateString =
    outpute.getUTCFullYear() +
    "-" +
    (outpute.getUTCMonth() + 1) +
    "-" +
    outpute.getUTCDate() +
    " / " +
    outpute.getUTCHours() +
    ":" +
    outpute.getUTCMinutes() +
    ":" +
    outpute.getUTCSeconds();
  return dateString;
};

export const getName = (firstName, lastName) => {
  if (firstName === null || firstName === undefined) {
    return "";
  } else {
    if (lastName === null || firstName === undefined) {
      return capitalize(firstName);
    } else {
      return capitalize(firstName) + " " + capitalize(lastName);
    }
  }
};

export const getStatusName = (status) => {
  switch (status) {
    case "DRAFT":
      return "Draft";
      break;
    case "ACTIVE":
      return "Aktive";
      break;
    case "SUBMITTED":
      return "Derguar";
      break;
    case "REVIEWED":
      return "Aprovuar";
      break;
    default:
      return "Draft";
  }
};

export const getStatusProductPrice = (status) => {
  switch (status) {
    case "APPROVED":
      return "Konfirmuar";
      break;
    case "PENDING":
      return "Ne pritje";
      break;
    case "REJECTED":
      return "Refuzuar";
      break;
    default:
      return "Ne pritje";
  }
};

export const getRoleName = (status) => {
  switch (status) {
    case "ROLE_OPERATOR":
      return "Operator";
      break;
    case "ROLE_EDITOR":
      return "Editor";
      break;
    case "ROLE_ANALYST":
      return "Analist";
      break;
    case "ROLE_SOFT_EXECUTIVE":
      return "Ekzekutiv";
      break;
    default:
      return "Operator";
  }
};

export const getDateFormat = (oldDate) => {
  var d = new Date(oldDate),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

export const getCustomTextLength = (value, limit) => {
  if (value?.length > limit) {
    return value.slice(0, limit - 3) + "...";
  } else {
    return value;
  }
};

export const getLastDayOfYearForPie = () => {
  const currentYear = new Date().getFullYear();
  return getDateFormat(new Date(currentYear - 1, 11, 31));
};

export const getLastDayOfCurrentMonth = () => {
  const currentDate = new Date();
  var last_date = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    0
  );
  return getDateFormat(last_date);
};

export const getfirstDayOfCurrentMonthTwoYearsAgo = () => {
  const currentDate = new Date();
  var first_date = new Date(
    currentDate.getFullYear() - 2,
    currentDate.getMonth(),
    1
  );
  return getDateFormat(first_date);
};

export const getlastDayOfCurrentMonth = () => {
  const currentDate = new Date();
  var last_date = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  );
  return getDateFormat(last_date);
};

const capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};
