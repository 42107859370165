import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import {
  Autocomplete,
  Box,
  Button,
  InputLabel,
  Stack,
  TextField,
  Checkbox,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getAllUnit } from "../../store/njesiRaportuese";
import { setLoading } from "../../store/loading";
import SelectFilter from "../UI/SelectFilter";
import { getByReportingUnit } from "../../store/formTemplate";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import TextFieldDateFilter from "../UI/TextFieldDateFilter";
import { id, sq } from "date-fns/locale";
import { getCustomTextLength, getDateFormat } from "../helper/CustomLabel";
import ContainerAnalyst from "../UI/ContainerAnalyst";
import {
  clearFormSubmissionExportData,
  getFormSubmissionExportData,
} from "../../store/formSubmission";
import { useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import gridLabels from "../Labels/GridLabels";
import CsvToJson from "../helper/CsvToJson";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export default function Export() {
  const { exportData } = useSelector((state) => state.formSubmission);
  const { njesiRaportuese } = useSelector((state) => state.njesiRaportuese);
  const { formTemplate } = useSelector((state) => state.formTemplate);
  const { loading } = useSelector((state) => state.loading);
  const user = useSelector((state) => state.auth.user);
  const isEditor = user?.role === "ROLE_EDITOR";
  const isOperator = user?.role === "ROLE_OPERATOR";
  const isMinor = user?.role === "ROLE_MINOR_EXECUTIVE";
  const dispatch = useDispatch();
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const [state, setState] = React.useState({
    reportingUnitId: "",
    formId: "",
    dateStart: "",
    dateEnd: "",
    formIds: [],
  });
  const [valueReportingUnit, setValueReportingUnit] = React.useState("");
  const [valueForm, setValueForm] = React.useState("");
  const [columnDataState, setColumnDataState] = React.useState([]);
  const [rowDataState, setRowDataState] = React.useState({});

  const searchStateUnit = React.useRef("");
  const searchStateForm = React.useRef("");
  const [searchValueServerUnit, setSearchValueServerUnit] = React.useState("");
  const [searchValueServerForm, setSearchValueServerForm] = React.useState("");
  const [anchorElUnit, setAnchorElUnit] = React.useState(null);
  const [anchorElForm, setAnchorElForm] = React.useState(null);

  const handleClickUnit = (event) => {
    dispatch(
      getAllUnit({ page: 0, searchValue: "", size: 11, filterSelect: true })
    );
    setState({
      ...state,
      formId: "",
      formIds: [],
    });
    setAnchorElUnit(event.currentTarget);
  };
  const handleClickForm = (event) => {
    getAllDataFormUnit();
    setState({
      ...state,
    });
    setAnchorElForm(event.currentTarget);
  };
  const handleClickStartDate = (event) => {
    setState({
      ...state,
      //dateEnd: "",
    });
  };
  const handleCloseUnit = () => {
    setAnchorElUnit(null);
  };
  const handleCloseForm = () => {
    setAnchorElForm(null);
  };

  const openUnit = Boolean(anchorElUnit);
  const openForm = Boolean(anchorElForm);

  const navigate = useNavigate();

  const searchItemsUnit = (e) => {
    const searchValue = e.target.value;
    if (searchValue !== "") {
      searchStateUnit.current.value = searchValue;
      let filterTimeout;
      filterTimeout = setTimeout(() => {
        setSearchValueServerUnit(searchStateUnit.current.value);
      }, 2000);
    } else {
      setSearchValueServerUnit("");
      dispatch(getAllUnit({ page: 0, searchValue: "", filterSelect: true }));
    }
  };

  const searchItemsForm = (e) => {
    const searchValue = e.target.value;
    if (searchValue !== "") {
      searchStateForm.current.value = searchValue;
      let filterTimeout;
      filterTimeout = setTimeout(() => {
        setSearchValueServerForm(searchStateForm.current.value);
      }, 1000);
    } else {
      setSearchValueServerForm("");
    }
  };

  const checkFilterSend = () => {
    if (
      state.formIds !== [] &&
      state.dateStart !== "" &&
      state.dateEnd !== ""
    ) {
      getDataExport();
    } else {
      setColumnDataState([]);
      setRowDataState([]);
    }
  };

  const getDataExport = () => {
    dispatch(setLoading(true));
    var sendFormIds = [];
    state?.formIds?.map((item) => {
      sendFormIds.push(item.id);
    });
    const tempState = { ...state, formIds: sendFormIds };
    dispatch(getFormSubmissionExportData({ dataFilter: tempState }))
      .unwrap()
      .then(() => {
        dispatch(setLoading(false));
        // searchState?.current?.focus();
      })
      .catch(() => {
        dispatch(setLoading(false));
        // searchState?.current?.focus();
      });
  };

  React.useEffect(() => {
    checkFilterSend();
  }, [state]);
  React.useEffect(() => {
    setState({
      reportingUnitId: "",
      formId: "",
      dateStart: "",
      dateEnd: "",
      formIds: [],
    });

    if (isEditor || isOperator) {
      setState({
        ...state,
        reportingUnitId: user?.reportingUnit,
        formId: "",
        formIds: [],
      });
    }

    dispatch(clearFormSubmissionExportData());
    checkFilterSend();
  }, []);

  React.useEffect(() => {
    getAllDataUnit();
  }, [dispatch, searchValueServerUnit]);

  React.useEffect(() => {
    if (exportData !== []) {
      const jsonDataExport = CsvToJson(exportData);

      if (jsonDataExport[0]) {
        const lista = Object.keys(jsonDataExport[1]);
        const headerField = [];

        for (var i = 0; i < lista.length; i++) {
          if (i === 0) {
            headerField.push({
              field: lista[i],
              headerName: lista[i],
              editable: false,
              width: 200,
              options: {
                setCellProps: () => ({
                  style: {
                    whiteSpace: "nowrap",
                    position: "sticky",
                    left: 0,
                    background: "gray",
                    zIndex: 95,
                  },
                }),
                setCellHeaderProps: () => ({
                  style: {
                    whiteSpace: "nowrap",
                    position: "sticky",
                    left: 0,
                    background: "gray",
                    zIndex: 95,
                  },
                }),
              },
            });
          } else {
            headerField.push({
              field: lista[i],
              headerName: lista[i],
              editable: false,
              width: 200,
              textAlign: "left",
            });
          }
        }

        setColumnDataState(headerField);
        setRowDataState(jsonDataExport);
      }
    }
  }, [exportData]);

  const getAllDataUnit = () => {
    dispatch(setLoading(true));
    dispatch(
      getAllUnit({
        page: 0,
        searchValue: searchValueServerUnit,
        size: 1000,
        filterSelect: true,
      })
    )
      .unwrap()
      .then(() => {
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };

  const getAllDataFormUnit = () => {
    dispatch(setLoading(true));
    dispatch(
      getByReportingUnit({
        unit: state.reportingUnitId,
        searchValue: searchValueServerForm,
      })
    )
      .unwrap()
      .then(() => {
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };

  const currentlySelected = (e) => {
    navigate("/formgrid/inputet", {
      state: {
        stateFormInputId: e.row.id,
        nameForm: e.row.form.name,
        stateForm: "old",
      },
    });
  };

  return (
    <ContainerAnalyst
      header={
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              alignContent: "flex-start",
              mb: "20px",
            }}
          >
            {user?.role === "ROLE_ANALYST" ||
            user?.role === "ROLE_EXECUTIVE" ||
            user?.role === "ROLE_SOFT_EXECUTIVE" ||
            isMinor ? (
              <Stack spacing={2} sx={{ mr: "10px" }}>
                <InputLabel required>Njësia</InputLabel>
                <SelectFilter
                  textfield
                  name="reportingUnitId"
                  id="reportingUnitId"
                  label="Njësia"
                  placeholder="Kërko Njësi"
                  noOptionsText="Nuk ka njësi"
                  value={state.reportingUnitId}
                  onClick={handleClickUnit}
                  source={njesiRaportuese}
                  open={openUnit}
                  anchorEl={anchorElUnit}
                  onClickAway={handleCloseUnit}
                  onClose={(event, reason) => {
                    if (reason === "escape") {
                      handleCloseUnit();
                    }
                  }}
                  onChange={(event, newValue, reason) => {
                    if (
                      event.type === "keydown" &&
                      event.key === "Backspace" &&
                      reason === "removeOption"
                    ) {
                      return;
                    }
                    setState({
                      ...state,
                      reportingUnitId: newValue.id ? newValue.id : "",
                      formId: "",
                      formIds: [],
                    });

                    setValueReportingUnit(newValue.name);
                    handleCloseUnit();
                    getAllDataFormUnit();
                  }}
                  onchangesearch={searchItemsUnit}
                  inputRef={searchStateUnit}
                ></SelectFilter>
              </Stack>
            ) : null}

            {/* <Stack spacing={2} sx={{ mr: "10px" }}>
              <InputLabel required>Forma</InputLabel>
              <SelectFilter
                textfield
                disabledfilter={!state.reportingUnitId}
                name="formId"
                id="formId"
                label="Forma"
                placeholder="Kërko Formë"
                noOptionsText="Nuk ka formë"
                value={state.formId}
                onClick={handleClickForm}
                source={formTemplate}
                open={openForm}
                anchorEl={anchorElForm}
                onClickAway={handleCloseForm}
                onClose={(event, reason) => {
                  if (reason === "escape") {
                    handleCloseForm();
                  }
                }}
                onChange={(event, newValue, reason) => {
                  if (
                    event.type === "keydown" &&
                    event.key === "Backspace" &&
                    reason === "removeOption"
                  ) {
                    return;
                  }
                  setState({
                    ...state,
                    formId: newValue.id ? newValue.id : "",
                  });
                  setValueForm(newValue.name);
                  handleCloseForm();
                }}
                onchangesearch={searchItemsForm}
                inputRef={searchStateForm}
              ></SelectFilter>
            </Stack> */}

            <Stack spacing={2} sx={{ mr: "10px" }}>
              <InputLabel required>Forma</InputLabel>
              <Autocomplete
                multiple
                disabled={!state.reportingUnitId}
                onOpen={handleClickForm}
                // onClick={handleClickIndikator}
                sx={{
                  "& .MuiAutocomplete-root .MuiOutlinedInput-root": {
                    padding: "0px !important",
                  },
                }}
                renderTags={
                  (values) =>
                    state.formIds !== []
                      ? getCustomTextLength(state.formIds[0]?.name, 20) +
                        String(state.formIds?.length > 1 ? "  +" : "") +
                        String(
                          state.formIds?.length > 1
                            ? state.formIds?.length - 1
                            : ""
                        )
                      : ""

                  // (values) => {
                  //   return values ? values[0].join("...") : "";
                  // }

                  // (values) =>
                  // values.map((value) => value.label).join(", ")
                }
                id="checkboxes-tags-demo"
                options={formTemplate}
                disableCloseOnSelect
                value={state.formIds}
                getOptionLabel={(option) => option.name}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.name}
                  </li>
                )}
                onChange={(event, newValue, reason) => {
                  if (
                    event.type === "keydown" &&
                    event.key === "Backspace" &&
                    reason === "removeOption"
                  ) {
                    return;
                  }
                  setState((state) => ({
                    ...state,
                    formIds: newValue ? newValue : [],
                  }));
                }}
                renderInput={(params) => (
                  <TextField
                    sx={{
                      minWidth: "250px",
                      maxWidth: "250px",
                      height: "50px",

                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      // height: "auto",
                      marginRight: "20px",
                      width: "min-content",

                      "& .Mui-disabled": {
                        "fieldset ": {
                          // borderColor: "#72aeff !important",
                          minWidth: "250px",
                          maxWidth: "250px",
                        },
                      },
                      "& .MuiInputBase-root.MuiOutlinedInput-root": {
                        backgroundColor: "#f6f8fc",
                        borderRadius: "12px",
                        height: "50px",
                      },
                      "& .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input.Mui-disabled":
                        {
                          WebkitTextFillColor: "#091B3D !important",
                        },
                    }}
                    {...params}
                  />
                )}
              />
            </Stack>

            <Stack spacing={2} sx={{ mr: "10px" }}>
              <InputLabel required>Datë Fillimi</InputLabel>
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={sq}>
                <DatePicker
                  value={state.dateStart}
                  views={["year", "month"]}
                  onChange={(newValue) => {
                    var first_date = new Date(
                      newValue.getFullYear(),
                      newValue.getMonth(),
                      1
                    );
                    setState({
                      ...state,
                      dateStart: first_date ? getDateFormat(first_date) : "",
                      //dateEnd: "",
                    });
                  }}
                  renderInput={(params) => (
                    <TextFieldDateFilter
                      onClick={handleClickStartDate}
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: "muaj viti",
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            </Stack>

            <Stack spacing={2} sx={{ mr: "10px" }}>
              <InputLabel required>Datë Mbarimi</InputLabel>
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={sq}>
                <DatePicker
                  value={state.dateEnd}
                  views={["year", "month"]}
                  onChange={(newValue) => {
                    var last_date = new Date(
                      newValue.getFullYear(),
                      newValue.getMonth() + 1,
                      0
                    );
                    setState({
                      ...state,
                      dateEnd: last_date ? getDateFormat(last_date) : "",
                    });
                  }}
                  renderInput={(params) => (
                    <TextFieldDateFilter
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: "muaj viti",
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            </Stack>
            <Stack sx={{ alignSelf: "flex-end" }}>
              {exportData === "" ? null : (
                <Button
                  sx={{
                    height: "50px",
                    backgroundColor: "#B2D4FF",
                    color: "white",
                    ":hover": { backgroundColor: "#B2D4FF" },
                  }}
                  //onClick={`data:text/csv;charset=utf-8,${escape(exportData)}`}
                  href={`data:text/csv;charset=utf-8,${escape(exportData)}`}
                  download={
                    valueReportingUnit +
                    (valueReportingUnit ? "_" : "") +
                    valueForm +
                    ".csv"
                  }
                >
                  Eksporto
                </Button>
              )}
            </Stack>
          </Box>
        </Box>
      }
    >
      <div
        style={{
          backgroundColor: "white",
          textAlign: "-webkit-center",
          padding: "0px 20px 20px 20px",
        }}
      >
        <div style={{ height: 700, width: "100%" }}>
          <DataGrid
            sx={{
              paddingTop: 0,
              paddingLeft: 0,
              paddingRight: 0,
              "& .MuiDataGrid-root.MuiDataGrid-root.MuiDataGrid-row": {
                minHeight: "60px !important",
              },
              "& .MuiDataGrid-cellContent": {
                textAlign: "left",
              },
            }}
            labelRowsPerPage="Artikuj për faqe"
            disableSelectionOnClick
            columns={columnDataState ? columnDataState : []}
            rows={rowDataState ? rowDataState : []}
            density="comfortable"
            editMode="row"
            disableColumnMenu
            localeText={gridLabels}
            componentsProps={{
              pagination: {
                labelRowsPerPage: "Rreshta per Faqe",
                showLastButton: true,
                showFirstButton: true,
              },
            }}
            rowsPerPageOptions={[]}
            getRowId={(row) => Object.values(row)[0] + Math.random()}
            pagination
          />
        </div>
      </div>
    </ContainerAnalyst>
  );
}
