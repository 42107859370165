import * as React from "react";
import { Box, IconButton, Typography } from "@mui/material";
import RemoveSharpIcon from "@mui/icons-material/RemoveSharp";
import CropPortraitSharpIcon from "@mui/icons-material/CropPortraitSharp";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";

const ButtonChartSelect = (props) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        textAlign: "left",
        alignSelf: "left",
        paddingRight: "20px",
      }}
    >
      <IconButton
        sx={{
          height: "40px",
          width: "40px",
          "&.MuiButtonBase-root.MuiIconButton-root": {
            borderRadius: "5px",
            color: "white",
            //backgroundColor: props.selected ? "#B2D4FF" : "#808EB1",
            backgroundColor: props.selected ? "#CD3728" : "#808EB1",
          },
          "& .dropbtn > svg": {
            pointerEvents: "none",
          },
        }}
        {...props}
        onClick={props.onClick}
      >
        {props.type === "line" && <RemoveSharpIcon />}
        {props.type === "pie" && <CircleOutlinedIcon />}
        {props.type === "bar" && <CropPortraitSharpIcon />}
      </IconButton>
      <Typography
        variant="h5"
        sx={{
          pr: "2px",
          pl: "15px",
          justifyContent: "center",
          fontFamily: "Basier Circle",
          fontSize: "20px",
          color: props.disabled ? "rgba(0,0,0,0.38)" : "#091B3D",
        }}
      >
        {props.type === "line" && "Line"}
        {props.type === "pie" && "Pie"}
        {props.type === "bar" && "Bar"}
      </Typography>
    </Box>
  );
};

export default ButtonChartSelect;
