class Perdorues {
  constructor(id, perdorues, fjalekalim, roli) {
    this.id = id;
    this.perdorues = perdorues;
    this.fjalekalim = fjalekalim;
    this.roli = roli;
  }
}

export default Perdorues;
