import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";
import { setLoading } from "./loading";
import NjesiService from "../services/njesi.service";

const initialState = {
  unit: [],
  totalElements: 0,
  totalPages: 0,
  size: 10,
  page: 0,
  searchValue: "",
  njesiRaportueseExport: [],
  currentPage: 0,
  searchValueCurrent: "",
};

export const setSearch = createAsyncThunk(
  "users/setSearchValue",
  ({ searchValue }, thunkAPI) => {
    return {
      searchValue: searchValue,
    };
  }
);
export const getAllUnit = createAsyncThunk(
  "reporting-unit/getAllUnit",
  async ({ page, searchValue, size, filterSelect = false }, thunkAPI) => {
    try {
      const data = await NjesiService.getAllUnits(
        page,
        searchValue,
        size,
        filterSelect
      );
      return {
        unit: data.content,
        totalElements: data.totalElements,
        totalPages: data.totalPages,
        size: data.size,
        currentPage: data.number,
        searchValueCurrent: searchValue,
      };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getAllUnitExport = createAsyncThunk(
  "reporting-unit/getAllUnitExport",
  async ({ searchValue, size }, thunkAPI) => {
    try {
      const data = await NjesiService.getAllUnits(0, searchValue, size);
      return {
        unitsExport: data.content,
      };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const createUnit = createAsyncThunk(
  "unit/create",
  async ({ unit }, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    try {
      const data = await NjesiService.createUnit(unit);
      thunkAPI.dispatch(getAllUnit({ page: 0, searchValue: "" }));
      thunkAPI.dispatch(setLoading(false));
      return thunkAPI.rejectWithValue();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setLoading(false));
      thunkAPI.dispatch(setMessage({ message: message }));
      thunkAPI.dispatch(getAllUnit({ page: 0, searchValue: "" }));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const updateUnit = createAsyncThunk(
  "unit/update",
  async ({ unit, currentPage, searchValueCurrent }, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    try {
      const data = await NjesiService.updateUnit(unit);
      thunkAPI.dispatch(
        getAllUnit({
          page: currentPage ? currentPage : 0,
          searchValue: searchValueCurrent ? searchValueCurrent : "",
        })
      );
      // thunkAPI.dispatch(getAllUnit({ page: 0, searchValue: "" }));
      thunkAPI.dispatch(setLoading(false));
      return thunkAPI.rejectWithValue();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setLoading(false));
      thunkAPI.dispatch(setMessage({ message: message }));
      thunkAPI.dispatch(getAllUnit({ page: 0, searchValue: "" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const deleteUnit = createAsyncThunk(
  "unit/delete",
  async ({ id, currentPage = 0, searchValueCurrent = "" }, thunkAPI) => {
    try {
      const data = await NjesiService.deleteUnit(id);
      thunkAPI.dispatch(setMessage({ message: "Rekordi u fshi me sukses!" }));
      thunkAPI.dispatch(
        getAllUnit({
          page: currentPage ? currentPage : 0,
          searchValue: searchValueCurrent ? searchValueCurrent : "",
        })
      );
      return thunkAPI.rejectWithValue();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message }));
      thunkAPI.dispatch(
        getAllUnit({
          page: currentPage ? currentPage : 0,
          searchValue: searchValueCurrent ? searchValueCurrent : "",
        })
      );
      return thunkAPI.rejectWithValue();
    }
  }
);

const unitSlice = createSlice({
  name: "njesiRaportuese",
  initialState,
  extraReducers: {
    [getAllUnit.fulfilled]: (state, action) => {
      state.njesiRaportuese = action.payload.unit;
      state.totalElements = action.payload.totalElements;
      state.totalPages = action.payload.totalPages;
      state.size = action.payload.size;
      state.searchValue = action.payload.searchValue;
      state.currentPage = action.payload.currentPage;
      state.searchValueCurrent = action.payload.searchValueCurrent;
    },
    [getAllUnit.rejected]: (state, action) => {
      state.njesiRaportuese = null;
      state.totalElements = 0;
      state.totalPages = 0;
      state.size = 10;
      state.searchValue = "";
    },
    [getAllUnitExport.fulfilled]: (state, action) => {
      state.njesiRaportueseExport = action.payload.unitsExport;
    },
    [getAllUnitExport.rejected]: (state, action) => {
      state.njesiRaportueseExport = [];
    },
    [setSearch.fulfilled]: (state, action) => {
      state.searchValue = action.payload.searchValue;
    },
    [setSearch.rejected]: (state, action) => {
      state.searchValue = "";
    },
  },
});

const { reducer } = unitSlice;
export default reducer;
